import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { differenceInDays, format } from "date-fns";
import "./WeeklyApprovalReports.css";
import { API_URL } from "../ApiUrl";
import { getUsers } from "../masters/Lists/ListShow";

const WeeklyApprovalReports = () => {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [assign, setAssign] = useState({
    assignValue: "",
    assignDropDownValues: [],
  });
  const [dateRangeError, setDateRangeError] = useState("");

  // Fetch users for dropdown
  useEffect(() => {
    const getAssignValue = async () => {
      try {
        const assignData = await getUsers();
        setAssign({
          assignValue: "",
          assignDropDownValues: assignData,
        });
      } catch (err) {
        console.error("Error fetching assign values:", err);
      }
    };
    getAssignValue();
  }, []);

  useEffect(() => {
    const fetchReportData = async () => {
      if (
        startDate &&
        endDate &&
        assign.assignValue &&
        differenceInDays(endDate, startDate) <= 7
      ) {
        setLoading(true);
        setDateRangeError(""); // Clear any previous error message
        try {
          const weekEnding = endDate.toISOString().split("T")[0];
          const response = await axios.get(`${API_URL}/review-forms`, {
            params: { weekEnding, assignee: assign.assignValue },
          });
          console.log("API Response Data:", response.data); // Log the response data

          // Filter data to only include records matching the selected assignee
          const filteredData = response.data.filter((data) => {
            // Check if the assignee name matches the name in the data
            return (
              data.name === assign.assignValue &&
              (startDate &&
              endDate &&
              differenceInDays(endDate, startDate) === 0
                ? new Date(data.weekEnding).toDateString() ===
                  startDate.toDateString()
                : new Date(data.weekEnding) >= startDate &&
                  new Date(data.weekEnding) <= endDate)
            );
          });

          setReportData(filteredData);
        } catch (err) {
          setError(`Failed to load report data: ${err.message}`);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchReportData();
  }, [startDate, endDate, assign.assignValue]);

  // Handle date range change
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    if (end && differenceInDays(end, start) > 7) {
      setDateRangeError("Please select a date range of one week or less.");
      setEndDate(null); // Clear end date if it exceeds one week
    } else {
      setDateRangeError(""); // Clear error if valid range
      setStartDate(start);
      setEndDate(end);
    }
  };

  return (
    <div className="report-container">
      <h1>Weekly Approval Report</h1>

      <div className="controls">
        <div className="date-picker">
          <label>Select Date Range:</label>
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            className="form-control border rounded"
            placeholderText="Select Date Range"
            dateFormat="dd/MM/yyyy" // Ensure DD/MM/YYYY format
          />
          {dateRangeError && <div className="error">{dateRangeError}</div>}
        </div>

        <div className="assign-dropdown">
          <label>Select Assignee:</label>
          <select
            className="form-select"
            aria-label="Select Assignee"
            value={assign.assignValue}
            onChange={(e) =>
              setAssign({
                ...assign,
                assignValue: e.target.value,
              })
            }
          >
            <option value="">Please choose one option</option>
            {assign.assignDropDownValues &&
              assign.assignDropDownValues.map((option, index) => (
                <option key={index} value={option.firstname}>
                  {option.firstname}
                </option>
              ))}
          </select>
        </div>
      </div>

      {loading ? (
        <div className="loading">Loading...</div>
      ) : error ? (
        <div className="error">Error: {error}</div>
      ) : reportData.length > 0 ? (
        <>
          {reportData.map((data) => (
            <div key={data.id} className="report-item">
              {/* New Report Details Section */}
              <section className="report-details">
                <h2>Report Details</h2>
                <p>
                  <strong>Name:</strong> {data.name || "Arun"}
                </p>
                <p>
                  <strong>Reviewer:</strong>{" "}
                  {data.reviewer || "2cqr automation"}
                </p>
                <p>
                  <strong>Date:</strong>{" "}
                  {format(new Date(data.weekEnding), "dd/MM/yyyy")}
                </p>
                <p>
                  <strong>Accomplishments:</strong>{" "}
                  {data.accomplishments || "N/A"}
                </p>{" "}
                {/* Displaying accomplishment */}
              </section>

              {/* Performance Ratings Section */}
              <section className="performance-rating">
                <h2>Performance Ratings</h2>
                <table>
                  <thead>
                    <tr>
                      <th>Quality of Work</th>
                      <th>Productivity</th>
                      <th>Job Knowledge</th>
                      <th>Communication</th>
                      <th>Teamwork</th>
                      <th>Initiative</th>
                      <th>Attendance</th>
                      <th>Punctuality</th>
                      <th>Worksheet Completion</th> {/* New Column */}
                      <th>Permission or Leave</th> {/* New Column */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{data.qualityOfWork || "N/A"}</td>
                      <td>{data.productivity || "N/A"}</td>
                      <td>{data.jobKnowledge || "N/A"}</td>
                      <td>{data.communication || "N/A"}</td>
                      <td>{data.teamwork || "N/A"}</td>
                      <td>{data.initiative || "N/A"}</td>
                      <td>{data.attendance || "N/A"}</td>
                      <td>{data.punctualityToMeeting || "N/A"}</td>
                      <td>{data.worksheetCompletion || "2"}</td>{" "}
                      {/* New Data */}
                      <td>{data.permissionOrLeave || "1"}</td> {/* New Data */}
                    </tr>
                  </tbody>
                </table>
              </section>

              {/* Goals and Status Section */}
              <section className="goals">
                <h2> Previous Week's Goals & Status</h2>
                {data.previousGoals &&
                  data.previousGoals.map((goal, index) => (
                    <div key={index} className="goal-item">
                      <p>
                        <strong>Goal:</strong> {goal.goal || "N/A"}
                      </p>
                      <p>
                        <strong>Achieved:</strong> {goal.achieved || "N/A"}
                      </p>
                      <p>
                        <strong>Comments:</strong> {goal.comments || "N/A"}
                      </p>
                    </div>
                  ))}
              </section>

              {/* Challenges Section */}
              <section className="challenges">
                <h2>Challenges Faced</h2>
                <p>{data.challengesFaced || "N/A"}</p>
              </section>

              {/* New Goals and Support Section */}
              <section className="new-goals">
                <h2>Next Week's Goals and Support</h2>
                {data.newGoals &&
                  data.newGoals.map((goal, index) => (
                    <div key={index} className="goal-item">
                      <p>
                        <strong>Goal:</strong> {goal.goal || "N/A"}
                      </p>
                      <p>
                        <strong>Achieved:</strong> {goal.achieved || "N/A"}
                      </p>
                      <p>
                        <strong>Comments:</strong> {goal.comments || "N/A"}
                      </p>
                    </div>
                  ))}
                <p>
                  <strong>Support Needed:</strong> {data.supportNeeded || "N/A"}
                </p>
              </section>

              {/* Additional Information Section */}
              <section className="additional-info">
                <h2>Additional Information</h2>
                <p>
                  <strong>Attendance Comments:</strong>{" "}
                  {data.attendanceComments || "N/A"}
                </p>
                <p>
                  <strong>Communication Comments:</strong>{" "}
                  {data.communicationComments || "N/A"}
                </p>
                <p>
                  <strong>Job Knowledge Comments:</strong>{" "}
                  {data.jobKnowledgeComments || "N/A"}
                </p>
                <p>
                  <strong>Quality of Work Comments:</strong>{" "}
                  {data.qualityOfWorkComments || "N/A"}
                </p>
                <p>
                  <strong>Productivity Comments:</strong>{" "}
                  {data.productivityComments || "N/A"}
                </p>
                <p>
                  <strong>Teamwork Comments:</strong>{" "}
                  {data.teamworkComments || "N/A"}
                </p>
                <p>
                  <strong>Initiative Comments:</strong>{" "}
                  {data.initiativeComments || "N/A"}
                </p>
                <p>
                  <strong>PunctualityToMeeting Comments:</strong>{" "}
                  {data.punctualityToMeetingComments || "N/A"}
                </p>
                <p>
                  <strong>Work Sheet Completion Comments:</strong>{" "}
                  {data.worksheetCompletionComments || "N/A"}
                </p>
                <p>
                  <strong>Permission or Leave Comments:</strong>{" "}
                  {data.permissionOrLeaveComments || "N/A"}
                </p>
              </section>
            </div>
          ))}
        </>
      ) : (
        <div>No data available for the selected date range and assignee.</div>
      )}
    </div>
  );
};

export default WeeklyApprovalReports;
