/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { MDBDataTableV5 } from "mdbreact";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import "./Modules.css";
import { API_URL } from "../ApiUrl";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

export default function Module() {
  useEffect(() => {
    fetchModules();
  }, []);

  const [modules, setModules] = useState([]);
  const [newModuleName, setNewModuleName] = useState("");
  const [isAddModuleModalOpen, setIsAddModuleModalOpen] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [editedModuleName, setEditedModuleName] = useState("");
  const [isEditModuleModalOpen, setIsEditModuleModalOpen] = useState(false);

  const navigate = useNavigate();
  const username = sessionStorage.getItem("username");
  const accessToken1 = localStorage.getItem("token");

  const fetchModules = async () => {
    try {
      const response = await axios.get(`${API_URL}/getmodules`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      const moduleData = response.data;
      setModules(moduleData);
    } catch (error) {
      console.error("Error fetching modules:", error);
      toast.error("Error fetching modules");
    }
  };

  const fetchModuleDetailById = async (Moduleid) => {
    try {
      const response = await axios.post(
        `${API_URL}/getModuledetailbyID`,
        {
          Moduleid,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );
      return response.data.ModuleData;
    } catch (error) {
      console.error("Error fetching module details by ID:", error);
      toast.error("Error fetching module details");
      return null;
    }
  };

  const handleAddModule = async () => {
    if (!newModuleName.trim()) {
      toast.error("Module name cannot be empty");
      return;
    }

    try {
      await axios.post(
        `${API_URL}/createmodule`,
        {
          Modulename: newModuleName,
          createdby: username,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );
      toast.success("Module added successfully");
      closeAddModuleModal();
      fetchModules();
    } catch (error) {
      console.error("Error adding module:", error);
      toast.error("Error adding module");
    }
  };

  const openEditModuleModal = (moduleId, moduleName) => {
    setSelectedModuleId(moduleId); // Set the selected module ID
    setEditedModuleName(moduleName); // Set the module name to edit
    setIsEditModuleModalOpen(true); // Open the modal
  };

  // Function to close the edit modal and reset state
  const closeEditModuleModal = () => {
    setIsEditModuleModalOpen(false);
    setSelectedModuleId(null);
    setEditedModuleName(""); // Reset the state values
  };

  // Function to handle the editing of a module
  const handleEditModule = async () => {
    if (!editedModuleName.trim()) {
      toast.error("Module name cannot be empty");
      return;
    }

    try {
      await axios.post(
        `${API_URL}/updatemodule`,
        {
          Moduleid: selectedModuleId, // Use the selected module ID
          Modulename: editedModuleName,
          lastmodifiedby: new Date().toString(),
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      toast.success("Module updated successfully");
      closeEditModuleModal();
      fetchModules(); // Refresh the module list
    } catch (error) {
      console.error("Error updating module:", error);
      toast.error("Error updating module");
    }
  };

  const openAddModuleModal = () => {
    setNewModuleName("");
    setIsAddModuleModalOpen(true);
  };

  const closeAddModuleModal = () => {
    setIsAddModuleModalOpen(false);
  };

  const handleBack = () => {
    navigate("/dashboard");
  };

  const datatable = {
    columns: [
      {
        label: "S.No",
        field: "sno",
        width: 100,
      },
      {
        label: "Name",
        field: "name",
        width: 100,
      },
      {
        label: "Actions",
        field: "actions",
        width: 100,
      },
    ],
    rows:
      modules &&
      modules.map((module, index) => ({
        sno: index + 1,
        name: module.name,
        actions: (
          <>
            <button
              className="mr-4"
              title="Edit"
              onClick={() => openEditModuleModal(module.moduleid, module.name)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
          </>
        ),
      })),
  };

  return (
    <div className="page-wrapper ">
      <div className="page-header d-print-none">
        <div className="container-xl ">
          <div className="row g-2 align-items-center ">
            <div className="col-auto ms-auto d-print-none">
              <div className="btn-list mt-3">
                <button
                  className="btn btn-primary d-none d-sm-inline-block"
                  onClick={openAddModuleModal}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 5l0 14"></path>
                    <path d="M5 12l14 0"></path>
                  </svg>
                  New Module
                </button>
              </div>
            </div>

            <Modal
              show={isAddModuleModalOpen}
              onHide={closeAddModuleModal}
              backdrop="static"
              dialogClassName="custom-modal"
              contentClassName="custom-modal-content"
            >
              <Modal.Header closeButton>
                <Modal.Title>Add New Module</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mb-3">
                  <label className="form-label">Module Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter module name"
                    value={newModuleName}
                    onChange={(e) => setNewModuleName(e.target.value)}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-secondary"
                  onClick={closeAddModuleModal}
                >
                  Cancel
                </button>
                <button className="btn btn-primary" onClick={handleAddModule}>
                  Add
                </button>
              </Modal.Footer>
            </Modal>

            <Modal show={isEditModuleModalOpen} onHide={closeEditModuleModal}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Module</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label>Module Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editedModuleName}
                    onChange={(e) => setEditedModuleName(e.target.value)}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeEditModuleModal}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleEditModule}>
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
            <ToastContainer />
          </div>
        </div>
      </div>
      <div className="page-body ">
        <div className="container-xl">
          <div className="row row-cards ml-5">
            <div className="col-11 mr-5">
              <div className="card  mt-5">
                <div className="card-header">
                  <h3 className="card-title">Module List</h3>
                </div>
                <div className="container table-responsive">
                  <MDBDataTableV5
                    striped
                    fixed
                    hover
                    entriesOptions={[10, 20, 30, 50]}
                    entries={10}
                    pagesAmount={4}
                    data={datatable}
                    searchTop
                    searchBottom={false}
                    pagingTop={false}
                  />
                </div>
                <ToastContainer />
              </div>
            </div>
          </div>
          <button
            className="btn btn-primary d-none d-sm-inline-block mt-5"
            onClick={handleBack}
            style={{ position: "absolute", right: "5.5%" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M9 6l-6 6 6 6" />
              <path d="M15 6l-6 6 6 6" />
            </svg>
            Back to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
}
