import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../ApiUrl";

function generateOptions(maxValue) {
  return Array.from({ length: maxValue + 1 }, (_, i) => (
    <option key={i} value={i}>
      {i}
    </option>
  ));
}

function convertToDHMSFormat(days, hours, minutes) {
  return `${days}d${hours}h${minutes}m`;
}

function convertDHMSToHours(dhms) {
  if (!dhms || typeof dhms !== "string") {
    return 0; // Return 0 if invalid input
  }

  const daysMatch = dhms.match(/(\d+)d/);
  const hoursMatch = dhms.match(/(\d+)h/);
  const minutesMatch = dhms.match(/(\d+)m/);

  const days = daysMatch ? parseInt(daysMatch[1], 10) : 0;
  const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
  const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

  const totalHours = days * 24 + hours + minutes / 60;

  return totalHours;
}

function CompleteTicketModal({
  showCompleteModal,
  setShowCompleteModal,
  ticketId,
  expectedFormat,
}) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [showDelayModal, setShowDelayModal] = useState(false);
  const [delayReason, setDelayReason] = useState("");
  const [submissionError, setSubmissionError] = useState(null);
  const [statusFromLocalStorage, setStatusFromLocalStorage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const status = localStorage.getItem("status");
    setStatusFromLocalStorage(status);
  }, []);

  const handleComplete = async () => {
    const actualTotalHours = days * 24 + hours + minutes / 60;
    const expectedTotalHours = convertDHMSToHours(expectedFormat);
    const differenceInHours = actualTotalHours - expectedTotalHours;

    const committedDeadline = convertToDHMSFormat(days, hours, minutes);

    if (differenceInHours < 0) {
      setShowDelayModal(true);
    } else {
      try {
        await axios.post(
          `${API_URL}/updateticketmasterlog`,
          {
            ticketId,
            committedDeadline,
            completed_date_time: new Date(),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setShowCompleteModal(false);
        // navigate("/dashboard");
      } catch (error) {
        console.error("Error updating ticket master log:", error);
        setSubmissionError("An error occurred. Please try again.");
      }
    }
  };

  const handleDelayReasonSubmit = async () => {
    const committedDeadline = convertToDHMSFormat(days, hours, minutes);

    try {
      await axios.post(
        `${API_URL}/updateticketmasterlog`,
        {
          ticketId,
          reasonForDelay: delayReason,
          committedDeadline,
          completed_date_time: new Date(),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setShowDelayModal(false);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error submitting reason for delay:", error);
      setSubmissionError(
        "Failed to submit the reason for delay. Please try again."
      );
    }
  };

  return (
    <>
      {statusFromLocalStorage !== "pending" && (
        <Modal
          show={showCompleteModal}
          onHide={() => setShowCompleteModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Complete Ticket</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Days</Form.Label>
                <Form.Select
                  value={days}
                  onChange={(e) => setDays(parseInt(e.target.value, 10))}
                >
                  {generateOptions(30)}
                </Form.Select>
              </Form.Group>

              <Form.Group>
                <Form.Label>Hours</Form.Label>
                <Form.Select
                  value={hours}
                  onChange={(e) => setHours(parseInt(e.target.value, 10))}
                >
                  {generateOptions(23)}
                </Form.Select>
              </Form.Group>

              <Form.Group>
                <Form.Label>Minutes</Form.Label>
                <Form.Select
                  value={minutes}
                  onChange={(e) => setMinutes(parseInt(e.target.value, 10))}
                >
                  {generateOptions(59)}
                </Form.Select>
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowCompleteModal(false)}
            >
              Close
            </Button>
            <Button variant="primary" onClick={handleComplete}>
              Complete
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <Modal show={showDelayModal} onHide={() => setShowDelayModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reason for Delay</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Please enter the reason for delay</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={delayReason}
                onChange={(e) => setDelayReason(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDelayModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleDelayReasonSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {submissionError && (
        <div className="text-danger text-center mt-2">{submissionError}</div>
      )}
    </>
  );
}

export default CompleteTicketModal;
