/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
//import AddClient from './AddClient';
//import ClientList from './ClientList';
import { API_URL } from "../../ApiUrl";
import { getClients } from "../Lists/ListShow";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { MDBDataTableV5 } from "mdbreact";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";

export default function Client() {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    toast.dismiss();
    setShow(false);
    setName("");
    setContactperson("");
    setEmail("");
    setPhone("");
    setAddress("");
    setState("");
    setCity("");
    setZipcode("");
  };

  const handleShow = () => {
    getAutoID();
    setShow(true);
    setPrimaryid("");
    setButtontxt("Create New Client");
  };

  const [headtitle, setHeadtitle] = useState("Add New Client");
  const [btntxt, setButtontxt] = useState("Create New Client");
  const [autoid, setAutoid] = useState("");
  const [name, setName] = useState("");
  const [primaryid, setPrimaryid] = useState("");
  const [contactperson, setContactperson] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();
  const axiosJWT = axios.create();

  useEffect(() => {
    const fetchClients = async () => {
      const data = await getClients();
      setClients(data);
    };
    fetchClients();
    getAutoID();
  }, []);

  const screens = sessionStorage.getItem("screens");
  const screenActions = screens.split(",");

  const getAutoID = async () => {
    try {
      const response = await axiosJWT.get(`${API_URL}/getautoid/client`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      setAutoid(response.data.clientid);
    } catch (error) {
      if (error) {
        console.log(error.message);
      }
    }
  };
  const accessToken1 = localStorage.getItem("token");
  const tokenPayload = jwt_decode(accessToken1);
  const isTokenExpired = tokenPayload.exp < Date.now() / 1000;

  if (isTokenExpired) {
    //console.log("expired");
    localStorage.removeItem("token");
    localStorage.setItem("isLoggedin", false);
    navigate("/");
  }
  const handleEdit = async (client_id) => {
    handleShow();
    setAutoid(client_id);
    setHeadtitle("Edit Client");
    setButtontxt("Update Client");
    const response = await axiosJWT.get(`${API_URL}/clients/${client_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken1}`,
      },
    });
    setName(response.data.name);
    setEmail(response.data.email);
    setPhone(response.data.phone);
    setContactperson(response.data.contact_person);
    setAddress(response.data.address);
    setState(response.data.state);
    setCity(response.data.city);
    setZipcode(response.data.zipcode);
    setPrimaryid(response.data.id);
  };

  const datatable = {
    columns: [
      {
        label: "id",
        field: "id",
      },
      {
        label: "client_id",
        field: "client_id",
      },
      {
        label: "Name",
        field: "name",
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "contact_person",
        field: "contact_person",
      },
      {
        label: "email",
        field: "email",
      },
      {
        label: "phone",
        field: "phone",
      },
      {
        label: "address",
        field: "address",
      },
      {
        label: "city",
        field: "city",
      },
      {
        label: "state",
        field: "state",
      },
      {
        label: "zipcode",
        field: "zipcode",
      },
      ...(screenActions.includes("Masters Edit") ||
      screenActions.includes("Masters Delete")
        ? [
            {
              label: "Actions",
              field: "actions",
            },
          ]
        : []),
    ],
    rows: clients.map((client, index) => {
      return {
        ...client,
        id: index + 1,
        actions: (
          <div style={{ display: "flex" }}>
            {screenActions.includes("Masters Edit") && (
              <button
                className="demo-icons-list-item no-bg"
                title="Edit"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                onClick={() => handleEdit(client.client_id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="blue" // Add your desired color here (e.g., "blue")
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M8 20l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4h4z" />
                  <path d="M13.5 6.5l4 4" />
                  <path d="M16 18h4m-2 -2v4" />
                </svg>
              </button>
            )}
            {screenActions.includes("Masters Delete") && (
              <button
                className="demo-icons-list-item no-bg"
                title="Delete"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                onClick={() => handleDelete(client.client_id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-trash"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="red"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 7l16 0"></path>
                  <path d="M10 11l0 6"></path>
                  <path d="M14 11l0 6"></path>
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                </svg>
              </button>
            )}
          </div>
        ),
      };
    }),
  };

  const handleKeyDown = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    // const regex = /^[0-9]*$/;
    if (!/^\d+$/.test(keyValue) && event.keyCode !== 8 && event.keyCode !== 9) {
      event.preventDefault();
    }
    if (
      event.target.value.length >= 10 &&
      event.keyCode !== 8 &&
      event.keyCode !== 9
    ) {
      event.preventDefault();
    }
  };

  const handleZipcode = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    // const regex = /^[0-9]*$/;
    if (!/^\d+$/.test(keyValue) && event.keyCode !== 8 && event.keyCode !== 9) {
      event.preventDefault();
    }
    if (
      event.target.value.length >= 6 &&
      event.keyCode !== 8 &&
      event.keyCode !== 9
    ) {
      event.preventDefault();
    }
  };

  const saveClient = async (data) => {
    data.preventDefault();
    if (name.trim() === "" || contactperson.trim() === "") {
      toast.error("Please Enter All Fields...!", { autoClose: 1000 });
      return;
    }

    try {
      if (primaryid) {
        const response = await axiosJWT.post(
          `${API_URL}/updateclient/${primaryid}`,
          {
            name,
            email,
            phone,
            contactperson,
            autoid,
            address,
            state,
            city,
            zipcode,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken1}`,
            },
          }
        );
        toast.success(response.data.msg, { autoClose: 1000 });
      } else {
        const response = await axiosJWT.post(
          `${API_URL}/createclient`,
          {
            name,
            email,
            phone,
            contactperson,
            autoid,
            address,
            state,
            city,
            zipcode,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken1}`,
            },
          }
        );
        toast.success(response.data.msg, { autoClose: 1000 });
      }

      setAutoid("");
      setName("");
      setContactperson("");
      setEmail("");
      setPhone("");
      setAddress("");
      setState("");
      setCity("");
      setZipcode("");
      //toast.success('Data saved successfully!', { autoClose: 3000 });
      getAutoID();
      const fetchClients = async () => {
        const data = await getClients();
        setClients(data);
      };
      fetchClients();
      setTimeout(() => {
        handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (client_id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this client?"
    );
    if (!confirmed) {
      return;
    }
    try {
      await axiosJWT.delete(`${API_URL}/clients/${client_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      toast.success("Client Deleted Successfully!", {
        autoClose: 2000,
      });
      setTimeout(() => {
        setClients(clients.filter((client) => client.client_id !== client_id));
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <div className="page-pretitle">Overview</div>
              <h2 className="page-title">Masters/Client</h2>
            </div>
            <div className="col-auto ms-auto d-print-none">
              <div className="btn-list">
                {screenActions.includes("Masters Create") && (
                  <button
                    href="#"
                    className="btn btn-primary d-none d-sm-inline-block"
                    onClick={handleShow}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M12 5l0 14"></path>
                      <path d="M5 12l14 0"></path>
                    </svg>
                    New Client
                  </button>
                )}
              </div>
            </div>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              className="modal-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>{headtitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="col-12">
                  <form className="">
                    <div className="card-body">
                      <div className="row row-cards">
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label className="form-label">Client Id</label>
                            <input
                              type="text"
                              className="form-control"
                              readOnly
                              defaultValue={autoid}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-9">
                          <div className="mb-3">
                            <label className="form-label">Client Name *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Client Name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">
                              Contact Person Name *
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Contact Person"
                              value={contactperson}
                              onChange={(e) => setContactperson(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Phone No </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Phone No"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Email Address </label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Address </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Address"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">City </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="City"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-5">
                          <div className="mb-3">
                            <label className="form-label">State </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="State"
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                          <div className="mb-3">
                            <label className="form-label">Postal Code </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="ZIP Code"
                              value={zipcode}
                              onChange={(e) => setZipcode(e.target.value)}
                              onKeyDown={handleZipcode}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="modal-footer">
                  <button
                    className="btn btn-md btn-primary ms-auto"
                    onClick={saveClient}
                  >
                    {btntxt}
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
            <ToastContainer />
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Client List</h3>
                </div>
                <div className="container table-responsive mt-3">
                  <MDBDataTableV5
                    striped
                    fixed
                    hover
                    entriesOptions={[10, 20, 30, 50]}
                    entries={10}
                    pagesAmount={4}
                    data={datatable}
                    searchTop
                    searchBottom={false}
                  />
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
