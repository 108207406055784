import axios from "axios";
import { API_URL } from "../../ApiUrl";

export const getClients = async () => {
  const axiosJWT = axios.create();
  const accessToken1 = localStorage.getItem("token");
  const response = await axiosJWT.get(`${API_URL}/clients`, {
    headers: {
      Authorization: `Bearer ${accessToken1}`,
    },
  });
  return response.data;
};

export const getUsers = async () => {
  const axiosJWT = axios.create();
  const accessToken1 = localStorage.getItem("token");
  const response = await axiosJWT.get(`${API_URL}/users`, {
    headers: {
      Authorization: `Bearer ${accessToken1}`,
    },
  });
  return response.data;
};

export const getTaskStatus = async () => {
  const axiosJWT = axios.create();
  const accessToken1 = localStorage.getItem("token");
  const response = await axiosJWT.get(`${API_URL}/taskstatus`, {
    headers: {
      Authorization: `Bearer ${accessToken1}`,
    },
  });
  return response.data;
};

export const getPhase = async () => {
  const axiosJWT = axios.create();
  const accessToken1 = localStorage.getItem("token");
  const response = await axiosJWT.get(`${API_URL}/phase`, {
    headers: {
      Authorization: `Bearer ${accessToken1}`,
    },
  });
  return response.data;
};

export const getPriority = async () => {
  const axiosJWT = axios.create();
  const accessToken1 = localStorage.getItem("token");
  const response = await axiosJWT.get(`${API_URL}/priority`, {
    headers: {
      Authorization: `Bearer ${accessToken1}`,
    },
  });
  return response.data;
};

export const getCycle = async () => {
  const axiosJWT = axios.create();
  const accessToken1 = localStorage.getItem("token");
  const response = await axiosJWT.get(`${API_URL}/cycle`, {
    headers: {
      Authorization: `Bearer ${accessToken1}`,
    },
  });

  return response.data;
};

export const getProduct = async () => {
  const axiosJWT = axios.create();
  const accessToken1 = localStorage.getItem("token");
  const response = await axiosJWT.get(`${API_URL}/product`, {
    headers: {
      Authorization: `Bearer ${accessToken1}`,
    },
  });

  return response.data;
};

export const getTeam = async () => {
  const axiosJWT = axios.create();
  const accessToken1 = localStorage.getItem("token");
  const response = await axiosJWT.get(`${API_URL}/team`, {
    headers: {
      Authorization: `Bearer ${accessToken1}`,
    },
  });
  return response.data;
};

export const getSource = async () => {
  const axiosJWT = axios.create();
  const accessToken1 = localStorage.getItem("token");
  const response = await axiosJWT.get(`${API_URL}/source`, {
    headers: {
      Authorization: `Bearer ${accessToken1}`,
    },
  });
  return response.data;
};
