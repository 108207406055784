/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { getSubTickets, getTickets } from "../tickets/Lists/ListShow";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../ApiUrl";
import { MDBDataTableV5 } from "mdbreact";
import ViewTicketModal from "./ViewTicketModal";
import "./DashboardCardTable.css";

export default function DashboardCardTable({
  tickets,
  setCurrentDateTime,
  setTickets,
  assignTicketView,
  setParentNo,
  childNo,
  setSubTicketList,
  setAssign,
}) {
  const [unassignedTickets, setUnassignedTickets] = useState([]);
  const [overdueTicket, setOverdueTickets] = useState([]);
  const [pendingTickets, setPendingTickets] = useState([]);
  const [assignedTicket, setAssignedTickets] = useState([]);
  const [completedTickets, setCompletedTickets] = useState([]);
  const [ticketsName, setTicketsName] = useState("Ticket List");

  const [showModal, setShowModal] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [viewTicket, setViewTicket] = useState({});

  const [currentProject, setCurrentProject] = useState([]);
  const navigate = useNavigate();

  const roles = sessionStorage.getItem("role");
  const username = sessionStorage.getItem("username");
  const accessToken1 = localStorage.getItem("token");

  const handleCardClick = (data) => {
    setTickets(data);
  };

  useEffect(() => {
    const fetchTickets = async () => {
      const data = await getTickets();
      const data1 = await getSubTickets();
      const parentValue = data1.map((element) => element.parent_no);
      setParentNo(parentValue.toString().replace(/,/g, ""));
      const filteredTickets = data.filter(
        (element) => element.status === "Assigned" && element.phase !== "Entrée"
      );
      if (assignTicketView && assignTicketView.length > 0) {
        setTickets(assignTicketView);
      } else {
        setTickets(filteredTickets);
      }
      const response = await axios.get(`${API_URL}/getModules`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });

      const moduleData = response.data;
      localStorage.setItem("modules", JSON.stringify(moduleData));
    };
    fetchTickets();
  }, []);

  useEffect(() => {
    const axiosJWT = axios.create();

    const getAllTicket = async () => {
      try {
        const response = await axiosJWT.get(`${API_URL}/tickets`, {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        });
        let data;
        let currentProjectdata;
        let unassingedData;
        unassingedData = response.data;
        data = response.data.filter((data) => {
          return data.assign === username && data.phase !== "Entrée";
        });
        currentProjectdata = response.data.filter((data) => {
          return data.assign === username;
        });

        if (roles === "Admin") {
          const unAssignedTickets = unassingedData.filter(
            (ticket) =>
              ticket.status === "Unassigned" &&
              ticket.phase !== "Entrée" &&
              ticket.status !== "Duplicate"
          );
          setUnassignedTickets(unAssignedTickets);

          const overdueTickets = unassingedData.filter((ticket) => {
            return (
              ticket.status !== "Closed" &&
              ticket.status !== "Completed" &&
              ticket.phase !== "Entrée" &&
              ticket.status !== "Duplicate" &&
              new Date(ticket.dead_line) < new Date()
            );
          });
          setOverdueTickets(overdueTickets);

          const assignedTickets = unassingedData.filter(
            (ticket) =>
              ticket.status === "Assigned" &&
              ticket.phase !== "Entrée" &&
              ticket.status !== "Duplicate"
          );

          setAssignedTickets(assignedTickets);

          const completedTickets = unassingedData.filter(
            (ticket) =>
              ticket.status === "Completed" &&
              ticket.phase !== "Entrée" &&
              ticket.status !== "Duplicate"
          );
          setCompletedTickets(completedTickets);

          const currentTicketCount = unassingedData.filter(
            (ticket) =>
              ticket.status !== "Closed" &&
              !ticket.parent_no &&
              ticket.status !== "Duplicate"
          );
          setCurrentProject(currentTicketCount);
        } else {
          const filteredOverdueTickets = data.filter(
            (item) =>
              item.status !== "Closed" &&
              item.status !== "Completed" &&
              item.status !== "Duplicate" &&
              new Date(item.dead_line) < new Date() &&
              item.status !== "Duplicate"
          );
          setOverdueTickets(filteredOverdueTickets);
          const processingTickets = data.filter((item) => {
            return (
              item.status === "Assigned" &&
              item.status !== "Duplicate" &&
              item.phase !== "Entrée"
            );
          });
          setAssignedTickets(processingTickets);

          const pendingTickets = data.filter((item) => {
            return item.status === "Pending" && item.status !== "Duplicate";
          });
          setPendingTickets(pendingTickets);

          const completedTickets = data.filter(
            (item) => item.status === "Completed" && item.status !== "Duplicate"
          );
          setCompletedTickets(completedTickets);

          let currentTicketCount = [];

          currentTicketCount = currentProjectdata.filter((item) => {
            return (
              item.status !== "Closed" &&
              !item.parent_no &&
              item.status !== "Duplicate"
            );
          });
          setCurrentProject(currentTicketCount);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    getAllTicket();
  }, [accessToken1, username, navigate, setCurrentDateTime]);

  const handleRowClick = async (ticketId) => {
    localStorage.setItem("ticket_id", ticketId);
    localStorage.setItem("showcompletedbtn", false);
    const ticketResponse = await axios.get(`${API_URL}/tickets/${ticketId}`, {
      headers: {
        Authorization: `Bearer ${accessToken1}`,
      },
    });

    localStorage.setItem("viewTicket", JSON.stringify(ticketResponse.data));

    const subTicketResponse = await axios.get(
      `${API_URL}/filteredSubTickets/${ticketId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      }
    );

    const subTickets = subTicketResponse.data;
    const updateLocalStorageBasedOnStatus = (subTickets) => {
      let hasPendingStatus = false;

      if (subTickets.length === 0) {
        localStorage.setItem("status", "completed");

        return;
      }
      for (let i = 0; i < subTickets.length; i++) {
        const ticketStatus = subTickets[i].status;

        if (ticketStatus !== "Completed" && ticketStatus !== "Closed") {
          hasPendingStatus = true;
          break;
        }
      }

      if (hasPendingStatus) {
        localStorage.setItem("status", "pending");
      } else {
        localStorage.setItem("status", "completed");
      }
    };

    updateLocalStorageBasedOnStatus(subTickets);
    setViewTicket(ticketResponse.data);
    setSelectedTicketId(ticketId);
    setShowModal(true);
  };

  const statusOrder = [
    "Pending",
    "unassigned",
    "Assigned",
    "Completed",
    "Closed",
    "Duplicate",
  ];

  const getStatusOrderIndex = (status) => statusOrder.indexOf(status);
  const sortedTickets = [...tickets]
    .filter(
      (ticket) =>
        roles === "Admin" ||
        (ticket.assign === username && ticket.status !== "Duplicate")
    )
    .sort((a, b) => {
      if (ticketsName === "Assigned Tickets") {
        if (a.priority !== null && b.priority !== null) {
          if (a.priority !== b.priority) {
            return a.priority - b.priority;
          }
        } else if (a.priority !== null) {
          return -1;
        } else if (b.priority !== null) {
          return 1;
        }

        const deadlineA = new Date(a.dead_line);
        const deadlineB = new Date(b.dead_line);
        if (deadlineA !== deadlineB) {
          return deadlineA - deadlineB;
        }

        const statusComparison =
          getStatusOrderIndex(a.status) - getStatusOrderIndex(b.status);
        if (statusComparison !== 0) return statusComparison;

        const ticketIdA = parseInt(a.ticket_id.slice(1), 10);
        const ticketIdB = parseInt(b.ticket_id.slice(1), 10);
        const ticketIdComparison = ticketIdB - ticketIdA;
        if (ticketIdComparison !== 0) return ticketIdComparison;

        return new Date(b.lastUpdated) - new Date(a.lastUpdated);
      } else {
        const deadlineA = new Date(a.dead_line);
        const deadlineB = new Date(b.dead_line);
        return deadlineA - deadlineB;
      }
    });

  const datatables = {
    columns: [
      {
        label: "id",
        field: "id",
      },
      {
        label: "ticket_id",
        field: "ticket_id",
      },
      {
        label: "client",
        field: "client",
      },
      {
        label: "product",
        field: "product",
      },
      {
        label: "status",
        field: "status",
      },
      {
        label: "subject",
        field: "subject",
      },
      {
        label: "phase",
        field: "phase",
      },

      {
        label: "assign",
        field: "assign",
      },
    ],

    rows: sortedTickets.map((ticket, index) => {
      return {
        ...ticket,
        id: index + 1,
        clickEvent: () => handleRowClick(ticket.ticket_id),
      };
    }),
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedTickets.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(tickets.length / itemsPerPage);
  const maxPageButtons = 5; // Number of page links to display

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const ellipsis = <span className="ellipsis">...</span>;

    const startPage = Math.max(2, currentPage - Math.floor(maxPageButtons / 2));
    const endPage = Math.min(
      totalPages - 1,
      currentPage + Math.floor(maxPageButtons / 2)
    );

    // Always show the first page
    pageNumbers.push(
      <button
        key={1}
        className={`pagination-btn ${currentPage === 1 ? "active" : ""}`}
        onClick={() => handlePageChange(1)}
      >
        1
      </button>
    );

    // Add ellipsis if there's a gap between the first page and the startPage
    if (startPage > 2) {
      pageNumbers.push(ellipsis);
    }

    // Add page numbers around the current page
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`pagination-btn ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    // Add ellipsis if there's a gap between the endPage and the last page
    if (endPage < totalPages - 1) {
      pageNumbers.push(ellipsis);
    }

    // Always show the last page
    if (totalPages > 1) {
      pageNumbers.push(
        <button
          key={totalPages}
          className={`pagination-btn ${
            currentPage === totalPages ? "active" : ""
          }`}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };
  return (
    <div>
      <div className="containers">
        {roles === "Admin" ? (
          <div className="cards bg-secondary text-white">
            <div className="icon">
              <i className="fas fa-user-friends"></i>
            </div>
            <div className="subheader">Unassigned Tickets</div>
            <div
              className="value"
              onClick={() => {
                handleCardClick(unassignedTickets);
                setTicketsName("Unassigned Tickets");
              }}
            >
              {unassignedTickets.length}
            </div>
          </div>
        ) : (
          <div className="cards bg-secondary text-white">
            <div className="icon">
              <i className="fas fa-user-friends"></i>
            </div>
            <div className="subheader">Pending Tickets</div>
            <div
              className="value"
              onClick={() => {
                handleCardClick(pendingTickets);
                setTicketsName("Pending Tickets");
              }}
            >
              {pendingTickets.length}
            </div>
          </div>
        )}
        <div className="cards cards-success">
          <div className="icon large-icon">
            <i className="fas fa-check-circle"></i>
          </div>
          <div className="subheader">OverDue Tickets</div>
          <div
            className="value"
            onClick={() => {
              handleCardClick(overdueTicket);
              setTicketsName("OverDue Tickets");
            }}
          >
            {overdueTicket.length}
          </div>
        </div>
        <div className="cards cards-warning">
          <div className="icon large-icon">
            <i className="fas fa-clock"></i>
          </div>
          <div className="subheader">Assigned Tickets</div>
          <div
            className="value"
            onClick={() => {
              handleCardClick(assignedTicket);
              setTicketsName("Assigned Tickets");
            }}
          >
            {assignedTicket.length}
          </div>
        </div>
        <div className="cards cards-danger">
          <div className="icon">
            <i className="fas fa-check"></i>
          </div>
          <div className="subheader">Completed</div>
          <div
            className="value"
            onClick={() => {
              handleCardClick(completedTickets);
              setTicketsName("Completed");
            }}
          >
            {completedTickets.length === 0 ? "0" : completedTickets.length}
          </div>
        </div>
        <div className="cards ard text-white bg-dark">
          <div className="icon">
            <i className="fas fa-check"></i>
          </div>
          <div className="subheader">Current Project</div>
          <div
            className="value"
            onClick={() => {
              handleCardClick(currentProject);
              setTicketsName("Current Project");
            }}
          >
            {currentProject.length}
          </div>
        </div>
      </div>
      <div className="col-10" style={{ marginLeft: "130px" }}>
        <div className="card ">
          <div className="card-header">
            <h3 className="carhd-title">{ticketsName}</h3>
          </div>
          <div className="container table-responsive ">
            <style>
              {`
          .mdb-datatable-info {
            display: none !important;
          }
          .mdb-dataTables_paginate {
            display: none !important;
          }
        `}
            </style>

            <MDBDataTableV5
              data={{
                columns: datatables.columns,
                rows: currentItems.map((ticket, index) => ({
                  ...ticket,
                  id: index + 1, // Continuous display ID
                  clickEvent: () => handleRowClick(ticket.ticket_id),
                })),
              }}
              noRecordsFoundLabel="No tickets found"
              striped
              hover
              sortable
              searchTop
              searchBottom={false}
              paging={false} // Disable internal pagination
              entries={itemsPerPage}
            />

            <div className="pagination-container">
              <button
                className="pagination-btn"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              {renderPageNumbers()}
              <button
                className="pagination-btn"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>

            <ViewTicketModal
              showModal={showModal}
              setShowModal={setShowModal}
              ticketId={selectedTicketId}
              viewTicket={viewTicket || {}}
              childNo={childNo}
              setSubTicketList={setSubTicketList}
              setParentNo={setParentNo}
              setAssign={setAssign}
              setViewTicket={setViewTicket}
            />

            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
}
