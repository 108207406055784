import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Dashboard from "./dashboard/Dashboard";
import Login from "./login/Login";
import Client from "./masters/client/Client";
import Users from "./masters/users/Users";
import Navbar from "./template/Navbar";
import Phase from "./masters/Phase/Phase";
import TaskStatus from "./masters/TaskStatus/TaskStatus";
import Product from "./masters/Product/Product";
import Team from "./masters/Team/Team";
import Source from "./masters/Source/Source";
import Ticket from "./tickets/Tickets";
import ViewTicket from "./tickets/viewTickets";
import SubTickets from "./tickets/subTickets";
import Reports from "./Reports/Reports";
import Priority from "./masters/priority/priority";
import Role from "./Role/Role";
import Excel from "./excel/Excel";
import Module from "./modules/Modules";
import Cycle from "./masters/cycle/Cycle";
import CommentReports from "./Reports/CommentReports";
import ProductReports from "./Reports/ProductReports";
import EmployeeReports from "./Reports/EmployeeReports";
import NumberofBugesReports from "./Reports/NumberofBugesReports";
import { WeeklyEmployeeReviewForm } from "./ReviewForm/WeeklyEmployeeReviewForm";
import WeeklyApprovalReports from "./ReviewForm/WeeklyApprovalReports";
import StatusReports from "./Reports/StatusReports";

function App() {
  const [assign, setAssign] = useState({
    assignValue: "",
    assignDropDownValues: [],
  });

  const [priority, setPriority] = useState({
    priorityValue: "",
    priorityDropDownValues: [],
  });
  const [product, setProduct] = useState({
    productValue: "",
    productDropDownValues: [],
  });

  const [client, setClient] = useState({
    clientValue: "",
    clientDropDownValues: [],
  });
  const [toAssign, setToAssign] = useState({
    toAssignValue: "",
    toAssignDropDownValues: [],
  });
  const [status, setStatus] = useState({
    statusValue: "",
    statusDropDownValues: [],
  });
  const [cycle, setCycle] = useState({
    cycleValue: "",
    cycleDropDownValues: [],
  });

  const [primaryId, setPrimaryId] = useState("");
  const [childNo, setChildNo] = useState("");
  const [completed_date_time, setCompleted_date_time] = useState("");
  const [dead_line, setDead_line] = useState("");
  const [initial_date_time, setInitial_date_time] = useState("");
  const [mark, setMark] = useState("");
  const [parentNo, setParentNo] = useState("");
  const [phase, setPhase] = useState({
    phaseValue: [], // Initialize phaseValue as an empty array
    phaseDropDownValues: [], // Initialize phaseDropDownValues as needed
  });
  const [source, setSource] = useState({
    sourceValue: "",
    sourceDropDownValues: [],
  });
  const [subject, setSubject] = useState([]);
  const [task, setTask] = useState();
  const [teams, setTeams] = useState({
    teamsValue: "",
    teamsDropDownValues: [],
  });

  const [ticket_id, setTicketId] = useState("");
  const [subTicketList, setSubTicketList] = useState([]);
  const [commentArea, setCommentArea] = useState([]);
  const [viewTicket, setViewTicket] = useState({});
  const [dashboardSubTicket, setDashboardSubTicket] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [assignTicketView, setAssignTicketView] = useState([]);
  const [newTickets, setNewTickets] = useState(false);
  const [duration, setDuration] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });

  useEffect(() => {
    localStorage.setItem("assignState", JSON.stringify(assign));
  }, [assign]);

  const extendedContext = {
    assign,
    setAssign,
    priority,
    setPriority,
    viewTicket,
    setViewTicket,
    product,
    setProduct,
    client,
    setClient,
    toAssign,
    setToAssign,
    status,
    setStatus,
    setCycle,
    cycle,
    ticket_id,
    setTicketId,
    childNo,
    completed_date_time,
    dead_line,
    initial_date_time,
    mark,
    parentNo,
    phase,
    source,
    subject,
    task,
    teams,
    setChildNo,
    setCompleted_date_time,
    setDead_line,
    setInitial_date_time,
    setMark,
    setParentNo,
    setPhase,
    setSource,
    setSubject,
    setTask,
    setTeams,
    setPrimaryId,
    primaryId,
    setCurrentDateTime,
    currentDateTime,
    subTicketList,
    setSubTicketList,
    setCommentArea,
    commentArea,
    setDashboardSubTicket,
    dashboardSubTicket,
    setTickets,
    tickets,
    setAssignTicketView,
    assignTicketView,
    setNewTickets,
    newTickets,
    duration,
    setDuration,
  };
  const screens = sessionStorage.getItem("screens");
  const screenActions = screens ? screens.split(",") : [];
  const allowedScreens = [];

  screenActions.forEach((action) => {
    const [screen] = action.split(" ");
    if (screen) {
      allowedScreens.push(screen);
    }
  });

  return (
    <div>
      <BrowserRouter basename="/">
     <Routes>
          <Route
            path="/"
            element={
              <>
                <Login />
              </>
            }
          />
          <Route
            path="/dashboard"
            element={
              <>
                <Navbar {...extendedContext} />
                <Dashboard {...extendedContext} />
              </>
            }
          />
          {allowedScreens.includes("Masters") && (
            <>
              {" "}
              <Route
                path="/client"
                element={
                  <>
                    <Navbar {...extendedContext} />
                    <Client />
                  </>
                }
              />
              <Route
                path="/taskstatus"
                element={
                  <>
                    <Navbar {...extendedContext} />
                    <TaskStatus />
                  </>
                }
              />
              <Route
                path="/phase"
                element={
                  <>
                    <Navbar {...extendedContext} />
                    <Phase />
                  </>
                }
              />
              <Route
                path="/product"
                element={
                  <>
                    <Navbar {...extendedContext} />
                    <Product />
                  </>
                }
              />
              <Route
                path="/team"
                element={
                  <>
                    <Navbar {...extendedContext} />
                    <Team />
                  </>
                }
              />
              <Route
                path="/priority"
                element={
                  <>
                    <Navbar {...extendedContext} />
                    <Priority {...extendedContext} />
                  </>
                }
              />
              <Route
                path="/source"
                element={
                  <>
                    <Navbar {...extendedContext} />
                    <Source />
                  </>
                }
              />
            </>
          )}
          {allowedScreens.includes("Users") && (
            <>
              {screens !== undefined && screens.includes("Users New") && (
                <Route
                  path="/users"
                  element={
                    <>
                      <Navbar {...extendedContext} />
                      <Users />
                    </>
                  }
                />
              )}
              {screens !== undefined && screens.includes("Users Role") && (
                <Route
                  path="/role"
                  element={
                    <>
                      <Navbar {...extendedContext} />
                      <Role {...extendedContext} />
                    </>
                  }
                />
              )}
            </>
          )}
          {allowedScreens.includes("Ticket") && (
            <>
              {" "}
              <Route
                path="/ticketReports"
                element={
                  <>
                    <Navbar {...extendedContext} />{" "}
                    <Ticket {...extendedContext} />
                  </>
                }
              />
              <Route
                path="/commentreports"
                element={
                  <>
                    <Navbar {...extendedContext} />{" "}
                    <CommentReports {...extendedContext} />
                  </>
                }
              />
              <Route
                path="/productreports"
                element={
                  <>
                    <Navbar {...extendedContext} />{" "}
                    <ProductReports {...extendedContext} />
                  </>
                }
              />
              <Route
                path="/employeereports"
                element={
                  <>
                    <Navbar {...extendedContext} />{" "}
                    <EmployeeReports {...extendedContext} />
                  </>
                }
              />
              <Route
                path="/bugsreports"
                element={
                  <>
                    <Navbar {...extendedContext} />{" "}
                    <NumberofBugesReports {...extendedContext} />
                  </>
                }
              />
              <Route
                path="/viewTickets"
                element={
                  <>
                    {" "}
                    <Navbar {...extendedContext} />{" "}
                    <ViewTicket {...extendedContext} />{" "}
                  </>
                }
              />
              <Route
                path="/subTickets"
                element={
                  <>
                    <Navbar {...extendedContext} />
                    <SubTickets {...extendedContext} />
                  </>
                }
              />
            </>
          )}
          {allowedScreens.includes("Reports") && (
            <Route
              path="/reports"
              element={
                <>
                  <Navbar {...extendedContext} />
                  <Reports {...extendedContext} />
                </>
              }
            />
          )}
          <Route
            path="/excel"
            element={
              <>
                <Navbar {...extendedContext} />
                <Excel {...extendedContext} />
              </>
            }
          />
          <Route
            path="/modules"
            element={
              <>
                <Navbar {...extendedContext} />
                <Module {...extendedContext} />
              </>
            }
          />
          <Route
            path="/reviewform"
            element={
              <>
                <Navbar {...extendedContext} />
                <WeeklyEmployeeReviewForm {...extendedContext} />
              </>
            }
          />
          <Route
            path="/weeklyreports"
            element={
              <>
                <Navbar {...extendedContext} />
                <WeeklyApprovalReports {...extendedContext} />
              </>
            }
          />
          <Route
            path="/cycle"
            element={
              <>
                <Navbar {...extendedContext} />
                <Cycle {...extendedContext} />
              </>
            }
          />
          <Route
            path="/statusreports"
            element={
              <>
                <Navbar {...extendedContext} />
                <StatusReports {...extendedContext} />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
