/* eslint-disable react-hooks/exhaustive-deps */
import { MDBDataTableV5 } from "mdbreact";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../ApiUrl";
import axios from "axios";

export default function DashboardDropdown({ setViewTicket }) {
  const [dropdownValue, setDropDownValues] = useState([]);
  const [assignedTicket, setAssignedTickets] = useState([]);
  const [unassignedTickets, setUnassignedTickets] = useState([]);
  const [reportingTicket, setReportingTicket] = useState([]);

  const navigate = useNavigate();

  const roles = sessionStorage.getItem("role");
  const username = sessionStorage.getItem("username");
  const accessToken1 = localStorage.getItem("token");

  useEffect(() => {
    const axiosJWT = axios.create();

    const getAllTicket = async () => {
      try {
        const response = await axiosJWT.get(`${API_URL}/tickets`, {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        });
        let data;
        let unassingedData;
        unassingedData = response.data;
        data = response.data.filter((data) => {
          return data.assign === username && data.phase !== "Entrée";
        });

        // }

        if (roles === "Admin") {
          const unAssignedTickets = unassingedData.filter(
            (ticket) =>
              ticket.status === "Unassigned" &&
              ticket.phase !== "Entrée" &&
              ticket.status !== "Duplicate"
          );
          setUnassignedTickets(unAssignedTickets);

          const assignedTickets = unassingedData.filter(
            (ticket) =>
              ticket.status === "Assigned" &&
              ticket.phase !== "Entrée" &&
              ticket.status !== "Duplicate"
          );
          setAssignedTickets(assignedTickets);
        } else {
          const processingTickets = data.filter((item) => {
            return item.status === "Assigned" && item.status !== "Duplicate";
          });
          setAssignedTickets(processingTickets);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    const getTicketByUserName = async () => {
      try {
        const response = await axiosJWT.get(`${API_URL}/tickets`, {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        });
        const filteredTickets = response.data.filter((ticket) => {
          return ticket.toAssign === username && ticket.phase !== "Entrée";
        });

        setReportingTicket(filteredTickets);
      } catch (error) {
        console.error("Error fetching reporting tickets: ", error);
      }
    };

    getAllTicket();
    getTicketByUserName();
  }, []);

  const handleDropDown = (showCompletedBtn, value) => {
    if (showCompletedBtn === "assigned") {
      localStorage.setItem("showcompletedbtn", true);
    } else {
      localStorage.setItem("showcompletedbtn", false);
    }
    setDropDownValues(value);
  };

  const handleView = async (ticketById) => {
    localStorage.setItem("ticket_id", ticketById);
    sessionStorage.setItem("buttonFlag", false);

    const response1 = await axios.get(`${API_URL}/tickets/${ticketById}`, {
      headers: {
        Authorization: `Bearer ${accessToken1}`,
      },
    });
    setViewTicket(response1.data);
    localStorage.setItem("viewTicket", JSON.stringify(response1.data));

    const subTicketResponse = await axios.get(
      `${API_URL}/filteredSubTickets/${ticketById}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      }
    );

    const subTickets = subTicketResponse.data;
    const updateLocalStorageBasedOnStatus = (subTickets) => {
      let hasPendingStatus = false;

      if (subTickets.length === 0) {
        localStorage.setItem("status", "completed");

        return;
      }
      for (let i = 0; i < subTickets.length; i++) {
        const ticketStatus = subTickets[i].status;

        if (ticketStatus !== "Completed" && ticketStatus !== "Closed") {
          hasPendingStatus = true;
          break;
        }
      }

      if (hasPendingStatus) {
        localStorage.setItem("status", "pending");
      } else {
        localStorage.setItem("status", "completed");
      }
    };

    updateLocalStorageBasedOnStatus(subTickets);

    navigate("/viewTickets");
  };

  const sortedDropdownTickets = [...dropdownValue]
    .filter((ticket) => {
      return (
        ticket.toAssign === username ||
        ticket.status === "Assigned" ||
        ticket.status === "Unassigned"
      );
    })
    .sort((a, b) => {
      const priorityA = a.priority !== null ? a.priority : Infinity;
      const priorityB = b.priority !== null ? b.priority : Infinity;

      return priorityA - priorityB;
    });

  const datatable = {
    columns: [
      {
        label: <span className="table-header">ID</span>,
        field: "id",
      },
      {
        label: <span className="table-header">Subject</span>,
        field: "subject",
      },
      {
        label: <span className="table-header">Ticket ID</span>,
        field: "ticket_id",
      },
      {
        label: <span className="table-header">Client</span>,
        field: "client",
      },
      {
        label: <span className="table-header">Phase</span>,
        field: "phase",
      },
      {
        label: <span className="table-header">Deadline</span>,
        field: "dead_line",
      },
      {
        label: <span className="table-header">Status</span>,
        field: "status",
      },
    ],
    rows: sortedDropdownTickets.map((ticket, index) => {
      return {
        ...ticket,
        id: index + 1,
        clickEvent: () => handleView(ticket.ticket_id),
      };
    }),
  };

  return (
    <div className="containers-xl">
      <div className="cards">
        <div className="cards-body">
          <div className="accordion" id="accordion-example">
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading-assigned">
                <button
                  className="accordion-button collapsed"
                  onClick={() => handleDropDown("assigned", assignedTicket)}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-assigned"
                  aria-expanded="false"
                >
                  <h2 className="mb-3">
                    Assigned Tickets ({assignedTicket.length} Tickets)
                  </h2>
                </button>
              </h2>
              <div
                id="collapse-assigned"
                className="accordion-collapse collapse"
                data-bs-parent="#accordion-example"
              >
                <div className="page-body">
                  <div className="container-xl">
                    <div className="row row-cards">
                      <div className="col-12">
                        <div className="card">
                          <div className="container table-responsive mt-3">
                            <h2 className="table-heading">Assigned List</h2>
                            <MDBDataTableV5
                              striped
                              fixed
                              hover
                              entriesOptions={[10, 20, 30, 50]}
                              entries={10}
                              pagesAmount={4}
                              data={datatable}
                              searchTop
                              searchBottom={false}
                              responsiveMd
                              className="custom-table"
                            />
                            <ToastContainer />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {roles !== undefined && roles.includes("Admin") && ( */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading-reporting">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  onClick={() => handleDropDown("reporting", reportingTicket)}
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-reporting"
                  aria-expanded="false"
                >
                  <h2 className="mb-3">
                    Reporting To Me ({reportingTicket.length} Tickets)
                  </h2>
                </button>
              </h2>
              <div
                id="collapse-reporting"
                className="accordion-collapse collapse"
                data-bs-parent="#accordion-example"
              >
                <div className="page-body">
                  <div className="container-xl">
                    <div className="row row-cards">
                      <div className="col-12">
                        <div className="card">
                          <div className="container table-responsive mt-3">
                            <h3 className="table-heading">Reporting List</h3>
                            <MDBDataTableV5
                              striped
                              fixed
                              hover
                              entriesOptions={[10, 20, 30, 50]}
                              entries={10}
                              pagesAmount={4}
                              data={datatable}
                              searchTop
                              searchBottom={false}
                              responsiveMd
                              className="custom-table"
                            />
                            <ToastContainer />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}
            {roles !== undefined && roles.includes("Admin") && (
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="heading-some-other-section"
                >
                  <button
                    className="accordion-button"
                    type="button"
                    onClick={() =>
                      handleDropDown("unassigned", unassignedTickets)
                    }
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-some-other-section"
                    aria-expanded="false"
                  >
                    <h2 className="mb-3">
                      {" "}
                      Unassigned Tickets ({unassignedTickets.length} Tickets)
                    </h2>
                  </button>
                </h2>
                <div
                  id="collapse-some-other-section"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordion-example"
                >
                  <div className="page-body">
                    <div className="container-xl">
                      <div className="row row-cards">
                        <div className="col-12">
                          <div className="card">
                            <div className="container table-responsive mt-3">
                              <h3 className="table-heading">UnAssigned List</h3>
                              <MDBDataTableV5
                                striped
                                fixed
                                hover
                                entriesOptions={[10, 20, 30, 50]}
                                entries={10}
                                pagesAmount={4}
                                data={datatable}
                                searchTop
                                searchBottom={false}
                                responsiveMd
                                className="custom-table"
                              />
                              <ToastContainer />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
