import React, { useEffect, useState } from "react";
import { Button, Alert } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../ApiUrl";
import { getProduct } from "../masters/Lists/ListShow";
import LineChart from "./Flowchart";
import CommentChart from "./CommentChart";
import CommentByEmployeesChart from "./CommentByEmployeesChart";

const ProductReports = ({ product, setProduct }) => {
  const [productsData, setProductsData] = useState([]);
  const [accumulatedComments, setAccumulatedComments] = useState([]); // State for accumulated comments
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProductValues = async () => {
      try {
        const productData = await getProduct();
        console.log("Fetched Product Data:", productData); // Log the product data
        setProduct({
          productValue: "",
          productDropDownValues: productData,
        });
      } catch (err) {
        console.error("Error fetching product data:", err);
        setError("Error fetching product data");
      }
    };
    fetchProductValues();
  }, [setProduct]);

  const token = localStorage.getItem("token");
  const { productValue, productDropDownValues } = product || {};

  const handleSubmit = async (productName) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${API_URL}/getProductByTicket/${productName}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Check if response data is an array
      if (!Array.isArray(response.data)) {
        throw new Error("Unexpected response format");
      }

      const sortedData = response.data.sort(
        (a, b) => new Date(a.created_date_time) - new Date(b.created_date_time)
      );
      setProductsData(sortedData);

      const comments = await logTicketComments(productName); // Pass productName to get comments
      setAccumulatedComments(comments);
    } catch (error) {
      setError("Error fetching data");
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const logTicketComments = async (productName) => {
    const comments = [];

    console.log(`Fetching comments for Product Name: ${productName}`); // Log the productName

    if (!productName) {
      console.error("Product name is undefined or empty");
      return comments; // Return empty comments if productName is invalid
    }

    try {
      const commentResponse = await axios.get(
        `${API_URL}/commentbyname/${productName}`, // Updated API endpoint
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (commentResponse.status === 200) {
        const ticketComments = commentResponse.data;
        console.log(
          `Comments for Product Name ${productName}:`,
          ticketComments
        ); // Log the comments

        if (Array.isArray(ticketComments) && ticketComments.length > 0) {
          comments.push(...ticketComments);
        }
      } else {
        console.error(`Unexpected response status: ${commentResponse.status}`);
      }
    } catch (error) {
      console.error(
        `Error fetching comments for Product Name ${productName}:`,
        error
      );
      // Log additional error details
      if (error.response) {
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      } else if (error.request) {
        console.error("Error request data:", error.request);
      } else {
        console.error("General error:", error.message);
      }
    }

    console.log("Accumulated Comments:", comments);
    return comments;
  };

  const handleClear = () => {
    setProduct({ ...product, productValue: "" });
    setProductsData([]);
    setAccumulatedComments([]); // Clear accumulated comments on clear
  };

  const handleProductChange = (e) => {
    const selectedProductName = e.target.value;

    setProduct({
      ...product,
      productValue: selectedProductName,
    });
    handleSubmit(selectedProductName);
  };

  return (
    <div className="d-flex justify-content-center mt-4">
      <div className="col-11">
        <div className="card custom-card">
          <div className="row">
            <div className="col-md-4 custom-selector">
              <h2>Product</h2>
              <select
                className="form-select"
                aria-label="Default select example"
                value={productValue}
                onChange={handleProductChange}
              >
                <option value="">Please choose one option</option>
                {productDropDownValues &&
                  productDropDownValues.map((option) => (
                    <option key={option.id} value={option.product}>
                      {option.product}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="d-grid gap-2 d-md-flex justify-content-md-end custom_btn mt-3">
            <Button variant="secondary" onClick={handleClear}>
              Clear
            </Button>
          </div>
        </div>
        {error && (
          <Alert variant="danger" className="mt-4">
            {error}
          </Alert>
        )}
        {loading && <p>Loading...</p>}
        {productsData.length > 0 && (
          <div className="mt-4">
            <h3 style={{ marginLeft: "4%" }}>Product Creation Over Month</h3>
            <LineChart data={productsData} />
          </div>
        )}
        {accumulatedComments.length > 0 && (
          <div className="mt-4">
            <h3 style={{ marginLeft: "4%" }}>Product Comments Over Month</h3>
            <CommentChart data={accumulatedComments} />
          </div>
        )}
        {accumulatedComments.length > 0 && (
          <div className="mt-4" style={{ marginLeft: "3%" }}>
            <h3>Comment Created by Employee Time Taken</h3>

            <CommentByEmployeesChart data={accumulatedComments} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductReports;
