/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../ApiUrl";
import ApexCharts from "react-apexcharts";
import { getSubTickets, getTickets } from "../tickets/Lists/ListShow";
import DashboardDropdown from "./DashboardDropdown";
import DashboardCardTable from "./DashboardCardTable";

export default function Dashboard({
  tickets,
  setCurrentDateTime,
  setViewTicket,
  setTickets,
  assignTicketView,
  setParentNo,
  viewTicket,
  setAssign,
  setSubTicketList,
}) {
  const [alltickets, setAllTickets] = useState([]);
  const [unassignedTickets, setUnassignedTickets] = useState([]);
  const [pendingTickets, setPendingTickets] = useState([]);
  const [assignedTicket, setAssignedTickets] = useState([]);
  const [completedTickets, setCompletedTickets] = useState([]);
  const [dropdownValue, setDropDownValues] = useState([]);
  const navigate = useNavigate();

  const accessToken1 = localStorage.getItem("token");
  const username = sessionStorage.getItem("username");
  const roles = sessionStorage.getItem("role");
  const roleActions = roles ? roles.split(",") : [];
  const allowedRole = [];

  roleActions.forEach((action) => {
    const [role] = action.split(" ");
    if (role) {
      allowedRole.push(role);
    }
  });

  // useEffect(() => {
  //   // Check if this is the initial render
  //   if (!hasRendered.current) {
  //     const fetchTickets = async () => {
  //       try {
  //         // Fetch tickets and subtickets
  //         const [data, data1] = await Promise.all([
  //           getTickets(),
  //           getSubTickets(),
  //         ]);

  //         // Extract parent values
  //         const parentValue = data1.map((element) => element.parent_no);
  //         setParentNo(parentValue.toString().replace(/,/g, ""));

  //         // Filter tickets (only run on initial render)
  //         const filteredTickets = data.filter(
  //           (element) =>
  //             element.status === "Assigned" && element.phase !== "Entrée"
  //         );
  //         if (assignTicketView && assignTicketView.length > 0) {
  //           setTickets(assignTicketView);
  //         } else {
  //           setTickets(filteredTickets);
  //         }

  //         // Fetch modules data
  //         const response = await axios.get(`${API_URL}/getModules`, {
  //           headers: {
  //             Authorization: `Bearer ${accessToken1}`,
  //           },
  //         });
  //         localStorage.setItem("modules", JSON.stringify(response.data));

  //         // Mark as rendered to avoid reruns
  //         hasRendered.current = true;
  //       } catch (error) {
  //         console.error("Error fetching tickets or modules:", error);
  //       }
  //     };

  //     fetchTickets(); // Fetch data only on initial render
  //   }
  // }, []);

  useEffect(() => {
    const axiosJWT = axios.create();

    const getAllTicket = async () => {
      try {
        const response = await axiosJWT.get(`${API_URL}/tickets`, {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        });
        let data;
        let unassingedData;
        unassingedData = response.data;
        data = response.data.filter((data) => {
          return data.assign === username && data.phase !== "Entrée";
        });

        // }

        setAllTickets(data);

        if (roles === "Admin") {
          const unAssignedTickets = unassingedData.filter(
            (ticket) =>
              ticket.status === "Unassigned" &&
              ticket.phase !== "Entrée" &&
              ticket.status !== "Duplicate"
          );
          setUnassignedTickets(unAssignedTickets);

          const assignedTickets = unassingedData.filter(
            (ticket) =>
              ticket.status === "Assigned" &&
              ticket.phase !== "Entrée" &&
              ticket.status !== "Duplicate"
          );
          setAssignedTickets(assignedTickets);

          const completedTickets = unassingedData.filter(
            (ticket) =>
              ticket.status === "Completed" &&
              ticket.phase !== "Entrée" &&
              ticket.status !== "Duplicate"
          );
          setCompletedTickets(completedTickets);
        } else {
          const processingTickets = data.filter((item) => {
            return item.status === "Assigned" && item.status !== "Duplicate";
          });
          setAssignedTickets(processingTickets);

          const pendingTickets = data.filter((item) => {
            return item.status === "Pending" && item.status !== "Duplicate";
          });
          setPendingTickets(pendingTickets);

          const completedTickets = data.filter(
            (item) => item.status === "Completed" && item.status !== "Duplicate"
          );
          setCompletedTickets(completedTickets);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    getAllTicket();
  }, [accessToken1, username, navigate, setCurrentDateTime]);

  const handleView = async (ticketById) => {
    localStorage.setItem("ticket_id", ticketById);
    sessionStorage.setItem("buttonFlag", false);

    const response1 = await axiosJWT.get(`${API_URL}/tickets/${ticketById}`, {
      headers: {
        Authorization: `Bearer ${accessToken1}`,
      },
    });
    setViewTicket(response1.data);
    localStorage.setItem("viewTicket", JSON.stringify(response1.data));

    const subTicketResponse = await axios.get(
      `${API_URL}/filteredSubTickets/${ticketById}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      }
    );

    const subTickets = subTicketResponse.data;
    const updateLocalStorageBasedOnStatus = (subTickets) => {
      let hasPendingStatus = false;

      if (subTickets.length === 0) {
        localStorage.setItem("status", "completed");

        return;
      }
      for (let i = 0; i < subTickets.length; i++) {
        const ticketStatus = subTickets[i].status;

        if (ticketStatus !== "Completed" && ticketStatus !== "Closed") {
          hasPendingStatus = true;
          break;
        }
      }

      if (hasPendingStatus) {
        localStorage.setItem("status", "pending");
      } else {
        localStorage.setItem("status", "completed");
      }
    };

    updateLocalStorageBasedOnStatus(subTickets);

    navigate("/viewTickets");
  };

  const closedTicketData = new Array(12).fill(0);
  const assignedTicketData = new Array(12).fill(0);
  const completedTicketData = new Array(12).fill(0);
  const unAssignedTicketData = new Array(12).fill(0);

  alltickets.forEach((ticket) => {
    const ticketMonth = new Date(ticket.createdAt).getMonth();
    if (ticket.status === "Closed") {
      closedTicketData[ticketMonth]++;
    } else if (ticket.status === "Assigned") {
      assignedTicketData[ticketMonth]++;
    } else if (ticket.status === "Unassigned") {
      unAssignedTicketData[ticketMonth]++;
    } else if (ticket.status === "Completed") {
      completedTicketData[ticketMonth]++;
    }
  });

  const overallTicketData = new Array(12).fill(0);

  for (let i = 0; i < 12; i++) {
    overallTicketData[i] =
      closedTicketData[i] +
      assignedTicketData[i] +
      completedTicketData[i] +
      unAssignedTicketData[i];
  }

  const chartOptions = {
    chart: {
      type: "bar",
      fontFamily: "inherit",
      height: 300,
      width: 300,
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "95%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    series: [
      {
        name: "Closed Tickets",
        data: closedTicketData,
        color: "#2ecc71",
      },
      {
        name: "Overall Tickets",
        data: overallTicketData,
        color: "#3498db",
      },
      {
        name: "Assigned Tickets",
        data: assignedTicketData,
        color: "#e74c3c",
      },
      {
        name: "Completed Tickets",
        data: completedTicketData,
        color: "#f9c846",
      },
      {
        name: "UnAssigned Tickets",
        data: unAssignedTicketData,
        color: "#f946ae",
      },
    ],
    tooltip: {
      theme: "dark",
    },
    grid: {
      padding: {
        top: -20,
        right: 0,
        left: -4,
        bottom: -4,
      },
      strokeDashArray: 4,
    },
    xaxis: {
      labels: {
        padding: 0,
      },
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      categories: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    },
    yaxis: {
      labels: {
        padding: 4,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      offsetY: 0,
      labels: {
        useSeriesColors: false,
        colors: ["#2ecc71", "#3498db", "#e74c3c"],
        fontSize: "14px",
      },
      markers: {
        width: 16,
        height: 16,
        strokeWidth: 0,
        strokeColor: "#fff",
        radius: 12,
      },
      itemMargin: {
        vertical: 10,
      },
    },
    annotations: {
      labels: [
        {
          text: "Work",
          x: "100%",
          y: 10,
          borderColor: "#666",
          borderWidth: 1,
          textAnchor: "start",
        },
        {
          text: "Eat",
          x: "100%",
          y: 60,
          borderColor: "#666",
          borderWidth: 1,
          textAnchor: "start",
        },
        {
          text: "Commute",
          x: "100%",
          y: 110,
          borderColor: "#666",
          borderWidth: 1,
          textAnchor: "start",
        },
        {
          text: "Watch TV",
          x: "100%",
          y: 160,
          borderColor: "#666",
          borderWidth: 1,
          textAnchor: "start",
        },
        {
          text: "Sleep",
          x: "100%",
          y: 210,
          borderColor: "#666",
          borderWidth: 1,
          textAnchor: "start",
        },
      ],
    },
  };

  const axiosJWT = axios.create();

  const handleDropDown = (showCompletedBtn, value) => {
    if (showCompletedBtn === "assigned") {
      localStorage.setItem("showcompletedbtn", true);
    } else {
      localStorage.setItem("showcompletedbtn", false);
    }
    setDropDownValues(value);
  };

  const sortedDropdownTickets = [...dropdownValue]
    .filter((ticket) => {
      return ticket.toAssign === username || ticket.status === "Assigned";
    })
    .sort((a, b) => {
      const priorityA = a.priority !== null ? a.priority : Infinity;
      const priorityB = b.priority !== null ? b.priority : Infinity;

      return priorityA - priorityB;
    });

  const datatable = {
    columns: [
      {
        label: <span className="table-header">ID</span>,
        field: "id",
      },
      {
        label: <span className="table-header">Subject</span>,
        field: "subject",
      },
      {
        label: <span className="table-header">Ticket ID</span>,
        field: "ticket_id",
      },
      {
        label: <span className="table-header">Client</span>,
        field: "client",
      },
      {
        label: <span className="table-header">Phase</span>,
        field: "phase",
      },
      {
        label: <span className="table-header">Deadline</span>,
        field: "dead_line",
      },
      {
        label: <span className="table-header">Status</span>,
        field: "status",
      },
    ],
    rows: sortedDropdownTickets.map((ticket, index) => {
      console.log(ticket);
      return {
        ...ticket,
        id: index + 1,
        clickEvent: () => handleView(ticket.ticket_id),
      };
    }),
  };

  const handleShow = () => {
    navigate("/ticketReports");
  };

  const handleCardClick = (data) => {
    setTickets(data);
  };

  const screens = (sessionStorage.getItem("screens") || "").split(",");

  return (
    <div className="page-wrapper">
      <div className="page-header d-print-none">
        <div className="containers-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <div className="page-pretitle">Overview</div>

              <h2 className="page-title">Dashboard</h2>
            </div>
            <div className="col-auto ms-auto d-print-none">
              <div className="btn-list">
                {screens !== undefined && screens.includes("Ticket Create") && (
                  <button
                    className="btn btn-primary d-none d-sm-inline-block "
                    onClick={handleShow}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M12 5l0 14"></path>
                      <path d="M5 12l14 0"></path>
                    </svg>
                    New Ticket
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="page-body">
            <DashboardCardTable
              tickets={tickets}
              setSubTicketList={setSubTicketList}
              setCurrentDateTime={setCurrentDateTime}
              setViewTicket={setViewTicket}
              setTickets={setTickets}
              assignTicketView={assignTicketView}
              setParentNo={setParentNo}
              viewTicket={viewTicket}
              setAssign={setAssign}
            />

            {roles !== undefined && roles.includes("Admin") && (
              <div id="chart-completion-tasks-8">
                <div className="tasks col-sm-10 col-md-11">
                  <ApexCharts
                    options={chartOptions}
                    series={chartOptions.series}
                    type="bar"
                    height={chartOptions.chart.height}
                  />
                </div>
              </div>
            )}
            <DashboardDropdown setViewTicket={setViewTicket} />
          </div>
        </div>
      </div>
    </div>
  );
}
