import React from "react";
import "./TicketStoriesTable.css";

const TicketStoriesTable = ({ stories }) => {
  return (
    <div className="ticket-stories-table-container">
      <table className="ticket-stories-table">
        <thead>
          <tr>
            <th>#</th> {/* Serial Number Column */}
            <th>Date</th>
            <th>Creator</th>
            <th>Product</th>
            <th>Subject</th>
            <th>Duration</th>
            <th>Comment</th>
          </tr>
        </thead>
      </table>

      {/* Scrollable Table Body */}
      <div className="table-body-container">
        <table className="ticket-stories-table">
          <tbody>
            {stories.map((story, index) => (
              <tr key={index}>
                <td>{index + 1}</td> {/* Serial Number */}
                <td>{story.date}</td>
                <td>{story.creator}</td>
                <td>{story.product}</td>
                <td>{story.subject}</td>
                <td>{story.duration}</td>
                <td>{story.comment}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TicketStoriesTable;
