import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../ApiUrl";

// StarRating Component
const StarRating = ({ rating, onRate }) => {
  const stars = [1, 2, 3, 4, 5];

  return (
    <div>
      {stars.map((star) => (
        <span
          key={star}
          onClick={() => onRate(star)}
          style={{
            cursor: "pointer",
            color: rating >= star ? "#FFD700" : "#ccc",
            fontSize: "24px",
          }}
        >
          ★
        </span>
      ))}
    </div>
  );
};

export const TestingNumberofBuges = ({
  show,
  onClose,
  ticketId,
  onConfirm,
  product,
  subject,
}) => {
  const [numberOfBuges, setNumberOfBuges] = useState("");
  const [rating, setRating] = useState(0);

  const token = localStorage.getItem("token");
  const username = sessionStorage.getItem("username");
  const handleSubmit = async () => {
    const data = {
      numberOfBuges,
      rating,
      ticketId,
      product,
      subject,
      created_by: username,
    };

    try {
      await axios.post(`${API_URL}/createnumberofbuges`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Number of Buges Rating Added Successfully");
      if (onConfirm) onConfirm(ticketId);
      onClose();
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Failed to save data");
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Number of Buges and Rating</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label className="form-label">Number of Buges</label>
          <input
            type="number"
            className="form-control"
            value={numberOfBuges}
            onChange={(e) => setNumberOfBuges(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Rating</label>
          <StarRating rating={rating} onRate={setRating} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
