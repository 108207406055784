import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const CommentByEmployeesChart = ({ data }) => {
  // Convert duration string (HH:MM:SS) to minutes
  const convertDurationToMinutes = (duration) => {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return hours * 60 + minutes + seconds / 60;
  };

  // Aggregate data by creator
  const aggregateDataByCreator = (data) => {
    const result = {};
    let totalDuration = 0;

    data.forEach((item) => {
      const { created_by: creator, duration } = item;
      const durationInMinutes = convertDurationToMinutes(duration);
      totalDuration += durationInMinutes;

      if (!result[creator]) {
        result[creator] = 0;
      }
      result[creator] += durationInMinutes;
    });

    return { aggregatedDurations: result, totalDuration };
  };

  // Format duration as hours and minutes
  const formatDuration = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);
    return `${hours}h ${minutes}m`;
  };

  // Aggregate and format data
  const { aggregatedDurations, totalDuration } = aggregateDataByCreator(data);
  const labels = Object.keys(aggregatedDurations);
  const counts = Object.values(aggregatedDurations);
  const totalDurationFormatted = formatDuration(totalDuration);

  // Chart data and options
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Total Comment Duration Per Employee",
        data: counts,
        fill: false,
        borderColor: "rgba(75,192,192,1)",
        backgroundColor: "rgba(75,192,192,0.2)",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const durationInMinutes = context.raw;
            return `Duration: ${formatDuration(durationInMinutes)}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Employee",
        },
      },
      y: {
        title: {
          display: true,
          text: "Duration",
        },
        ticks: {
          callback: (value) => formatDuration(value),
        },
      },
    },
  };

  return (
    <div>
      {/* Chart Display */}
      <div
        className="duration-info"
        style={{ marginBottom: "20px", marginLeft: "5%" }}
      >
        <h3>Total Duration: {totalDurationFormatted}</h3>
      </div>
      <div
        style={{
          position: "relative",
          height: "60vh",
          width: "76vw",
        }}
      >
        <Line data={chartData} options={options} />
      </div>

      <div style={{ marginTop: "20px", width: "70vw" }}>
        <h4>Employee Comment Duration Analysis</h4>

        <table className="table table-bordered" style={{ marginLeft: "7%" }}>
          <thead>
            <tr>
              <th>Employee</th>
              <th>Total Duration</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(aggregatedDurations).map(([employee, duration]) => (
              <tr key={employee}>
                <td>{employee}</td>
                <td>{formatDuration(duration)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CommentByEmployeesChart;
