/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import Autosuggest from "react-autosuggest";
import { API_URL } from "../ApiUrl";
import "./StatusReportsSearchBar.css";
import CommentByEmployeesChart from "../Reports/CommentByEmployeesChart";
import TicketStoriesTable from "../Reports/TicketStoriesTable";

// Debounce function to limit API calls when typing
const debounce = (func, delay) => {
  let debounceTimer;
  return function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), delay);
  };
};

const StatusReportsSearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [parentTicket, setParentTicket] = useState(null);
  const [comments, setComments] = useState([]);
  const [subTicketComments, setSubTicketComments] = useState([]);
  const [accumulatedComments, setAccumulatedComments] = useState([]); // State for accumulated comments
  const [formattedStories, setFormattedStories] = useState([]);

  const accessToken = localStorage.getItem("token");

  const onSuggestionsFetchRequested = useCallback(
    debounce(async ({ value }) => {
      if (!value) return;

      setIsLoading(true);
      try {
        const response = await axios.get(
          `${API_URL}/status-search-suggestions`,
          {
            params: { query: value },
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        setSuggestions(response.data.suggestions);
        setErrorMessage("");
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setErrorMessage("Failed to fetch suggestions. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }, 300),
    [accessToken]
  );

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => suggestion.ticket_id;

  const renderSuggestion = (suggestion) => (
    <div style={styles.suggestionItem}>
      {suggestion.ticket_id} - {suggestion.client} - {suggestion.product} -{" "}
      {suggestion.phase} - {suggestion.subject}
    </div>
  );

  const fetchFilteredSubTickets = async (
    parentTicketId,
    accumulatedComments = []
  ) => {
    try {
      const response = await axios.get(
        `${API_URL}/filteredSubTickets/${parentTicketId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const subTickets = response.data;

      await Promise.all(
        subTickets.map(async (subTicket) => {
          await fetchFilteredSubTickets(
            subTicket.ticket_id,
            accumulatedComments
          );

          const commentsResponse = await axios.get(
            `${API_URL}/commentbyid/${subTicket.ticket_id}`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );

          const comments = commentsResponse.data;
          if (comments.length > 0) {
            comments.sort(
              (a, b) =>
                new Date(b.created_date_time) - new Date(a.created_date_time)
            );

            accumulatedComments.push({
              ticket_id: subTicket.ticket_id,
              comments,
            });
          }
        })
      );

      return accumulatedComments;
    } catch (error) {
      console.error(`Error fetching sub-tickets: ${error.message}`);
      throw error;
    }
  };

  const handleFetch = async (parentTicketId) => {
    try {
      const subTicketComments = await fetchFilteredSubTickets(parentTicketId);
      setSubTicketComments(subTicketComments); // Store in state if needed
    } catch (error) {
      console.error("Error in handleFetch:", error);
    }
  };

  const logTicketComments = async (productName) => {
    const comments = [];

    if (!productName) {
      return comments;
    }

    try {
      const commentResponse = await axios.get(
        `${API_URL}/commentbyname/${productName}`, // Updated API endpoint
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (commentResponse.status === 200) {
        const ticketComments = commentResponse.data;

        if (Array.isArray(ticketComments) && ticketComments.length > 0) {
          comments.push(...ticketComments);
        }
      } else {
        console.error(`Unexpected response status: ${commentResponse.status}`);
      }
    } catch (error) {
      console.error(
        `Error fetching comments for Product Name ${productName}:`,
        error
      );
      if (error.response) {
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      } else if (error.request) {
        console.error("Error request data:", error.request);
      } else {
        console.error("General error:", error.message);
      }
    }
    return comments;
  };

  const handleSearch = async (ticket_id) => {
    try {
      const response = await axios.get(`${API_URL}/tickets/${ticket_id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      const parentTicket = response.data;

      if (parentTicket.phase === "Entrée") {
        setParentTicket(parentTicket);
      }

      const productName = parentTicket.product;

      if (parentTicket.ticket_id) {
        await fetchFilteredSubTickets(parentTicket.ticket_id);
      }

      const response1 = await axios.get(
        `${API_URL}/getProductByTicket/${productName}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!Array.isArray(response1.data)) {
        throw new Error("Unexpected response format from getProductByTicket");
      }

      const comments1 = await logTicketComments(productName); // Pass productName to get comments
      setAccumulatedComments(comments1);
    } catch (error) {
      console.error("Error fetching ticket details or comments:", error);
    }
  };

  const onSuggestionSelected = async (event, { suggestion }) => {
    try {
      await handleSearch(suggestion.ticket_id);
      await handleFetch(suggestion.ticket_id);
    } catch (error) {
      console.error("Error handling suggestion selection:", error);
    }
  };

  const inputProps = {
    placeholder: "Search by ticket_id, client, product or subject...",
    value: searchTerm,
    onChange: (e, { newValue }) => setSearchTerm(newValue),
    style: styles.searchBox,
    "aria-label": "Search tickets",
    "aria-autocomplete": "list",
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth is 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatCommentsAsStories = (parentComments, subTicketComments) => {
    const formattedComments = [];

    if (parentComments.length > 0) {
      parentComments.forEach((comment) => {
        formattedComments.push({
          date: formatDate(comment.created_date_time),
          creator: comment.created_by,
          product: comment.product || "N/A",
          subject: comment.subject || "N/A",
          duration: comment.duration || "N/A",
          comment: comment.comments || "N/A",
        });
      });
    }

    subTicketComments.forEach((subTicket) => {
      subTicket.comments.forEach((comment) => {
        formattedComments.push({
          date: formatDate(comment.created_date_time),
          creator: comment.created_by,
          product: comment.product || "N/A",
          subject: comment.subject || "N/A",
          duration: comment.duration || "N/A",
          comment: comment.comments || "N/A",
        });
      });
    });

    console.log(formattedComments);

    formattedComments.sort((a, b) => new Date(b.date) - new Date(a.date));

    return formattedComments;
  };

  useEffect(() => {
    const formatted = formatCommentsAsStories(comments, subTicketComments);
    console.log(formatted);

    setFormattedStories(formatted);
  }, [comments, subTicketComments]);

  const renderTicketStories = () => {
    return (
      <div className="ticket-stories-container">
        <h3>Comments and Ticket Progress Updates</h3>

        {formattedStories.length ? (
          <TicketStoriesTable stories={formattedStories} />
        ) : (
          <p>No ticket stories available.</p>
        )}
      </div>
    );
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.content}>
        <div style={styles.header}>Status Reports</div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleSearch(searchTerm);
          }}
          style={styles.searchForm}
        >
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            theme={{
              container: "autosuggest-container",
              suggestionsContainer: "statussuggestions-container",
              suggestionsList: "suggestions-list",
              suggestion: "suggestion-item",
            }}
            onSuggestionSelected={onSuggestionSelected}
          />
        </form>

        {parentTicket && (
          <div style={styles.parentTicketContainer}>
            <h3>Details of Parent Ticket</h3>

            <table style={styles.ticketTable}>
              <tbody>
                <tr>
                  <th>Ticket ID</th>
                  <td>{parentTicket.ticket_id}</td>
                </tr>
                <tr>
                  <th>Created By</th>
                  <td>{parentTicket.created_by}</td>
                </tr>
                <tr>
                  <th>Created On</th>
                  <td>{formatDate(parentTicket.createdAt)}</td>
                </tr>
                <tr>
                  <th>Product Name</th>
                  <td>{parentTicket.product}</td>
                </tr>
                <tr>
                  <th>Phase</th>
                  <td>{parentTicket.phase}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>{parentTicket.status}</td>
                </tr>
                <tr>
                  <th>Assigned To</th>
                  <td>{parentTicket.assign}</td>
                </tr>
                <tr>
                  <th>Client</th>
                  <td>{parentTicket.client}</td>
                </tr>
                <tr>
                  <th>Team</th>
                  <td>{parentTicket.teams}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {isLoading && <p>Loading...</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div className="report-container">{renderTicketStories()}</div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {/* <div className="report-container">{parentTicket && renderReport()}</div> */}
        {accumulatedComments.length > 0 && (
          <div className="mt-3">
            <h3>Product: Total Time Spent - {parentTicket.product}</h3>

            <CommentByEmployeesChart data={accumulatedComments} />
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  pageContainer: {
    display: "flex",
    flexDirection: "column", // Make sure the content stacks vertically
    alignItems: "center",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    height: "100vh", // Full viewport height
    overflowY: "auto", // Scroll if content overflows
  },
  content: {
    width: "100%",
    maxWidth: "1200px", // Adjust max width for larger screens
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    boxSizing: "border-box",
  },
  header: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  commentChartContainer: {
    width: "100%",
    maxWidth: "1200px", // Adjust max width for larger screens
    margin: "0 auto", // Center the chart
    padding: "20px",
  },
  searchForm: {
    marginBottom: "20px",
  },
  searchBox: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  suggestionItem: {
    padding: "10px",
    borderBottom: "1px solid #ddd",
    whiteSpace: "normal", // Allow text to wrap
  },

  loadingMessage: {
    color: "#007bff",
  },
  errorMessage: {
    color: "red",
  },
  parentTicketContainer: {
    marginBottom: "20px",
  },
  ticketTable: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
  },
  commentsContainer: {
    marginTop: "20px",
  },
  commentTable: {
    width: "100%",
    borderCollapse: "collapse",
    wordBreak: "break-word", // Break long words
    tableLayout: "fixed", // Fix table layout to avoid overflow
  },
  subTicketCommentsContainer: {
    marginTop: "20px",
  },
  subTicketContainer: {
    marginBottom: "20px",
  },
  ticketStoryCard: {
    border: "1px solid #ddd",
    borderRadius: "5px",
    padding: "15px",
    marginBottom: "15px",
    backgroundColor: "#f9f9f9",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  ticketStoryHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  ticketStoryDate: {
    fontWeight: "bold",
    color: "#333",
  },
  ticketStoryCreator: {
    color: "#666",
  },
  ticketStoryBody: {
    marginBottom: "10px",
  },
  ticketStoryFooter: {
    borderTop: "1px solid #ddd",
    paddingTop: "10px",
  },
  subticketOverview: {
    borderBottom: "1px solid #ddd",
    padding: "10px 0",
  },
};

export default StatusReportsSearchBar;
