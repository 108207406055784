// Import necessary libraries and components
import React, { useState, useEffect } from "react";
import axios from "axios";
import { format } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../ApiUrl";

const Excel = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    fetchUploadedFiles();
  }, [currentPage, itemsPerPage]);

  const fetchUploadedFiles = async () => {
    try {
      const response = await axios.get(`${API_URL}/uploadedfiles`);
      const sortedFiles = response.data.sort((a, b) => {
        return new Date(b.upload_date_time) - new Date(a.upload_date_time);
      });
      setUploadedFiles(sortedFiles);
    } catch (error) {
      console.error("Error fetching uploaded files:", error);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError(null);
  };

  const handleUpload = async () => {
    if (!file) {
      toast.error("Please select a file");
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      const username = localStorage.getItem("username");
      formData.append("file", file);
      formData.append("created_by", username);
      await uploadFile();
      // await importExcel();
      const response = await axios.post(`${API_URL}/importexcel`, formData, {
        responseType: "blob",
      });

      const contentType = response.headers["content-type"];
      if (
        contentType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        contentType === "application/vnd.ms-excel"
      ) {
        const blob = new Blob([response.data], { type: contentType });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "unimported_tickets.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        toast.warning(
          "Some tickets could not be imported. Downloading Excel file."
        );
      } else {
        toast.success("File uploaded and imported successfully");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file");
    } finally {
      setLoading(false);
    }
  };

  const uploadFile = async () => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      await axios.post(`${API_URL}/uploadedfile`, formData);
      setFile(null);
    } catch (error) {
      console.error("Error uploading file:", error);
      setError(error.response?.data?.error || "Error uploading file");
    }
  };

  // const importExcel = async () => {
  //   if (!file) {
  //     console.error("Please select a file");
  //     return;
  //   }

  //   try {
  //     const formData = new FormData();
  //     formData.append("file", file);

  //     await axios.post(`${API_URL}/importexcel`, formData, {
  //       headers: { "Content-Type": "multipart/form-data" },
  //     });

  //     toast.success("File uploaded successfully");
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //     if (error.response?.data?.invalidData) {
  //       const { barcode, error: errorMessage } =
  //         error.response.data.invalidData;
  //       toast.error(`Invalid data for barcode ${barcode}: ${errorMessage}`);
  //     } else {
  //       toast.error(`Error uploading file: ${error.message}`);
  //     }
  //   }
  // };

  const handleDownloadFormat = async () => {
    try {
      const headers = [
        "ticket_type",
        "subject",
        "task",
        // "toAssign",
        // "assign",
        "dead_line",
        "noofdaysexpected",
        "cycle",
        "phase",
        "client",
        "product",
        // "completed_date_time",
        // "initial_date_time",
        // "priority",
        "source",
        "teams",
        // "mark",
        "status",
        // "comment",
        // "created_by",
      ];

      const dummyData = [
        [
          "P",
          "sub",
          "task",
          // "arun",
          // "arun",
          "31-05-2024",
          "1d3h0m",
          "R-D-TCC-CR-T",
          "Coding",
          "KEC",
          "KEC",
          // "high",
          "bug fix",
          "developement",
          "Pending",
        ],
        [
          "S",
          "sub",
          "task",
          // "arun",
          // "arun",
          "31-05-2024",
          "1d3h0m",
          "R-D-TCC-CR-T",
          "Coding",
          "KEC",
          "KEC",
          // "high",
          "bug fix",
          "developement",
          "Pending",
        ],
      ];

      const data = [headers, ...dummyData];

      const blob = new Blob([data.map((row) => row.join(",")).join("\n")], {
        type: "text/csv",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "upload_format.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading format:", error);
    }
  };

  const handleDownload = async (fileId, fileName) => {
    try {
      const response = await axios.get(`${API_URL}/download/${fileId}`, {
        responseType: "blob",
      });

      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const renderTableRows = () => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = uploadedFiles.slice(indexOfFirstItem, indexOfLastItem);

    return currentItems.map((file, index) => (
      <tr key={file.id}>
        <td>{indexOfFirstItem + index + 1}</td>
        <td>{file.file_name}</td>
        <td>{format(new Date(file.upload_date_time), "dd/MM/yyyy")}</td>
        <td>
          <button
            className="btn btn-secondary"
            onClick={() => handleDownload(file.id, file.file_path)}
          >
            Download
          </button>
        </td>
      </tr>
    ));
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="card shadow">
            <div className="card-header bg-primary text-white">
              <h2>Excel File Upload</h2>
            </div>

            <div className="card-body">
              <div className="form-group">
                <label htmlFor="file" className="font-weight-bold">
                  Select File:
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="file"
                  onChange={handleFileChange}
                />
              </div>

              <button
                className="btn btn-success mt-3"
                onClick={handleUpload}
                disabled={loading}
              >
                {loading ? "Uploading..." : "Upload File"}
              </button>

              <button
                className="btn btn-primary ml-2 mt-3"
                onClick={handleDownloadFormat}
              >
                Download Format
              </button>

              <div className="mt-4">
                <p className="font-weight-bold">
                  Showing{" "}
                  {Math.min(currentPage * itemsPerPage, uploadedFiles.length)}{" "}
                  of {uploadedFiles.length} files.
                </p>

                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="thead-light">
                      <tr>
                        <th>Serial No</th>
                        <th>Uploaded File</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{renderTableRows()}</tbody>
                  </table>
                </div>

                <div className="d-flex justify-content-between mt-2">
                  <div className="form-group col-5">
                    <label htmlFor="rowsPerPage">Rows per Page:</label>
                    <select
                      id="rowsPerPage"
                      className="form-control form-control-sm"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div>
                    <button
                      className="btn btn-secondary btn-sm"
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Prev
                    </button>
                    <button
                      className="btn btn-secondary btn-sm ml-2"
                      onClick={handleNextPage}
                      disabled={
                        currentPage ===
                        Math.ceil(uploadedFiles.length / itemsPerPage)
                      }
                    >
                      Next
                    </button>
                  </div>
                </div>

                {error && (
                  <p className="alert alert-danger mt-3 text-center">{error}</p>
                )}
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Excel;
