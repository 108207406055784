/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./WeeklyEmployeeReviewForm.css"; // Import the CSS file
import { API_URL } from "../ApiUrl";
import { getUsers } from "../masters/Lists/ListShow";

export const WeeklyEmployeeReviewForm = () => {
  // State hooks for form data
  const [employeeInfo, setEmployeeInfo] = useState({
    name: "",
    weekEnding: "",
    reviewer: "",
  });

  const [accomplishments, setAccomplishments] = useState("");
  const [performance, setPerformance] = useState({
    qualityOfWork: { rating: "", comments: "" },
    productivity: { rating: "", comments: "" },
    jobKnowledge: { rating: "", comments: "" },
    communication: { rating: "", comments: "" },
    teamwork: { rating: "", comments: "" },
    initiative: { rating: "", comments: "" },
    attendance: { rating: "", comments: "" },
    punctualityToMeeting: { rating: "", comments: "" },
    worksheetCompletion: { rating: "", comments: "" },
    permissionOrLeave: { rating: "", comments: "" },
  });

  const [goals, setGoals] = useState({
    previousGoals: [{ goal: "", achieved: "", comments: "" }],
    newGoals: [{ goal: "", achieved: "", comments: "" }],
  });

  const [challenges, setChallenges] = useState({
    challengesFaced: "",
    supportNeeded: "",
  });

  const [assign, setAssign] = useState({
    assignValue: "",
    assignDropDownValues: [],
  });

  useEffect(() => {
    const fetchAssignData = async () => {
      try {
        const assignData = await getUsers(); // Replace with your API call
        setAssign({
          assignValue: "",
          assignDropDownValues: assignData,
        });
      } catch (error) {
        console.error("Error fetching assign data:", error);
      }
    };

    fetchAssignData();
  }, []);

  // Handler for form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Flattening the performance object to ensure all data is sent
    const flattenedPerformance = {};
    Object.keys(performance).forEach((key) => {
      flattenedPerformance[key] = performance[key]?.rating || null;
      flattenedPerformance[`${key}Comments`] =
        performance[key]?.comments || null;
    });

    // Form data to be sent to the backend
    const formData = {
      ...employeeInfo,
      accomplishments,
      challengesFaced: challenges.challengesFaced,
      supportNeeded: challenges.supportNeeded,
      ...flattenedPerformance, // Include flattened performance data
      previousGoals: goals.previousGoals,
      newGoals: goals.newGoals,
    };

    try {
      const response = await axios.post(`${API_URL}/review-form`, formData);
      console.log("Form submitted successfully:", response.data);

      // Reset form fields upon successful submission
      setEmployeeInfo({
        name: "",
        weekEnding: "",
        reviewer: "",
      });

      setAccomplishments("");

      setPerformance({
        qualityOfWork: { rating: "", comments: "" },
        productivity: { rating: "", comments: "" },
        jobKnowledge: { rating: "", comments: "" },
        communication: { rating: "", comments: "" },
        teamwork: { rating: "", comments: "" },
        initiative: { rating: "", comments: "" },
        attendance: { rating: "", comments: "" },
        punctualityToMeeting: { rating: "", comments: "" },
        worksheetCompletion: { rating: "", comments: "" },
        permissionOrLeave: { rating: "", comments: "" },
      });

      setGoals({
        previousGoals: [{ goal: "", achieved: "", comments: "" }],
        newGoals: [{ goal: "", achieved: "", comments: "" }],
      });

      setChallenges({
        challengesFaced: "",
        supportNeeded: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const { assignValue, assignDropDownValues } = assign || {};

  return (
    <div className="review-form-container">
      <h2>Weekly Employee Review Form</h2>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <legend>Employee Information:</legend>
          <div className="form-group">
            <label>Name:</label>
            <select
              className="form-select"
              aria-label="Select employee name"
              value={employeeInfo.name}
              onChange={(e) =>
                setEmployeeInfo({ ...employeeInfo, name: e.target.value })
              }
            >
              <option>Please choose one option</option>
              {assignDropDownValues?.map((option, index) => (
                <option key={index} value={option.firstname}>
                  {option.firstname}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Week Ending:</label>
            <input
              type="date"
              value={employeeInfo.weekEnding}
              onChange={(e) =>
                setEmployeeInfo({
                  ...employeeInfo,
                  weekEnding: e.target.value,
                })
              }
            />
          </div>

          <div className="form-group">
            <label>Reviewer:</label>
            <select
              className="form-select"
              aria-label="Select reviewer"
              value={employeeInfo.reviewer}
              onChange={(e) =>
                setEmployeeInfo({ ...employeeInfo, reviewer: e.target.value })
              }
            >
              <option>Please choose one option</option>
              {assignDropDownValues?.map((option, index) => (
                <option key={index} value={option.firstname}>
                  {option.firstname}
                </option>
              ))}
            </select>
          </div>
        </fieldset>

        {/* Weekly Accomplishments */}
        <fieldset>
          <legend>Section 1: Weekly Accomplishments</legend>
          <textarea
            value={accomplishments}
            onChange={(e) => setAccomplishments(e.target.value)}
            placeholder="Describe the weekly accomplishments here..."
          />
        </fieldset>

        <fieldset>
          <legend>Section 2: Key Responsibilities and Performance</legend>
          <table>
            <thead>
              <tr>
                <th>Criteria</th>
                <th>Rating (1-5)</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(performance).map((criteria) => (
                <tr key={criteria}>
                  <td>{criteria.replace(/([A-Z])/g, " $1").toUpperCase()}</td>
                  <td>
                    <input
                      type="number"
                      min="1"
                      max="5"
                      value={performance[criteria]?.rating || ""}
                      onChange={(e) =>
                        setPerformance({
                          ...performance,
                          [criteria]: {
                            ...performance[criteria],
                            rating: e.target.value,
                          },
                        })
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={performance[criteria]?.comments || ""}
                      onChange={(e) =>
                        setPerformance({
                          ...performance,
                          [criteria]: {
                            ...performance[criteria],
                            comments: e.target.value,
                          },
                        })
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </fieldset>

        {/* Goals and Objectives */}
        <fieldset>
          <legend>Section 3: Goals and Objectives for the Week</legend>
          <div>
            <h3>Previous Week’s Goals:</h3>
            {goals.previousGoals.map((goal, index) => (
              <div key={index} className="goal-item">
                <label>Goal:</label> &nbsp;
                <input
                  type="text"
                  value={goal.goal}
                  onChange={(e) => {
                    const newGoals = [...goals.previousGoals];
                    newGoals[index].goal = e.target.value;
                    setGoals({ ...goals, previousGoals: newGoals });
                  }}
                />
                <label>Achieved (Yes/No):</label> &nbsp;
                <input
                  type="text"
                  value={goal.achieved}
                  onChange={(e) => {
                    const newGoals = [...goals.previousGoals];
                    newGoals[index].achieved = e.target.value;
                    setGoals({ ...goals, previousGoals: newGoals });
                  }}
                />
                <label>Comments:</label> &nbsp;
                <input
                  type="text"
                  value={goal.comments}
                  onChange={(e) => {
                    const newGoals = [...goals.previousGoals];
                    newGoals[index].comments = e.target.value;
                    setGoals({ ...goals, previousGoals: newGoals });
                  }}
                />
              </div>
            ))}
          </div>
          <div>
            <h3>New Goals for the Coming Week:</h3>
            {goals.newGoals.map((goal, index) => (
              <div key={index} className="goal-item">
                &nbsp; <label>Goal:</label> &nbsp;
                <input
                  type="text"
                  value={goal.goal}
                  onChange={(e) => {
                    const newGoals = [...goals.newGoals];
                    newGoals[index].goal = e.target.value;
                    setGoals({ ...goals, newGoals: newGoals });
                  }}
                />
                &nbsp;
                <label>Comments:</label> &nbsp;
                <input
                  type="text"
                  value={goal.comments}
                  onChange={(e) => {
                    const newGoals = [...goals.newGoals];
                    newGoals[index].comments = e.target.value;
                    setGoals({ ...goals, newGoals: newGoals });
                  }}
                />
              </div>
            ))}
          </div>
        </fieldset>

        {/* Challenges and Support Needed */}
        <fieldset>
          <legend>Section 4: Challenges and Support Needed</legend>
          <textarea
            value={challenges.challengesFaced}
            onChange={(e) =>
              setChallenges({ ...challenges, challengesFaced: e.target.value })
            }
            placeholder="Describe any challenges faced during the week..."
          />
          <textarea
            value={challenges.supportNeeded}
            onChange={(e) =>
              setChallenges({ ...challenges, supportNeeded: e.target.value })
            }
            placeholder="Describe any support needed from the team or management..."
          />
        </fieldset>

        <button type="submit">Submit Review</button>
      </form>
    </div>
  );
};
