/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../ApiUrl";
import { getUsers } from "../Lists/ListShow";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getRole } from "../../Role/List";
import Select from "react-dropdown-select";

export default function Users() {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    toast.dismiss();
    setShow(false);
    resetForm();
  };

  const handleShow = () => {
    getAutoID();
    setShow(true);
    setPasswordplaceholder("Password");
    setPrimaryid("");
  };

  const [headtitle, setHeadtitle] = useState("Add New User");
  const [btntxt, setButtontxt] = useState("Create New User");
  const [autoid, setAutoid] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [gender, setGender] = useState("Male");
  const [dob, setDOB] = useState(null);
  const [primaryid, setPrimaryid] = useState("");
  const [designation, setDesignation] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [altcontact, setAltContact] = useState("");
  const [users, setUsers] = useState([]);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState({ roleValue: "", roleDropDownValues: [] });
  const [passwordplaceholder, setPasswordplaceholder] = useState("Password");
  const [selectedRoles, setSelectedRoles] = useState([]);
  // const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const axiosJWT = axios.create();
  const { roleValue } = role;

  useEffect(() => {
    const fetchUsers = async () => {
      const data = await getUsers();
      setUsers(data);
      const role = await getRole();
      setRole({
        ...role,
        roleDropDownValues: role,
      });
    };
    fetchUsers();
    getAutoID();
  }, []);

  const getAutoID = async () => {
    try {
      const response = await axiosJWT.get(`${API_URL}/getautoid/user`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });

      setAutoid(response.data.user_id);
    } catch (error) {
      if (error) {
        console.log(error.message);
      }
    }
  };
  const accessToken1 = localStorage.getItem("token");
  const username = sessionStorage.getItem("username");
  const localStorageRole = sessionStorage.getItem("role");
  const tokenPayload = jwt_decode(accessToken1);

  const isTokenExpired = tokenPayload.exp < Date.now() / 1000;

  if (isTokenExpired) {
    localStorage.removeItem("token");
    localStorage.setItem("isLoggedin", false);
    navigate("/");
  }

  const handleEdit = async (user_id) => {
    setShow(true);
    setAutoid(user_id);
    setHeadtitle("Edit User");
    setButtontxt("Update User");
    // setShowPassword(true);
    const response = await axiosJWT.get(`${API_URL}/users/${user_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken1}`,
      },
    });

    setAutoid(response.data.user_id);
    setFirstName(response.data.firstname);
    setLastName(response.data.lastname);
    setGender(response.data.gender);
    setEmail(response.data.email);
    setPhone(response.data.phone);
    setDesignation(response.data.designation);
    setAddress(response.data.address);
    setState(response.data.state);
    setCity(response.data.city);
    setZipcode(response.data.zipcode);
    setPrimaryid(response.data.id);
    setAltContact(response.data.alt_contact);

    const userRoles = response.data.role;
    const selectedRoles = role.roleDropDownValues.filter((role) =>
      userRoles.includes(role.name)
    );
    setSelectedRoles(selectedRoles);
    const dob = new Date(response.data.dob);
    const formattedDate = dob.toISOString();
    setDOB(formattedDate);
    setPasswordplaceholder("Skip, If you dont want to update Password");
  };

  const handleKeyDown = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    // const regex = /^[0-9]*$/;

    const allowedKeys = [8, 9, 37, 39];
    if (!/^\d+$/.test(keyValue) && !allowedKeys.includes(event.keyCode)) {
      event.preventDefault();
    }

    if (
      event.target.value.length >= 10 &&
      !allowedKeys.includes(event.keyCode)
    ) {
      event.preventDefault();
    }
  };
  const handleChange = (event) => {
    setGender(event.target.value);
  };
  const handleChangedob = (date) => {
    setDOB(date);
  };
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      setFile(base64String);
    };
    if (selectedFile) {
      const fileSize = selectedFile.size / 1024;
      if (fileSize > 100) {
        toast.error("Image size exceeds the limit of 100 KB");
        event.target.value = null;
        return;
      }
    }

    reader.readAsDataURL(selectedFile);
    setPreview(URL.createObjectURL(selectedFile));
  };

  const handleZipcode = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    // const regex = /^[0-9]*$/;
    if (!/^\d+$/.test(keyValue) && event.keyCode !== 8 && event.keyCode !== 9) {
      event.preventDefault();
    }
    if (
      event.target.value.length >= 6 &&
      event.keyCode !== 8 &&
      event.keyCode !== 9
    ) {
      event.preventDefault();
    }
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setAutoid("");
    setDesignation("");
    setEmail("");
    setPhone("");
    setAddress("");
    setState("");
    setCity("");
    setZipcode("");
    setAltContact("");
    setPreview("");
    setPassword("");
    setHeadtitle("Add New User");
    setButtontxt("Create New User");
    setPrimaryid("");
    setGender("Male");
    setDOB(null);
    setSelectedRoles([]);
    // setShowPassword(false);
  };

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const saveUser = async (data) => {
    data.preventDefault();
    // setShowPassword(false);
    if (
      dob === "" ||
      firstname.trim() === "" ||
      roleValue === "" ||
      email.trim() === "" ||
      phone.toString().trim() === "" ||
      altcontact.toString().trim() === "" ||
      !designation ||
      designation.trim() === "" ||
      address.trim() === "" ||
      state.trim() === "" ||
      city.trim() === "" ||
      zipcode.toString().trim() === ""
    ) {
      toast.error("Please Enter All Fields...!", { autoClose: 1000 });
      return;
    }
    if (phone.toString().length != "10") {
      toast.error("Please Enter 10 Digit Phone Number...!", {
        autoClose: 1000,
      });
      return;
    }
    if (altcontact.toString().length != "10") {
      toast.error("Please Enter 10 Digit Alt Phone Number...!", {
        autoClose: 1000,
      });
      return;
    }
    if (zipcode.toString().length != "6") {
      toast.error("Please Enter 6 Digit Zipcode...!", { autoClose: 1000 });
      return;
    }
    if (!isValidEmail(email)) {
      toast.error("Please Enter valid Email...!", { autoClose: 1000 });
      return;
    }
    try {
      if (primaryid) {
        try {
          const response = await axiosJWT.post(
            `${API_URL}/updateuser/${primaryid}`,
            {
              firstname,
              lastname,
              email,
              phone,
              designation,
              altcontact,
              autoid,
              address,
              state,
              city,
              zipcode,
              password,
              dob,
              file,
              gender,
              roleData,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken1}`,
              },
            }
          );
          toast.success(response.data.msg, { autoClose: 1000 });
          setAutoid("");
          setFirstName("");
          setLastName("");
          setDesignation("");
          setEmail("");
          setPhone("");
          setAddress("");
          setState("");
          setCity("");
          setZipcode("");
          setAltContact("");
          setPassword("");

          const fetchUsers = async () => {
            const data = await getUsers();
            setUsers(data);
          };
          fetchUsers();
          setTimeout(() => {
            handleClose();
          }, 1000);
        } catch (error) {
          if (error.response) {
            toast.error(error.response.data.error, { autoClose: 2000 });
          } else {
            toast.error("Error occurred1");
          }
        }
      } else {
        if (password.trim() === "") {
          toast.error("Please Enter Password...!", { autoClose: 1000 });
          return;
        }
        try {
          const response = await axiosJWT.post(
            `${API_URL}/createuser`,
            {
              firstname,
              lastname,
              gender,
              dob,
              file,
              email,
              phone,
              designation,
              altcontact,
              autoid,
              address,
              state,
              city,
              zipcode,
              password,
              roleData,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken1}`,
              },
            }
          );
          toast.success(response.data.msg, { autoClose: 1000 });
          setAutoid("");
          setFirstName("");
          setLastName("");
          setDesignation("");
          setEmail("");
          setPhone("");
          setAddress("");
          setState("");
          setCity("");
          setZipcode("");
          setAltContact("");
          setPassword("");
          getAutoID();
          const fetchUsers = async () => {
            const data = await getUsers();
            setUsers(data);
          };
          fetchUsers();
          setTimeout(() => {
            handleClose();
          }, 1000);
        } catch (error) {
          if (error.response) {
            toast.error(error.response.data.error, { autoClose: 2000 });
          } else {
            toast.error("Error occurred");
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (user_id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this User?"
    );
    if (!confirmed) {
      return;
    }
    try {
      await axiosJWT.delete(`${API_URL}/users/${user_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      toast.success("User Deleted Successfully!", {
        autoClose: 2000,
      });
      setTimeout(() => {
        setUsers(users.filter((user) => user.user_id !== user_id));
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };
  const roleData = selectedRoles.map((role) => role.name).toString();

  const screens = sessionStorage.getItem("screens").split(",");

  const filteredUsers =
    localStorageRole !== "Admin"
      ? users.filter((user) => user.firstname === username)
      : users;

  return (
    <div className="page-wrapper">
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <div className="page-pretitle">Overview</div>
              <h2 className="page-title">Users</h2>
            </div>
            <div className="col-auto ms-auto d-print-none">
              <div className="btn-list">
                {localStorageRole === "Admin" &&
                  screens !== undefined &&
                  screens.includes("Users New") && (
                    <button
                      href="#"
                      className="btn btn-primary d-none d-sm-inline-block"
                      onClick={handleShow}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M12 5l0 14"></path>
                        <path d="M5 12l14 0"></path>
                      </svg>
                      New User
                    </button>
                  )}
              </div>
            </div>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              className="modal-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>{headtitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="col-12">
                  <form className="">
                    <div className="card-body">
                      <div className="row row-cards">
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label className="form-label">User Id</label>
                            <input
                              type="text"
                              className="form-control"
                              readOnly
                              defaultValue={autoid}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-5">
                          <div className="mb-3">
                            <label className="form-label">First Name *</label>
                            {localStorageRole !== "Admin" ? (
                              <input
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                value={firstname}
                                disabled
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                value={firstname}
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Last Name</label>
                            {localStorageRole !== "Admin" ? (
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                                value={lastname}
                                disabled
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                                value={lastname}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Gender *</label>
                            <select
                              id="gender"
                              value={gender}
                              onChange={handleChange}
                              className="form-select"
                            >
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">DOB *</label>
                            <DatePicker
                              id="dob"
                              selected={dob ? moment(dob).toDate() : null}
                              onChange={handleChangedob}
                              dateFormat="dd/MM/yyyy"
                              maxDate={new Date()}
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={100}
                              placeholderText="D/M/Y"
                              className="form-select"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Phone No *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Phone No"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Alt Contact *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Alt Contact"
                              value={altcontact}
                              onChange={(e) => setAltContact(e.target.value)}
                              onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">
                              Email Address *
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">UseRole *</label>
                            <div className="border rounded">
                              {localStorageRole !== "Admin" ? (
                                <Select
                                  name="select"
                                  options={role.roleDropDownValues}
                                  labelField="name"
                                  valueField="name"
                                  disabled
                                  values={selectedRoles}
                                />
                              ) : (
                                <Select
                                  name="select"
                                  options={role.roleDropDownValues}
                                  labelField="name"
                                  valueField="name"
                                  multi
                                  onChange={(values) =>
                                    setSelectedRoles(values)
                                  }
                                  values={selectedRoles}
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6 col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Address *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Address"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Designation *</label>
                            {localStorageRole !== "Admin" ? (
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Designation"
                                value={designation}
                                disabled
                                onChange={(e) => setDesignation(e.target.value)}
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Designation"
                                value={designation}
                                onChange={(e) => setDesignation(e.target.value)}
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">City *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="City"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">State *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="State"
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-sm-4 col-md-3">
                          <div className="mb-3">
                            <label className="form-label">Postal Code *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="ZIP Code"
                              value={zipcode}
                              onChange={(e) => setZipcode(e.target.value)}
                              onKeyDown={handleZipcode}
                            />
                          </div>
                        </div>
                        <div className="col-sm-4 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Password *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={passwordplaceholder}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                        </div>
                        {/* <div className="col-sm-6 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Password *</label>
                            {/* <input
                              type="password"
                              className="form-control"
                              value={password}
                              placeholder={passwordplaceholder}
                              onChange={(e) => setPassword(e.target.value)}
                            /> */}
                        {/* {!showPassword ? (
                          <input
                            type="password"
                            className="form-control"
                            value={password}
                            placeholder={passwordplaceholder}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        ) : (
                          <input
                            type="password"
                            className="form-control"
                            value=""
                            placeholder={passwordplaceholder}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        )}
                      </div>
                    </div> */}

                        <div className="col-sm-6 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Profile Image</label>
                            <input
                              type="file"
                              className="form-select"
                              id="image"
                              accept="image/*"
                              onChange={handleFileChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="mb-3">
                            {preview && (
                              <img
                                src={preview}
                                alt="Preview"
                                style={{ width: "50%", height: "auto" }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="modal-footer">
                  <button
                    className="btn btn-md btn-primary ms-auto"
                    onClick={saveUser}
                  >
                    {btntxt}
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
            <ToastContainer />
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Users List</h3>
                </div>
                <div className="container table-responsive mt-3 mb-3">
                  <div className="row row-cards">
                    {filteredUsers.map((user, index) => (
                      <div className="col-md-6 col-lg-3">
                        <div className="card">
                          <div className="card-body p-4 text-center">
                            <span
                              className="avatar avatar-xl mb-3 rounded"
                              style={{
                                backgroundImage: user.img
                                  ? `url(${API_URL}${user.img})`
                                  : `url("./default_avatar.png")`,
                              }}
                            ></span>
                            <h3 className="m-0 mb-1">
                              {user.firstname + "(" + user.user_id + ")"}
                            </h3>
                            <div className="text-muted">{user.designation}</div>
                            <div className="text-muted">{user.phone}</div>
                            <div className="text-muted">{user.email}</div>
                            <div className="mt-3"></div>
                          </div>
                          {screens !== undefined &&
                            screens.includes("Users Profile") && (
                              <button
                                style={{ display: "none" }}
                                className="card-btn"
                              >
                                View full profile
                              </button>
                            )}
                          <div className="d-flex">
                            <button
                              className="card-btn"
                              onClick={() => handleEdit(user.user_id)}
                            >
                              <i className="ti ti-file-pencil"></i>&nbsp; Edit
                            </button>
                            {screens !== undefined &&
                              screens.includes("Users Delete") && (
                                <button
                                  className="card-btn"
                                  onClick={() => handleDelete(user.user_id)}
                                >
                                  <i className="ti ti-trash"></i>&nbsp; Delete
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
