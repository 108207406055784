import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const FlowChart = ({ data }) => {
  const aggregateDataByMonth = (data) => {
    const result = {};
    const now = new Date();
    const oneYearAgo = new Date(now.getFullYear() - 1, now.getMonth() + 1, 0);

    data.forEach((item) => {
      const date = new Date(item.createdAt);
      if (date >= oneYearAgo && date <= now) {
        const month = date.toLocaleString("default", {
          month: "long",
          year: "numeric",
        });
        if (!result[month]) {
          result[month] = 0;
        }
        result[month] += 1;
      }
    });

    return result;
  };

  const monthlyData = aggregateDataByMonth(data);
  const labels = Object.keys(monthlyData);
  const counts = Object.values(monthlyData);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Product Created by Month",
        data: counts,
        fill: false,
        borderColor: "rgba(75,192,192,1)",
        backgroundColor: "rgba(75,192,192,0.2)",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Month",
        },
      },
      y: {
        title: {
          display: true,
          text: "Count",
        },
      },
    },
  };

  console.log("Chart Data:", chartData);

  return (
    <div
      style={{
        position: "relative",
        height: "60vh",
        width: "80vw",
        left: "7%",
      }}
    >
      <Line data={chartData} options={options} />
    </div>
  );
};

export default FlowChart;
