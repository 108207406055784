const config = {
  mark: [{ mark: true }, { mark: false }],
  screens: [
    { heading: "Reports", name: ["Reports Edit", "Reports Exports"] },
    {
      heading: "Masters",
      name: [
        "Masters Edit",
        "Masters Delete",
        "Masters Create",
        "Masters View",
      ],
    },
    {
      heading: "Users",
      name: [
        "Users Role",
        "Users New",
        "Users Profile",
        "Users Edit",
        "Users Delete",
      ],
    },
    { heading: "Mark Task", name: ["Mark Task"] },
    {
      heading: "Tickets",
      name: [
        "Ticket Edit",
        "Ticket Delete",
        "Ticket View",
        "Ticket Sub",
        "Ticket Create",
      ],
    },
  ],
};
export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = ("0" + (today.getMonth() + 1)).slice(-2);
  const day = ("0" + today.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

export default config;
