import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../ApiUrl";
import { getProduct, getUsers } from "../Lists/ListShow";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { MDBDataTableV5 } from "mdbreact";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";

export default function Product() {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    toast.dismiss();
    setShow(false);
    setProduct("");
    setHeadtitle("Add New Product");
    setButtontxt("Create New Product");
  };

  const handleShow = () => {
    setShow(true);
    setProduct(""); // Reset product input
    setOwner(""); // Reset owner dropdown
    setPrimaryid("");
  };

  const [headtitle, setHeadtitle] = useState("Add New Product");
  const [btntxt, setButtontxt] = useState("Create New Product");
  const [productlist, setProductList] = useState([]);
  const [primaryid, setPrimaryid] = useState("");
  const [product, setProduct] = useState("");
  const [users, setUsers] = useState([]);
  const [owner, setOwner] = useState("");

  const navigate = useNavigate();
  const axiosJWT = axios.create();

  useEffect(() => {
    const fetchProduct = async () => {
      const data = await getProduct();
      setProductList(data);
    };
    fetchProduct();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      const data = await getUsers();
      setUsers(data);
    };
    fetchUsers();
  }, []);

  const accessToken1 = localStorage.getItem("token");
  const tokenPayload = jwt_decode(accessToken1);
  const isTokenExpired = tokenPayload.exp < Date.now() / 1000;

  if (isTokenExpired) {
    localStorage.removeItem("token");
    localStorage.setItem("isLoggedin", false);
    navigate("/");
  }

  const screens = sessionStorage.getItem("screens");
  const screenActions = screens.split(",");

  const handleEdit = async (id) => {
    handleShow();
    setHeadtitle("Edit Product");
    setButtontxt("Update Product");
    const response = await axiosJWT.get(`${API_URL}/product/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken1}`,
      },
    });
    setProduct(response.data.product);
    setOwner(response.data.projectowner);
    setPrimaryid(response.data.id);
  };
  const datatable = {
    columns: [
      {
        label: "id",
        field: "id",
      },
      {
        label: "product",
        field: "product",
      },
      ...(screenActions.includes("Masters Edit") ||
      screenActions.includes("Masters Delete")
        ? [
            {
              label: "Actions",
              field: "actions",
            },
          ]
        : []),
    ],
    rows: productlist.map((stat, index) => {
      return {
        ...stat,
        id: index + 1,
        actions: (
          <div style={{ display: "flex" }}>
            {screenActions.includes("Masters Edit") && (
              <button
                className="demo-icons-list-item no-bg"
                title="Edit"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                onClick={() => handleEdit(stat.id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="blue"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M8 20l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4h4z" />
                  <path d="M13.5 6.5l4 4" />
                  <path d="M16 18h4m-2 -2v4" />
                </svg>
              </button>
            )}

            {screenActions.includes("Masters Delete") && (
              <button
                className="demo-icons-list-item no-bg"
                title="Delete"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                onClick={() => handleDelete(stat.id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-trash"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="red"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 7l16 0"></path>
                  <path d="M10 11l0 6"></path>
                  <path d="M14 11l0 6"></path>
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                </svg>
              </button>
            )}
          </div>
        ),
      };
    }),
  };

  // const handleKeyDown = (event) => {
  //   const keyCode = event.which || event.keyCode;
  //   const keyValue = String.fromCharCode(keyCode);
  //   const regex = /^[0-9]*$/; // regex to match only numbers
  //   if (!/^\d+$/.test(keyValue) && event.keyCode !== 8 && event.keyCode !== 9) {
  //     event.preventDefault();
  //   }
  //   if (event.target.value.length >= 10 && event.keyCode !== 8 && event.keyCode !== 9) {
  //     event.preventDefault();
  //   }
  // };

  const saveProduct = async () => {
    if (product.trim() === "") {
      toast.error("Please Enter Fields...!", { autoClose: 1000 });
      return;
    }
    try {
      const requestData = {
        product,
        projectowner: owner, // Assuming owner is the value you want to send from the dropdown
      };

      if (primaryid) {
        const response = await axiosJWT.post(
          `${API_URL}/updateproduct/${primaryid}`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${accessToken1}`,
            },
          }
        );
        toast.success(response.data.msg, { autoClose: 1000 });
        const fetchProduct = async () => {
          const data = await getProduct();
          setProductList(data);
        };
        fetchProduct();
        setTimeout(() => {
          handleClose();
        }, 1000);
      } else {
        const response = await axiosJWT.post(
          `${API_URL}/createproduct`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${accessToken1}`,
            },
          }
        );
        toast.success(response.data.msg, { autoClose: 1000 });
        setProduct("");
        const fetchProduct = async () => {
          const data = await getProduct();
          setProductList(data);
        };
        fetchProduct();
        setTimeout(() => {
          handleClose();
        }, 1000);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error, { autoClose: 2000 });
      } else {
        toast.error("Error occurred");
      }
    }
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Product?"
    );
    if (!confirmed) {
      return;
    }
    try {
      await axiosJWT.delete(`${API_URL}/product/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      toast.success("Product Deleted Successfully!", {
        autoClose: 2000,
      });
      setTimeout(() => {
        setProductList(productlist.filter((stat) => stat.id !== id));
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = (event) => {
    const keyCode = event.which || event.keyCode;

    if (keyCode === 13) {
      // Enter key is pressed
      event.preventDefault(); // Prevent the default form submission
      saveProduct(event); // Pass the event to the function
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <div className="page-pretitle">Overview</div>
              <h2 className="page-title">Masters/Product</h2>
            </div>
            <div className="col-auto ms-auto d-print-none">
              <div className="btn-list">
                {screenActions.includes("Masters Create") && (
                  <button
                    href="#"
                    className="btn btn-primary d-none d-sm-inline-block"
                    onClick={handleShow}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M12 5l0 14"></path>
                      <path d="M5 12l14 0"></path>
                    </svg>
                    New Product
                  </button>
                )}
              </div>
            </div>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              className="modal-xs"
            >
              <Modal.Header closeButton>
                <Modal.Title>{headtitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="col-12">
                  <form className="">
                    <div className="card-body">
                      <div className="row row-cards">
                        <div className="col-sm-6 col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Product*</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Product"
                              value={product}
                              onChange={(e) => setProduct(e.target.value)}
                              onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Project Owner*</label>
                            <select
                              className="form-select"
                              value={owner}
                              onChange={(e) => setOwner(e.target.value)}
                            >
                              <option value="">Select Project Owner</option>
                              {users.map((user) => (
                                <option key={user.id} value={user.firstname}>
                                  {user.firstname}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-md btn-primary ms-auto"
                  onClick={saveProduct}
                >
                  {btntxt}
                </button>
              </Modal.Footer>
            </Modal>
            <ToastContainer />
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Product List</h3>
                </div>
                <div className="container table-responsive mt-3">
                  <MDBDataTableV5
                    striped
                    fixed
                    hover
                    entriesOptions={[10, 20, 30, 50]}
                    entries={10}
                    pagesAmount={4}
                    data={datatable}
                    searchTop
                    searchBottom={false}
                  />
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
