import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const CommentChart = ({ data }) => {
  // Aggregate data by month and year
  const aggregateDataByMonth = (data) => {
    const result = {};
    const now = new Date();
    const oneYearAgo = new Date(now.getFullYear() - 1, now.getMonth(), 1); // Start of last year

    // Initialize all months in the result
    const months = [];
    let month = new Date(now.getFullYear(), now.getMonth(), 1);

    // Generate months from the current month to the same month last year
    while (month >= oneYearAgo) {
      const monthLabel = month.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      months.unshift(monthLabel); // Add to the beginning of the array to start with the oldest month
      result[monthLabel] = 0;
      month.setMonth(month.getMonth() - 1);
    }

    // Aggregate data
    data.forEach((item) => {
      const date = new Date(item.created_date_time);
      if (date >= oneYearAgo && date <= now) {
        const month = date.toLocaleString("default", {
          month: "long",
          year: "numeric",
        });
        if (result[month] !== undefined) {
          result[month] += 1;
        }
      }
    });

    return { aggregatedDurations: result, months };
  };

  // Validate data
  if (!data || !Array.isArray(data)) {
    console.error("Invalid data format or data not available.");
    return null;
  }

  const { aggregatedDurations, months } = aggregateDataByMonth(data);
  console.log("Aggregated Monthly Data:", aggregatedDurations); // Log aggregated data
  const labels = months;
  const counts = months.map((month) => aggregatedDurations[month] || 0);

  if (labels.length === 0 || counts.length === 0) {
    console.warn("No data available to render the chart.");
    return <p>No data available</p>;
  }

  // Chart data configuration
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Product Comments by Month",
        data: counts,
        fill: false,
        borderColor: "rgba(75,192,192,1)",
        backgroundColor: "rgba(75,192,192,0.2)",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        tension: 0.1,
      },
    ],
  };

  console.log("Chart Data:", chartData); // Log chart data

  // Chart options configuration
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Month",
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 12,
        },
      },
      y: {
        title: {
          display: true,
          text: "Count",
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div
      style={{
        position: "relative",
        height: "60vh",
        width: "80vw",
        margin: "0 auto", // Center the chart horizontally
      }}
    >
      <Line data={chartData} options={options} />
    </div>
  );
};

export default CommentChart;
