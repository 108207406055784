import React, { useEffect, useState } from "react";
import { Button, Alert, Table } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../ApiUrl";
import { getProduct } from "../masters/Lists/ListShow";

const NumberofBugesReports = ({ product, setProduct }) => {
  const [productsData, setProductsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [noData, setNoData] = useState(false); // State for no data found

  useEffect(() => {
    const fetchProductValues = async () => {
      try {
        const productData = await getProduct();
        setProduct({
          productValue: "",
          productDropDownValues: productData,
        });
      } catch (err) {
        console.error("Error fetching product data:", err);
        setError("Error fetching product data");
      }
    };
    fetchProductValues();
  }, [setProduct]);

  const token = localStorage.getItem("token");
  const { productValue, productDropDownValues } = product || {};

  const handleSubmit = async (productName) => {
    setLoading(true);
    setError(null);
    setNoData(false);

    try {
      const response = await axios.post(
        `${API_URL}/bugesbyproduct`,
        { product: productName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Check if response data is an array
      if (!Array.isArray(response.data)) {
        throw new Error("Unexpected response format");
      }

      const sortedData = response.data.sort(
        (a, b) => new Date(a.created_date_time) - new Date(b.created_date_time)
      );
      setProductsData(sortedData);

      if (sortedData.length === 0) {
        setNoData(true);
      }
    } catch (error) {
      setError("No Data Found For This Product");
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setProduct({ ...product, productValue: "" });
    setProductsData([]);
    setError(null); // Clear error on clear
    setNoData(false); // Clear no data state on clear
  };

  const handleProductChange = (e) => {
    const selectedProductName = e.target.value;

    setProduct({
      ...product,
      productValue: selectedProductName,
    });
    handleSubmit(selectedProductName);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <div className="d-flex justify-content-center mt-4">
      <div className="col-11">
        <div className="card custom-card">
          <div className="row">
            <div className="col-md-4 custom-selector">
              <h2>Product</h2>
              <select
                className="form-select"
                aria-label="Default select example"
                value={productValue}
                onChange={handleProductChange}
              >
                <option value="">Please choose one option</option>
                {productDropDownValues &&
                  productDropDownValues.map((option) => (
                    <option key={option.id} value={option.product}>
                      {option.product}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="d-grid gap-2 d-md-flex justify-content-md-end custom_btn mt-3">
            <Button variant="secondary" onClick={handleClear}>
              Clear
            </Button>
          </div>
        </div>
        {noData && (
          <Alert variant="info" className="mt-4">
            No bugs found for this product
          </Alert>
        )}
        {error && (
          <Alert variant="danger" className="mt-4">
            {error}
          </Alert>
        )}
        {loading && <p>Loading...</p>}
        {!loading && productsData.length > 0 && (
          <Table striped bordered hover className="mt-4">
            <thead>
              <tr>
                <th>Serial Number</th>
                <th>Number of Bugs</th>
                <th>Rating</th>
                <th>Product</th>
                <th>Subject</th>
                <th>Created Date</th>
                {/* Add other columns if necessary */}
              </tr>
            </thead>
            <tbody>
              {productsData.map((data, index) => (
                <tr key={data.id}>
                  <td>{index + 1}</td>
                  <td>{data.numberofbuges}</td>
                  <td>{data.rating}</td>
                  <td>{data.product}</td>
                  <td>{data.subject}</td>
                  <td>{formatDate(data.createdAt)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default NumberofBugesReports;
