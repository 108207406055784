/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { MDBDataTableV5 } from "mdbreact";
import Select from "react-select";
import React from "react";
import moment from "moment";
import { json, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import { getSubTickets } from "./Lists/ListShow";
import axios from "axios";
import { API_URL } from "../ApiUrl";
import jwt_decode from "jwt-decode";
import {
  getClients,
  getCycle,
  getPhase,
  getProduct,
  getTaskStatus,
  getUsers,
} from "../masters/Lists/ListShow";
import { Button, Modal } from "react-bootstrap";
import MOMENT from "moment-timezone";
import { getCurrentDate } from "../config";
import CompleteTicketModal from "./TimeTakenToComplete";
import CommentSection from "./CommentSection";
import Priority from "./Priority";
import { TestingNumberofBuges } from "./TestingNumberofBuges";

const axiosJWT = axios.create();

export default function SubTickets({
  assign,
  setAssign,
  priority,
  setPriority,
  product,
  setProduct,
  toAssign,
  setToAssign,
  status,
  setStatus,

  childNo,
  completed_date_time,
  dead_line,
  initial_date_time,
  parentNo,
  phase,
  source,
  subject,
  task,
  teams,
  setChildNo,
  setCompleted_date_time,
  setDead_line,
  setInitial_date_time,
  setParentNo,
  setPhase,
  setSource,
  setSubject,
  setTask,
  setTeams,
  setPrimaryId,
  primaryId,
  ticket_id,
  subTicketList,
  setSubTicketList,
  // setCurrentDateTime,
  setClient,
  client,
  setCommentArea,
  commentArea,
  viewTicket,
  setViewTicket,
  setCycle,
  cycle,
  setDuration,
  duration,
}) {
  const parentTeam = localStorage.getItem("team");
  const parentProduct = localStorage.getItem("product");
  const teamsValue = parentTeam;
  // const productValue = parentProduct;

  const { statusValue, statusDropDownValues } = status;
  const { sourceValue } = source;
  const { phaseValue, phaseDropDownValues } = phase;
  const { clientValue, clientDropDownValues } = client;

  const { productValue, productDropDownValues } = product;

  const { priorityValue, priorityDropDownValues } = priority;
  const { toAssignValue, toAssignDropDownValues } = toAssign;
  const { cycleValue, cycleDropDownValues } = cycle;

  const [autoid, setAutoid] = useState([]);
  const [textShow, setTextShow] = useState(false);
  const [textAreas, setTextAreas] = useState([""]);
  const [comments, setComments] = useState("");
  const [btntxt, setButtontxt] = useState("Create New Ticket");
  const [newTickets, setNewTickets] = useState(true);
  const [show, setShow] = useState(false);
  const [commentFlag, setCommentFlag] = useState(false);
  const [oldComment, setOldComment] = useState([]);
  const [deleteTicket, setDeleteTicket] = useState();
  // const [subLists, setSubLists] = useState([]);
  const [statusFromLocalStorage, setStatusFromLocalStorage] = useState("");
  const assinedTicket = viewTicket.assign;
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const handleClose = () => {
    setShow(false);
    setTextAreas([""]);
  };

  const [showBugesModal, setShowBugesModal] = useState(false);
  const [ticketIdToClose, setTicketIdToClose] = useState(null);

  const [showPriorityModal, setShowPriorityModal] = useState(false);
  const [checkParentNo, setCheckParentNo] = useState([]);

  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [timeTaken, setTimeTaken] = useState("");
  const [expectedTime, setExpectedTime] = useState("");
  const [ticketId, setTicketId] = useState("");

  const [readOnly, setReadOnly] = useState(false);

  const parentTicket = sessionStorage.getItem("parentNo");
  const showcompletedbtn = localStorage.getItem("showcompletedbtn");
  const completedTicketStatus = viewTicket.status;

  const [showModal, setShowModal] = useState(false);
  const [viewAssign, setViewAssign] = useState({
    viewAssignValue: "",
    viewAssignDropDownValues: [],
  });
  const { viewAssignValue, viewAssignDropDownValues } = viewAssign || {};

  useEffect(() => {
    const storedAssign = localStorage.getItem("assignState");
    if (storedAssign) {
      setAssign(JSON.parse(storedAssign));
    }
  }, []);

  const { assignValue, assignDropDownValues } = assign;

  const handleShowUsers = async () => {
    const assignData = await getUsers();
    setViewAssign({
      viewAssignValue: "",
      viewAssignDropDownValues: assignData,
    });

    setAssign({
      assignValue: "",
      assignDropDownValues: assignData,
    });
    setShowModal(false);
  };

  const getClient = async () => {
    const clientData = await getClients();
    setClient({
      clientValue: "",
      clientDropDownValues: clientData,
    });
  };

  const getCycleData = async () => {
    const cycleData = await getCycle();

    setCycle({
      cycleValue: "",
      cycleDropDownValues: cycleData,
    });
  };
  const getProductData = async () => {
    const products = await getProduct();
    setProduct({
      productValue: "",
      productDropDownValues: products,
    });
  };
  useEffect(() => {
    subTickets();
    getClient();
    getProductData();
    getCycleData();
    getAutoID();
    handleShowUsers();

    const accessToken1 = localStorage.getItem("token");
    const axiosJWT = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken1}`,
      },
    });

    if (localStorage.getItem("isRefreshing") === "true") {
      localStorage.setItem("isRefreshing", "false");
    } else {
      const fetchClients = async () => {
        const parentNo = parentTicket;
        const ticketIdRegex = /^[A-Z]+\d+/;

        const match = parentNo.match(ticketIdRegex);
        if (match) {
          const firstParentNo = match[0];

          try {
            const response = await axiosJWT.get(
              `${API_URL}/tickets/${firstParentNo}`,
              {
                headers: {
                  Authorization: `Bearer ${accessToken1}`,
                },
              }
            );

            setClient({
              ...client,
              clientValue: response.data.client,
            });
            setCycle({
              ...cycle,
              cycleValue: response.data.cycle,
            });
            setProduct({
              ...product,
              productValue: response.data.product,
            });

            setSource({
              ...source,
              sourceValue: response.data.source,
            });
            setTeams({
              ...teams,
              teamsValue: response.data.teams,
            });
            setDead_line({
              ...dead_line,
              dead_line: response.data.dead_line,
            });
            setPriority({
              ...priority,
              priorityValue: response.data.priority,
            });
            setAssign({
              ...assign,
              assignValue: response.data.assign,
            });
            setToAssign({
              ...toAssign,
              toAssignValue: response.data.toAssign,
            });
          } catch (error) {
            console.error("Error fetching client data:", error);
          }
        } else {
          console.error("parentNo does not match the expected format.");
        }
      };

      const fetchParentTicketNo = async () => {
        try {
          const response1 = await axiosJWT.get(`${API_URL}/tickets`, {
            headers: {
              Authorization: `Bearer ${accessToken1}`,
            },
          });
          setDeleteTicket(response1.data);
        } catch (error) {
          console.error("Error fetching parent ticket numbers:", error);
        }
      };
      fetchClients();
      fetchParentTicketNo();

      localStorage.setItem("isRefreshing", "false");
    }
    getAutoID();
  }, []);

  useEffect(() => {
    const status = localStorage.getItem("status");
    setStatusFromLocalStorage(status);
  }, []);

  const subTickets = async () => {
    const viewTicketDataString = localStorage.getItem("subTicketList");

    if (viewTicketDataString) {
      const viewTicketData = JSON.parse(viewTicketDataString);
      setSubTicketList(viewTicketData);
    }
  };

  const handleTextClose = () => {
    setTextShow(false);
  };

  const navigate = useNavigate();

  const role = sessionStorage.getItem("role");

  const handleChange = (e, index) => {
    const updatedTextAreas = [...textAreas];
    updatedTextAreas[index] = e.target.value;
    setTextAreas(updatedTextAreas);
  };

  const handlePriority = () => {
    setShowPriorityModal(true);
  };

  const handleClosePriorityModal = () => {
    setShowPriorityModal(false);
    const viewTicket = localStorage.getItem("viewTicket");
    if (viewTicket) {
      setViewTicket(JSON.parse(viewTicket));
    }
  };

  const handleDateChange = (e, field) => {
    const newValue = parseInt(e.target.value, 10);
    setDuration({
      ...duration,
      [field]: newValue,
    });
  };
  const removeTextArea = async (id) => {
    const updatedComments = oldComment.filter((comment) => comment.id !== id);
    setOldComment(updatedComments);

    try {
      const response = await axiosJWT.delete(`${API_URL}/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });

      if (response.status === 200) {
        console.log("Comment deleted from the database");
      } else {
        console.error("Failed to delete comment from the database");
      }
    } catch (error) {
      console.error("Error occurred while deleting comment:", error);
    }
  };

  const handleFileChange = (event) => {
    setAttachment(event.target.files[0]); // Store the selected file
  };

  const close = async (ticket_id, onSuccess) => {
    try {
      await axios.post(
        `${API_URL}/tickets/${ticket_id}`,
        {
          status: "Closed",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      if (viewTicket.phase === "Testing" || viewTicket.phase === "testing") {
        setShowBugesModal(true);
      } else {
        setShowBugesModal(false);
      }

      toast.success("Ticket status updated to Closed", { autoClose: 1000 });
      setTimeout(() => {
        onSuccess();
        handleClose();
      }, 1000);

      // navigate("/dashboard");
    } catch (err) {
      console.log(err);
    }
  };

  const assignTicket = async (ticketById) => {
    try {
      await axiosJWT.post(
        `${API_URL}/updateAssignTicket/${ticketById}`,
        {
          assignValue: viewAssignValue,
          statusValue: "Assigned",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );
      // handleModal();
      const response1 = await axiosJWT.get(`${API_URL}/tickets/${ticketById}`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      localStorage.setItem("viewTicket", JSON.stringify(response1.data));
      const viewTicketDataString = localStorage.getItem("viewTicket");
      if (viewTicketDataString) {
        setViewTicket(response1.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleModal = async (ticket_id) => {
    setShowModal(!showModal);
    try {
      const response = await axiosJWT.get(`${API_URL}/tickets/${ticket_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });

      await axiosJWT.post(
        `${API_URL}/createticketmasterlog`,
        {
          assign: response.data.assign,
          ticket_id: response.data.ticket_id,
          phase: response.data.phase,
          noofdaysexpected: response.data.noofdaysexpected,
          assigned_date_time: new Date().toISOString(),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      setViewAssign({
        ...viewAssign,
        viewAssignValue: response.data.assign,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleComments = async () => {
    setTextShow(true);
    const response = await axiosJWT.get(`${API_URL}/commentbyid/${comments}`, {
      headers: {
        Authorization: `Bearer ${accessToken1}`,
      },
    });
    setOldComment(response.data);
  };

  const currentDateTime = new Date();
  const username = sessionStorage.getItem("username");

  const createTextArea = async (index) => {
    try {
      const comment = textAreas.toString();
      const commentToPost = comment.trim();

      if (commentToPost !== "") {
        await axios.post(
          `${API_URL}/createcomment`,
          {
            ticket_id: autoid,
            created_by: username,
            comments: commentToPost,
            from_date: "",
            to_date: "",
            created_date_time: currentDateTime.toISOString(),
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken1}`,
            },
          }
        );
        setTimeout(() => {
          handleTextClose();
        }, 1000);
        toast.success("Comment posted successfully", { autoClose: 1000 });
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  let datetime = MOMENT().tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");

  const getAutoID = async () => {
    try {
      const response = await axiosJWT.get(`${API_URL}/getautoid/subticket`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      setAutoid(response.data.ticket_id);
    } catch (error) {
      if (error) {
        console.log(error.message);
      }
    }
  };

  const accessToken1 = localStorage.getItem("token");
  jwt_decode(accessToken1);

  const handleShow = async () => {
    getAutoID();
    setShow(false);
    setCommentFlag(true);
    setReadOnly(true);
    setComments("");
    // setBtnTextComment('Create');
    setButtontxt("Create New Ticket");
    setPrimaryId("");
    setNewTickets(!newTickets);
    setChildNo("");
    setCompleted_date_time("");
    setInitial_date_time("");
    setSubject("");
    setDuration([""]);
    setTask("");
    // setTextAreas([""]);

    const statusData = await getTaskStatus();
    setStatus({
      statusValue: "",
      statusDropDownValues: statusData,
    });

    const phaseData = await getPhase();
    setPhase({
      phaseValue: "",
      phaseDropDownValues: phaseData,
    });
  };

  const handleCloseTicket = async (ticket_id) => {
    if (ticket_id) {
      await close(ticket_id, () => {
        setTicketIdToClose(ticket_id);
      });
    }
  };

  const handleShowModal = (ticket_id) => {
    setTicketIdToClose(ticket_id);
    setShowBugesModal(true);
  };

  const handleCloseModal = () => {
    setShowBugesModal(false);
  };

  const handleConfirmClose = async (ticket_id) => {
    if (ticket_id) {
      await close(ticket_id, handleCloseModal);
    }
  };
  const handleEdit = async (ticket_id) => {
    setNewTickets(!newTickets);
    setComments(ticket_id);
    setCommentFlag(false);
    setAutoid(ticket_id);
    setReadOnly(false);
    setButtontxt("Update ticket");
    // setBtnTextComment('Update Comment');
    try {
      const response = await axiosJWT.get(`${API_URL}/tickets/${ticket_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      console.log("edit", response.data);
      setCycle({ ...cycle, cycleValue: response.data.cycle });
      setChildNo(response.data.child_no);
      setCompleted_date_time(response.data.completed_date_time);
      setDead_line(response.data.dead_line);
      setInitial_date_time(response.data.initial_date_time);
      setClient({
        ...client,
        clientValue: response.data.client,
      });
      setParentNo(response.data.parent_no);
      setPhase({
        ...phase,
        phaseValue: response.data.phase,
      });

      setPriority({
        ...priority,
        priorityValue: response.data.priority,
      });
      setProduct({
        ...product,
        productValue: response.data.product,
      });
      setSource({
        ...source,
        sourceValue: response.data.source,
      });
      setStatus({
        ...status,
        statusValue: response.data.status,
      });
      setSubject(response.data.subject);
      setTask(response.data.task);
      setTeams({
        ...teams,
        teamsValue: response.data.teams,
      });
      setPrimaryId(response.data.id);
      setAssign({
        ...assign,
        assignValue: response.data.assign,
      });
      setToAssign({
        ...toAssign,
        toAssignValue: response.data.toAssign,
      });
      const { days, hours, minutes } = parseDuration(
        response.data.noofdaysexpected
      );

      // Update state with the parsed values
      setDuration({ days, hours, minutes });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async (ticket_id) => {
    const hasSubTickets =
      deleteTicket &&
      deleteTicket.some((ticket) => ticket.parent_no === ticket_id);

    if (hasSubTickets) {
      window.alert("This ticket has sub-tickets and cannot be deleted.");
      return;
    }
    const confirmed = window.confirm(
      "Are you sure you want to delete this subTicket?"
    );
    if (!confirmed) {
      return;
    }
    try {
      await axiosJWT.delete(`${API_URL}/subtickets/${ticket_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      toast.success("Sub Ticket Deleted Successfully!", {
        autoClose: 2000,
      });
      const fetchSubTickets = async () => {
        const subParentNo = sessionStorage.getItem("parentNo");
        const data = await getSubTickets();
        const filteredSubTickets = data.filter(
          (element) => element.parent_no === subParentNo
        );
        const deletedFilter = filteredSubTickets.filter(
          (element) => element.ticket_id !== ticket_id
        );
        setSubTicketList(deletedFilter);
      };
      fetchSubTickets();
    } catch (error) {
      console.log(error);
    }
  };

  const getFormattedDuration = () => {
    const { days, hours, minutes } = duration;

    // Ensure that missing values are set to zero
    const safeDays = days || 0;
    const safeHours = hours || 0;
    const safeMinutes = minutes || 0;

    return `${safeDays}d${safeHours}h${safeMinutes}m`; // Constructing the formatted duration
  };

  const parseDuration = (durationString) => {
    if (!durationString) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
      };
    }

    const daysMatch = durationString.match(/(\d+)d/);
    const hoursMatch = durationString.match(/(\d+)h/);
    const minutesMatch = durationString.match(/(\d+)m/);
    const days = daysMatch ? parseInt(daysMatch[1], 10) : 0;
    const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
    const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;
    return { days, hours, minutes };
  };

  const getAutoSubID = async () => {
    try {
      await axiosJWT.get(`${API_URL}/getautoid/subticket`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
    } catch (error) {
      if (error) {
        console.log(error.message);
      }
    }
  };

  const handleOpenCompleteModal = () => {
    setShowCompleteModal(true);
  };

  const createSubTicketsBasedOnCycle = async (parentId, cycle) => {
    const cycleSteps = cycle.split("-").map((step) => step.trim()); // Trim each step

    const firstStep = cycleSteps[0].toLowerCase();
    const response = await axiosJWT.get(`${API_URL}/getautoid/subticket`, {
      headers: {
        Authorization: `Bearer ${accessToken1}`,
      },
    });

    const formatDate = (date) => {
      return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    };

    const getFutureDate = () => {
      const today = new Date();
      const futureDate = new Date(today.setDate(today.getDate() + 7));
      return formatDate(futureDate); // Format as YYYY-MM-DD
    };

    // Extract the date string if dead_line is an object
    const deadline =
      dead_line && typeof dead_line === "object" && dead_line.dead_line
        ? dead_line.dead_line
        : dead_line || getFutureDate();

    if (firstStep === "test cases and coding") {
      const commonData = {
        autoid: response.data.ticket_id,
        parentNo: parentId,
        childNo,
        username,
        completed_date_time,
        deadline,
        initial_date_time,
        clientValue,
        priorityValue,
        productValue,
        sourceValue,
        statusValue,
        subject,
        task,
        teamsValue,
        assignValue: toAssignValue,
        toAssignValue,
        cycleValue,
        attachment,
      };

      function incrementLastNumber(input) {
        const regex = /(\d+)$/;

        const match = input.match(regex);

        if (match) {
          const numberStr = match[1];

          const incrementedNumber = parseInt(numberStr, 10) + 1;

          const incrementedNumberStr = incrementedNumber
            .toString()
            .padStart(numberStr.length, "0");

          const updatedString = input.replace(regex, incrementedNumberStr);

          return updatedString;
        } else {
          throw new Error("No numeric portion found to increment.");
        }
      }

      const original = response.data.ticket_id;
      const updated = incrementLastNumber(original);

      await axiosJWT.post(
        `${API_URL}/createsubticket`,
        {
          ...commonData,
          // autoid: updated,
          phaseValue: "test cases",
          assignValue: "Anitha",
          statusValue: "Assigned",
          completed_date_time: null,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      await axiosJWT.post(
        `${API_URL}/createsubticket`,
        {
          ...commonData,
          autoid: updated,
          completed_date_time: null,
          phaseValue: "coding",
          initial_date_time: new Date().toISOString(),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );
    } else if (firstStep !== "test cases and coding" && firstStep !== "none") {
      const commonData = {
        autoid: response.data.ticket_id,
        parentNo: parentId,
        childNo,
        username,
        completed_date_time,
        deadline,
        initial_date_time,
        clientValue,
        priorityValue,
        productValue,
        sourceValue,
        statusValue,
        subject,
        task,
        teamsValue,
        assignValue,
        toAssignValue,
        cycleValue,
        attachment,
      };

      // First sub-ticket with "Testing"
      await axiosJWT.post(
        `${API_URL}/createsubticket`,
        {
          ...commonData,
          phaseValue: firstStep,
          completed_date_time: null,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );
    }
    // else if (firstStep === "code review") {
    //   const commonData = {
    //     autoid: response.data.ticket_id,
    //     parentNo: parentId,
    //     childNo,
    //     username,
    //     completed_date_time,
    //     dead_line,
    //     initial_date_time,
    //     clientValue,
    //     priorityValue,
    //     productValue,
    //     sourceValue,
    //     statusValue,
    //     subject,
    //     task,
    //     teamsValue,
    //     assignValue,
    //     toAssignValue,
    //     cycleValue: cycle,
    //   };

    //   await axiosJWT.post(
    //     `${API_URL}/createsubticket`,
    //     {
    //       ...commonData,
    //       phaseValue: "Code Review",
    //     },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${accessToken1}`,
    //       },
    //     }
    //   );
    // }
    // else {
    //   const commonData = {
    //     autoid: response.data.ticket_id,
    //     parentNo: parentId,
    //     childNo,
    //     completed_date_time,
    //     dead_line,
    //     initial_date_time,
    //     clientValue,
    //     priorityValue,
    //     productValue,
    //     sourceValue,
    //     statusValue,
    //     subject,
    //     task,
    //     teamsValue,
    //     assignValue,
    //     toAssignValue,
    //     username,
    //     cycleValue: cycle,
    //   };

    //   await axiosJWT.post(
    //     `${API_URL}/createsubticket`,
    //     {
    //       ...commonData,
    //       phaseValue: "Requirement",
    //     },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${accessToken1}`,
    //       },
    //     }
    //   );
    // }
  };

  useEffect(() => {
    const getFutureDate = () => {
      const today = new Date();
      const futureDate = new Date(today.setDate(today.getDate() + 7));
      return futureDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    };

    if (dead_line && typeof dead_line === "object") {
      console.error("dead_line is an object, correcting it");
      setDead_line(dead_line.dead_line || getFutureDate()); // Extract the correct value or fallback to future date
    } else if (!dead_line) {
      const deadline = getFutureDate();
      setDead_line(deadline);
    }
  }, [dead_line]);

  const saveTicket = async (data) => {
    data.preventDefault();
    setNewTickets(false);
    let toAssignValue = "";

    const isInvalid = (value) =>
      value === "" || value === "Please choose one option";

    if (
      isInvalid(statusValue) ||
      isInvalid(clientValue) ||
      isInvalid(subject) ||
      isInvalid(task) ||
      isInvalid(phaseValue) ||
      // isInvalid(priorityValue) ||
      isInvalid(productValue) ||
      isInvalid(sourceValue) ||
      isInvalid(teamsValue) ||
      isInvalid(cycleValue)
    ) {
      toast.error("Please Enter All Fields...!", { autoClose: 1000 });
      return;
    } else {
      setNewTickets(true);
    }

    try {
      const products = await getProduct();

      const selectedProduct = products.find(
        (product) => product.product === productValue
      );
      toAssignValue = selectedProduct ? selectedProduct.projectowner : "";
    } catch (error) {
      console.error("Error fetching product data:", error);
    }

    const formatDate = (date) => {
      return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    };

    const getFutureDate = () => {
      const today = new Date();
      const futureDate = new Date(today.setDate(today.getDate() + 7));
      return formatDate(futureDate);
    };

    const deadline =
      dead_line && typeof dead_line === "object" && dead_line.dead_line
        ? dead_line.dead_line
        : dead_line || getFutureDate();
    const ticketPayload = new FormData();
    ticketPayload.append("autoid", autoid);
    ticketPayload.append("username", username);
    ticketPayload.append("childNo", childNo);
    ticketPayload.append("completed_date_time", completed_date_time);
    ticketPayload.append("deadline", deadline);
    ticketPayload.append("initial_date_time", currentDateTime.toISOString());
    ticketPayload.append("clientValue", clientValue);
    ticketPayload.append("parentNo", parentNo);
    ticketPayload.append("phaseValue", phaseValue);
    ticketPayload.append("priorityValue", priorityValue);
    ticketPayload.append("productValue", productValue);
    ticketPayload.append("sourceValue", sourceValue);
    ticketPayload.append("statusValue", statusValue);
    ticketPayload.append("subject", subject);
    ticketPayload.append("task", task);
    ticketPayload.append("teamsValue", teamsValue);
    ticketPayload.append("assignValue", assignValue);
    ticketPayload.append("toAssignValue", toAssignValue);
    ticketPayload.append("formattedDuration", getFormattedDuration());
    ticketPayload.append("cycleValue", cycleValue);
    if (attachment) {
      ticketPayload.append("attachment", attachment);
    }

    const submitTicket = async (url, payload) => {
      try {
        const response = await axios.post(url, payload, {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success(response.data.msg, { autoClose: 1000 });
        if (!primaryId) {
          localStorage.setItem("subTicketList", JSON.stringify(response.data));
          const parentId = response.data.id;
          getAutoSubID();

          await createSubTicketsBasedOnCycle(parentId, cycleValue);
          toast.success("Ticket created successfully", { autoClose: 1000 });
        }
        handleTextClose();
      } catch (error) {
        console.error(error);
      }
    };

    if (primaryId) {
      await submitTicket(
        `${API_URL}/updatesubticket/${primaryId}`,
        ticketPayload
      );
    } else {
      await submitTicket(`${API_URL}/createsubticket`, ticketPayload);
    }

    const commentToPost = textAreas.toString().trim();
    if (commentToPost !== "") {
      await axios.post(
        `${API_URL}/createcomment`,
        {
          ticket_id: autoid,
          created_by: username,
          comments: commentToPost,
          from_date: "",
          to_date: "",
          created_date_time: currentDateTime.toISOString(),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );
    }

    const fetchTickets = async () => {
      const data = await getSubTickets();
      const subParentNo = sessionStorage.getItem("parentNo");
      const filteredTickets = data.filter(
        (element) => element.parent_no === subParentNo
      );
      setSubTicketList(filteredTickets);
      localStorage.setItem("subTicketList", JSON.stringify(filteredTickets));
    };

    fetchTickets();
    getAutoID();
    setTimeout(() => {
      handleTextClose();
    }, 1000);
  };
  const copyOtherValues = (viewticket) => {
    return {
      parentNo: viewticket.parent_no,
      deadline: viewticket.dead_line || null,
      initial_date_time: new Date().toISOString(),
      clientValue: viewticket.client,
      priorityValue: viewticket.priority || null,
      productValue: viewticket.product || null,
      sourceValue: viewticket.source || null,
      subject: viewticket.subject || null,
      task: viewticket.task || null,
      teamsValue: viewticket.teams || null,
      toAssignValue: toAssignValue,
      // statusValue: "Processing",
      // completed_date_time: viewticket.completed_date_time,
      cycleValue: viewticket.cycle || null,
      childNo: childNo || null,
      formattedDuration: viewticket.noofdaysexpected || null,
    };
  };

  // const handleCompleteTicket = async () => {
  //   try {
  //     await axios.post(
  //       `${API_URL}/tickets/${ticket_id}`,
  //       {
  //         status: "Completed",
  //         completed_date_time: new Date().toISOString(),
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken1}`,
  //         },
  //       }
  //     );
  //     // navigate("/dashboard");
  //   } catch (err) {
  //     console.error(err);
  //   }
  //   handleCloseCompleteModal();
  // };

  const completed = async (viewticket) => {
    const ticketId = viewticket.ticket_id;

    if (ticketId.startsWith("ST")) {
      const baseTicketId = parseInt(ticketId.replace("ST", ""), 10);

      await axiosJWT.post(
        `${API_URL}/tickets/${ticketId}`,
        {
          status: "Completed",
          completed_date_time: new Date().toISOString(),
        },
        {
          headers: { Authorization: `Bearer ${accessToken1}` },
        }
      );

      toast.success("Ticket status updated to Completed", { autoClose: 1000 });
      // navigate("/dashboard");
      setTimeout(() => {
        handleClose();
      }, 1000);

      const currentDateTime = new Date();
      const ticketDeadline = new Date(viewticket.deadline);
      const createdOn = new Date(viewTicket.createdAt);
      if (currentDateTime > ticketDeadline) {
        setSelectedTicket(viewticket);
        handleOpenCompleteModal();

        // setShowCompleteModal(true);
      }

      const phase = viewticket.phase?.toLowerCase();
      const parentNo = viewticket.parent_no;

      if (phase === "entree") {
        const response = await axiosJWT.get(
          `${API_URL}/filteredSubTickets/${parentNo}`,
          {
            headers: { Authorization: `Bearer ${accessToken1}` },
          }
        );
        const hasIncompleteTickets = response.data.some(
          (ticket) => ticket.status !== "Completed"
        );
      }

      if (!viewticket.cycle || !viewticket.cycle.trim()) {
        toast.error("Cannot proceed without a valid cycle.");
        return;
      }

      let cyclePhases = viewticket.cycle.split(" - ");

      const findMatchingPhaseIndex = (phases, phaseToMatch) => {
        return phases.findIndex((p) =>
          p.toLowerCase().includes(phaseToMatch.toLowerCase())
        );
      };

      const currentPhaseIndex = findMatchingPhaseIndex(cyclePhases, phase);

      let nextPhase = null;
      if (
        currentPhaseIndex !== -1 &&
        currentPhaseIndex < cyclePhases.length - 1
      ) {
        nextPhase = cyclePhases[currentPhaseIndex + 1];
      }

      let beforeTicketId = null;
      let afterTicketId = null;

      if (phase === "test cases") {
        afterTicketId = `ST${(baseTicketId + 1).toString().padStart(4, "0")}`;
      } else if (phase === "coding") {
        beforeTicketId = `ST${(baseTicketId - 1).toString().padStart(4, "0")}`;
      }

      let isSubTicketCreationAllowed = false;

      const hasTestCasesAndCoding = nextPhase == "test cases and coding";
      const correctAutoid = parseInt(autoid.replace("ST", ""), 10);

      const subTickets = [];
      const testCasesTicketId = `ST${(correctAutoid + 1)
        .toString()
        .padStart(4, "0")}`;
      const codingTicketId = `ST${(correctAutoid + 2)
        .toString()
        .padStart(4, "0")}`;
      if (hasTestCasesAndCoding) {
        const firstSubTicket = {
          autoid: testCasesTicketId,
          username,
          phaseValue: "test cases",
          completed_date_time: null,
          assignValue: "Anitha",
          deadline: viewticket.dead_line,
          statusValue: "Assigned",
          ...copyOtherValues(viewticket),
        };

        const secondSubTicket = {
          autoid: codingTicketId,
          username,
          phaseValue: "coding",
          statusValue: "Unassigned",
          assignValue: "2cqr automation",
          completed_date_time: null,
          deadline: viewticket.dead_line,
          initial_date_time: new Date().toISOString(),

          ...copyOtherValues(viewticket),
        };

        subTickets.push(firstSubTicket, secondSubTicket);
      } else if (phase === "test cases" || phase === "coding") {
        const beforeTicket = beforeTicketId
          ? await axiosJWT.get(`${API_URL}/tickets/${beforeTicketId}`, {
              headers: { Authorization: `Bearer ${accessToken1}` },
            })
          : null;

        const afterTicket = afterTicketId
          ? await axiosJWT.get(`${API_URL}/tickets/${afterTicketId}`, {
              headers: { Authorization: `Bearer ${accessToken1}` },
            })
          : null;

        const isBeforeCompleted = beforeTicket?.data?.status === "Completed";
        const isAfterCompleted = afterTicket?.data?.status === "Completed";

        const hasSameParentNoBefore =
          beforeTicket && beforeTicket.data.parent_no === parentNo;

        const hasSameParentNoAfter =
          afterTicket && afterTicket.data.parent_no === parentNo;
        if (
          (phase === "test cases" &&
            hasSameParentNoAfter &&
            isAfterCompleted) ||
          (phase === "coding" && hasSameParentNoBefore && isBeforeCompleted)
        ) {
          isSubTicketCreationAllowed = true;
        }

        const isAboveOrBelowNotMatched =
          (phase === "test cases" && !hasSameParentNoAfter) ||
          (phase === "coding" && !hasSameParentNoBefore);
        if (
          isAboveOrBelowNotMatched &&
          nextPhase !== null &&
          phase !== "entree"
        ) {
          let newSubTicket;

          if (nextPhase === "code review") {
            newSubTicket = {
              autoid,
              username,
              phaseValue: nextPhase,
              assignValue: "2cqr automation",
              statusValue: "Unassigned",
              deadline: viewticket.dead_line,

              completed_date_time: null,
              ...copyOtherValues(viewticket),
            };
          } else if (nextPhase === "testing") {
            newSubTicket = {
              autoid,
              username,
              phaseValue: nextPhase,
              assignValue: "Anitha",
              deadline: viewticket.dead_line,
              statusValue: "Assigned",
              completed_date_time: null,
              ...copyOtherValues(viewticket),
            };
          } else {
            newSubTicket = {
              autoid,
              username,
              phaseValue: nextPhase,
              completed_date_time: null,
              deadline: viewticket.dead_line,
              assignValue: "2cqr automation",
              statusValue: "Unassigned",
              ...copyOtherValues(viewticket),
            };
          }
          console.log(newSubTicket);

          await axiosJWT.post(`${API_URL}/createsubticket`, newSubTicket, {
            headers: { Authorization: `Bearer ${accessToken1}` },
          });
          getAutoID();
          if (role === "Admin") {
            toast.success("New sub-ticket created for the next phase!", {
              autoClose: 1000,
            });
          } else {
            toast.info("Ticket is Completed", { autoClose: 1000 });
          }

          const fetchSubTickets = async () => {
            const subParentNo = sessionStorage.getItem("parentNo");
            const data = await getSubTickets();
            const filteredSubTickets = data.filter(
              (element) => element.parent_no === subParentNo
            );

            setSubTicketList(filteredSubTickets);
          };
          fetchSubTickets();
        } else if (
          isSubTicketCreationAllowed &&
          nextPhase !== null &&
          phase !== "entree"
        ) {
          const newSubTicket = {
            autoid,
            username,
            phaseValue: nextPhase,
            assignValue: "2cqr automation",
            deadline: viewticket.dead_line,
            completed_date_time: null,
            statusValue: "Unassigned",
            ...copyOtherValues(viewticket),
          };

          await axiosJWT.post(`${API_URL}/createsubticket`, newSubTicket, {
            headers: { Authorization: `Bearer ${accessToken1}` },
          });
          getAutoID();
          if (role === "Admin") {
            toast.success("New sub-ticket created for the next phase!", {
              autoClose: 1000,
            });
          } else {
            toast.info("Ticket is Completed", { autoClose: 1000 });
          }

          const fetchSubTickets = async () => {
            const subParentNo = sessionStorage.getItem("parentNo");
            const data = await getSubTickets();
            const filteredSubTickets = data.filter(
              (element) => element.parent_no === subParentNo
            );

            setSubTicketList(filteredSubTickets);
          };
          fetchSubTickets();
        } else {
          if (role === "Admin") {
            toast.info(
              `Cannot create a new sub-ticket. Ensure that the corresponding ${
                phase === "test cases" ? "coding" : "test cases"
              } ticket is completed and shares the same parent number.`,
              { autoClose: 2000 }
            );
          } else {
            toast.info("Ticket is Completed", { autoClose: 2000 });
          }
        }
      } else {
        let newSubTicket;
        if (nextPhase === "testing") {
          newSubTicket = {
            autoid,
            phaseValue: nextPhase,
            username,
            assignValue: "Anitha",
            deadline: viewticket.dead_line,
            statusValue: "Assigned",
            completed_date_time: null,
            ...copyOtherValues(viewticket),
          };
        } else {
          newSubTicket = {
            autoid,
            phaseValue: nextPhase,
            username,
            assignValue: "2cqr automation",
            deadline: viewticket.dead_line,
            completed_date_time: null,
            statusValue: "Unassigned",
            ...copyOtherValues(viewticket),
          };
        }

        subTickets.push(newSubTicket);
      }

      // Create the sub-tickets
      if (
        !isSubTicketCreationAllowed &&
        nextPhase !== null &&
        phase !== "Entrée"
      ) {
        for (const subTicket of subTickets) {
          await axiosJWT.post(`${API_URL}/createsubticket`, subTicket, {
            headers: {
              Authorization: `Bearer ${accessToken1}`,
            },
          });
        }
        getAutoID();

        toast.success("Sub-tickets created successfully!", { autoClose: 1000 });
        const fetchSubTickets = async () => {
          const subParentNo = sessionStorage.getItem("parentNo");
          const data = await getSubTickets();
          const filteredSubTickets = data.filter(
            (element) => element.parent_no === subParentNo
          );

          setSubTicketList(filteredSubTickets);
        };
        fetchSubTickets();
      }
    } else if (ticketId.startsWith("T")) {
      // If ticket_id starts with "T"
      try {
        await axiosJWT.post(
          `${API_URL}/tickets/${viewticket.ticket_id}`,
          {
            status: "Completed",
            completed_date_time: new Date().toISOString(),
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken1}`,
            },
          }
        );
        toast.success("Ticket status updated to Completed", {
          autoClose: 1000,
        });
        // navigate("/dashboard");
        handleClose();
      } catch (error) {
        toast.error("Failed to update the ticket status.", { autoClose: 1000 });
      }
    }
  };

  const screens = sessionStorage.getItem("screens").split(",");

  const duplicateTicket = async (ticket_id) => {
    await axiosJWT.post(
      `${API_URL}/tickets/${ticket_id}`,
      {
        status: "Duplicate",
        // completed_date_time: new Date().toISOString(),
      },
      {
        headers: { Authorization: `Bearer ${accessToken1}` },
      }
    );

    toast.success("Ticket status updated to Completed", { autoClose: 1000 });
    navigate("/dashboard");
    setTimeout(() => {
      handleClose();
    }, 1000);
  };

  const backTicket = async (data) => {
    data.preventDefault();
    setNewTickets(true);
    setCommentFlag(false);
  };

  const ticketView = async (ticket_id) => {
    const parentNo = ticket_id;
    localStorage.setItem("showcompletedbtn", true);
    localStorage.setItem("ticket_id", ticket_id);
    const subTicketResponse = await axios.get(
      `${API_URL}/filteredSubTickets/${parentNo}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      }
    );
    const checkParentNo = async () => {
      const subTicketResponse = await axios.get(
        `${API_URL}/filteredSubTickets/${ticket_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );
      setCheckParentNo(subTicketResponse.data);
      return subTicketResponse;
    };

    checkParentNo();

    const subTickets = subTicketResponse.data;
    const updateLocalStorageBasedOnStatus = (subTickets) => {
      let hasPendingStatus = false;

      if (subTickets.length === 0) {
        localStorage.setItem("status", "completed");

        return;
      }
      for (let i = 0; i < subTickets.length; i++) {
        const ticketStatus = subTickets[i].status;

        if (ticketStatus !== "Completed" && ticketStatus !== "Closed") {
          hasPendingStatus = true;
          break;
        }
      }

      if (hasPendingStatus) {
        localStorage.setItem("status", "pending");
      } else {
        localStorage.setItem("status", "completed");
      }
    };

    updateLocalStorageBasedOnStatus(subTickets);
    setShow(true);
    const response = await axiosJWT.get(`${API_URL}/commentbyid/${ticket_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken1}`,
      },
    });
    const response1 = await axiosJWT.get(`${API_URL}/tickets/${ticket_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken1}`,
      },
    });
    setViewTicket(response1.data);
    setCommentArea(response.data);
    const status = localStorage.getItem("status");
    setStatusFromLocalStorage(status);
    // if (localStorage.getItem("viewTicket")) {
    //   const viewTicketData = JSON.parse(localStorage.getItem("viewTicket"));
    //   setViewTicket(viewTicketData);
    // }
  };

  const subTicket = async (ticket_id) => {
    let parentNoHistory = sessionStorage.getItem("parentNoHistory");
    parentNoHistory = parentNoHistory ? JSON.parse(parentNoHistory) : [];
    parentNoHistory.push(ticket_id);
    sessionStorage.setItem("parentNoHistory", JSON.stringify(parentNoHistory));

    sessionStorage.setItem("parentNo", ticket_id);

    const subParentNo = sessionStorage.getItem("parentNo");
    navigate("/subTickets");
    const axiosJWT = axios.create();
    const accessToken1 = localStorage.getItem("token");

    try {
      const responseProductTeams = await axiosJWT.get(
        `${API_URL}/tickets/${ticket_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      localStorage.setItem("product", responseProductTeams.data.product);
      localStorage.setItem("team", responseProductTeams.data.teams);
      const response = await axiosJWT.get(
        `${API_URL}/filteredSubTickets/${subParentNo}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      localStorage.setItem("subTicketList", JSON.stringify(response.data));
      const viewTicketDataString = localStorage.getItem("subTicketList");

      if (viewTicketDataString) {
        const viewTicketData = JSON.parse(viewTicketDataString);
        setSubTicketList(viewTicketData);
      }
      setTimeout(() => {
        handleClose();
      }, 1000);
    } catch (error) {
      console.error(error);
    }
    setParentNo(ticket_id);
  };

  const parentTicketNo = sessionStorage.getItem("parentNo");

  const statusOrder = [
    "Assigned",
    "Pending",
    "Unassigned",
    "Completed",
    "Closed",
  ];

  const getStatusOrderIndex = (status) => statusOrder.indexOf(status);

  const sortedTickets = [...subTicketList]
    .filter((ticket) => role === "Admin" || ticket.assign === username) // Conditionally apply filter based on role
    .sort((a, b) => {
      const statusComparison =
        getStatusOrderIndex(a.status) - getStatusOrderIndex(b.status);
      if (statusComparison !== 0) return statusComparison;

      const ticketIdA = parseInt(a.ticket_id.slice(1), 10);
      const ticketIdB = parseInt(b.ticket_id.slice(1), 10);
      const ticketIdComparison = ticketIdB - ticketIdA;
      if (ticketIdComparison !== 0) return ticketIdComparison;

      return new Date(b.lastUpdated) - new Date(a.lastUpdated);
    });

  const datatable = {
    columns: [
      {
        label: "id",
        field: "id",
      },
      {
        label: "parent_no",
        field: "parent_no",
      },
      {
        label: "ticket_id",
        field: "ticket_id",
      },
      {
        label: "client",
        field: "client",
      },
      {
        label: "product",
        field: "product",
      },
      {
        label: "status",
        field: "status",
      },
      {
        label: "subject",
        field: "subject",
      },
      {
        label: "phase",
        field: "phase",
      },
      {
        label: "assign",
        field: "assign",
      },
      {
        label: "Actions",
        field: "actions",
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Actions",
        },
      },
    ],
    rows: sortedTickets.map((ticket, index) => {
      return {
        ...ticket,
        id: index + 1,
        actions: (
          <div style={{ display: "flex" }}>
            {screens !== undefined && screens.includes("Ticket Edit") && (
              <button
                className="demo-icons-list-item no-bg"
                title="Edit"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                onClick={() => handleEdit(ticket.ticket_id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="blue"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M8 20l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4h4z" />
                  <path d="M13.5 6.5l4 4" />
                  <path d="M16 18h4m-2 -2v4" />
                </svg>
              </button>
            )}
            {screens !== undefined && screens.includes("Ticket Delete") && (
              <button
                className="demo-icons-list-item no-bg"
                title="Delete"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                onClick={() => handleDelete(ticket.ticket_id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-trash"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="red"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 7l16 0"></path>
                  <path d="M10 11l0 6"></path>
                  <path d="M14 11l0 6"></path>
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                </svg>
              </button>
            )}

            {screens !== undefined && screens.includes("Ticket View") && (
              <button
                className="demo-icons-list-item no-bg"
                title="View"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                onClick={() => ticketView(ticket.ticket_id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-eye"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                  <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
                </svg>
              </button>
            )}
            {screens !== undefined && (
              // && screens.includes("Ticket Sub")
              <button
                className="demo-icons-list-item no-bg"
                title="Sub Ticket"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                onClick={() => subTicket(ticket.ticket_id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-tournament"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 4m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M20 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M4 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M4 20m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M6 12h3a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-3"></path>
                  <path d="M6 4h7a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-2"></path>
                  <path d="M14 10h4"></path>
                </svg>
              </button>
            )}
          </div>
        ),
      };
    }),
  };

  const backToParentTicket = async () => {
    let parentNoHistory = sessionStorage.getItem("parentNoHistory");

    if (parentNoHistory) {
      parentNoHistory = JSON.parse(parentNoHistory);
      parentNoHistory.pop();
      sessionStorage.setItem(
        "parentNoHistory",
        JSON.stringify(parentNoHistory)
      );

      if (parentNoHistory.length > 0) {
        const lastParentNo = parentNoHistory[parentNoHistory.length - 1];

        const newParentNo = parentNoHistory[parentNoHistory.length - 1] || null;
        sessionStorage.setItem("parentNo", newParentNo);

        const axiosJWT = axios.create();
        const accessToken1 = localStorage.getItem("token");

        try {
          const response = await axiosJWT.get(
            `${API_URL}/filteredSubTickets/${lastParentNo}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken1}`,
              },
            }
          );

          localStorage.setItem("subTicketList", JSON.stringify(response.data));
          const viewTicketDataString = localStorage.getItem("subTicketList");

          if (viewTicketDataString) {
            const viewTicketData = JSON.parse(viewTicketDataString);
            setSubTicketList(viewTicketData);
          }
          setTimeout(() => {
            handleClose();
          }, 1000);
        } catch (error) {
          console.error(error);
        }
        // setParentNo(ticket_id);
        setCommentFlag(false);
      } else {
        navigate("/ticketReports");
      }
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-header d-print-none ">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            {newTickets && (
              <div className="col">
                <div className="page-pretitle">Overview</div>
                <h2 className="page-title">Sub Tickets/Ticket Reports</h2>
              </div>
            )}
            {!newTickets && (
              <div className="col">
                <h2 className="page-title">
                  Sub Ticket Id / {autoid}/ {parentTicketNo}
                </h2>
              </div>
            )}
            <div className="col-auto ms-auto d-print-none">
              <div className="btn-list">
                {screens !== undefined &&
                  screens.includes("Ticket Create") &&
                  newTickets && (
                    <button
                      className="btn btn-primary d-none d-sm-inline-block"
                      onClick={handleShow}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M12 5l0 14"></path>
                        <path d="M5 12l14 0"></path>
                      </svg>
                      New Ticket
                    </button>
                  )}
                {newTickets && (
                  <button
                    className="btn btn-md btn-primary"
                    onClick={backToParentTicket}
                  >
                    Back
                  </button>
                )}
              </div>
            </div>
            {!newTickets && (
              <div className="col-12">
                <form>
                  <div className="card-body">
                    <div className="row row-cards">
                      {/* <div className="col-md-3">
                        <div className="mb-3">
                          <label className="form-label">Sub Ticket Id</label>
                          <span
                            className="form-text"
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              marginLeft: "3px",
                            }}
                          >
                            {autoid}
                          </span>
                        </div>
                      </div> */}

                      {/* <div className="col-md-3">
                        <div className="mb-3">
                          <label className="form-label">Parent No</label>
                          <span
                            className="form-text"
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              marginLeft: "3px",
                            }}
                          >
                            {parentTicketNo}
                          </span>
                        </div>
                      </div> */}

                      <div className="col-sm-6 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Subject *</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Task *</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Task"
                            value={task}
                            onChange={(e) => setTask(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="mb-3">
                          <label className="form-label">Dead_line *</label>
                          <input
                            type="date"
                            className="form-control"
                            min={getCurrentDate()} // Use the imported function
                            value={dead_line} // Make sure dead_line is in the format YYYY-MM-DD
                            onChange={(e) => setDead_line(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6 col-md-3 d-flex flex-nowrap align-items-center">
                        <div className="mr-3">
                          <label htmlFor="day-select" className="form-label">
                            Days
                          </label>
                          <select
                            id="day-select"
                            className="form-select"
                            aria-label="Select days"
                            value={duration.days}
                            onChange={(e) => handleDateChange(e, "days")}
                          >
                            {Array.from({ length: 31 }, (_, i) => i).map(
                              (num) => (
                                <option key={num} value={num}>
                                  {num}
                                </option>
                              )
                            )}
                          </select>
                        </div>

                        <div className="mr-3">
                          <label htmlFor="hour-select" className="form-label">
                            Hours
                          </label>
                          <select
                            id="hour-select"
                            className="form-select"
                            aria-label="Select hours"
                            value={duration.hours}
                            onChange={(e) => handleDateChange(e, "hours")}
                          >
                            {Array.from({ length: 24 }, (_, i) => i).map(
                              (num) => (
                                <option key={num} value={num}>
                                  {num}
                                </option>
                              )
                            )}
                          </select>
                        </div>

                        <div>
                          <label htmlFor="minute-select" className="form-label">
                            Minutes
                          </label>
                          <select
                            id="minute-select"
                            className="form-select"
                            aria-label="Select minutes"
                            value={duration.minutes}
                            onChange={(e) => handleDateChange(e, "minutes")}
                          >
                            {Array.from({ length: 60 }, (_, i) => i).map(
                              (num) => (
                                <option key={num} value={num}>
                                  {num}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-6 col-md-3">
                        <div className="mb-2">
                          <label className="form-label">Cycle *</label>
                          {!readOnly && (
                            <input
                              type="text"
                              className="form-control"
                              value={cycleValue}
                              readOnly
                            />
                          )}
                          {readOnly && (
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={cycleValue}
                              onChange={(e) =>
                                setCycle({
                                  ...cycle,
                                  cycleValue: e.target.value,
                                })
                              }
                            >
                              <option>Please choose one option</option>
                              {cycleDropDownValues.map((option, index) => {
                                return (
                                  <option key={index}>{option.cycle}</option>
                                );
                              })}
                            </select>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="mb-3">
                          <label className="form-label">Phase *</label>
                          {!readOnly && (
                            <input
                              type="text"
                              className="form-control"
                              value={phaseValue}
                              readOnly
                            />
                          )}
                          {readOnly && (
                            <Select
                              isMulti
                              readOnly
                              options={phaseDropDownValues.map((data) => ({
                                value: data.phase,
                                label: data.phase,
                              }))}
                              // value={phaseValue.map((phase) => ({
                              //   value: phase,
                              //   label: phase,
                              // }))}
                              onChange={(selectedOptions) => {
                                // Extract the values from selected options
                                const selectedPhases = selectedOptions.map(
                                  (option) => option.value
                                );
                                // Update state with selected phases
                                setPhase({
                                  ...phase,
                                  phaseValue: selectedPhases,
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="mb-3">
                          <label className="form-label">Product *</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={productValue}
                            onChange={(e) =>
                              setProduct({
                                ...product,
                                productValue: e.target.value,
                              })
                            }
                          >
                            <option>Please choose one option</option>
                            {productDropDownValues?.map((data, index) => {
                              return (
                                <option key={index}>{data.product}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="mb-2">
                          <label className="form-label">Client *</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={clientValue}
                            onChange={(e) =>
                              setClient({
                                ...client,
                                clientValue: e.target.value,
                              })
                            }
                          >
                            <option>Please choose one option</option>
                            {clientDropDownValues?.map((data, index) => {
                              return <option key={index}>{data.name}</option>;
                            })}
                          </select>
                        </div>
                      </div>

                      {/* <div className="col-sm-6 col-md-3">
                        <div className="mb-3">
                          <label className="form-label">Priority *</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={priorityValue}
                            onChange={(e) =>
                              setPriority({
                                ...priority,
                                priorityValue: e.target.value,
                              })
                            }
                          >
                            <option>Please choose one option</option>
                            {priorityDropDownValues.map((data, index) => {
                              return (
                                <option key={index}>{data.priority}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div> */}

                      <div className="col-sm-6 col-md-3">
                        <div className="mb-3">
                          <label className="form-label">Source *</label>
                          <input
                            type="text"
                            className="form-control"
                            value={sourceValue}
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="col-sm-6 col-md-3">
                        <div className="mb-3">
                          <label className="form-label">Teams *</label>
                          <input
                            type="text"
                            className="form-control"
                            value={teamsValue}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="mb-3">
                          <label className="form-label">Status *</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={statusValue}
                            onChange={(e) =>
                              setStatus({
                                ...status,
                                statusValue: e.target.value,
                              })
                            }
                          >
                            <option>Please choose one option</option>
                            {statusDropDownValues.map((data, index) => {
                              return <option key={index}>{data.status}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-sm-6 col-md-3">
                        <div className="mb-3">
                          <label className="form-label">ToAssign *</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={toAssignValue}
                            onChange={(e) =>
                              setToAssign({
                                ...toAssign,
                                toAssignValue: e.target.value,
                              })
                            }
                          >
                            <option>Please choose one option</option>
                            {toAssignDropDownValues.map((option, index) => {
                              return (
                                <option key={index}>{option.firstname}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div> */}
                      <div className="col-sm-6 col-md-3">
                        <div className="mb-2">
                          <label className="form-label">Assign </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={assignValue}
                            onChange={(e) =>
                              setAssign({
                                ...assign,
                                assignValue: e.target.value,
                              })
                            }
                          >
                            <option>Please choose one option</option>
                            {assignDropDownValues?.map((option, index) => {
                              return (
                                <option key={index}>{option.firstname}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="mb-3">
                          <label className="form-label">Attachment </label>
                          <input
                            type="file"
                            className="form-control"
                            onChange={handleFileChange}
                          />
                        </div>
                      </div>
                      {/* <div className="col-sm-6 col-md-7">
                        <div className="mb-3">
                          {commentFlag && (
                            <label className="form-label">Comment </label>
                          )}
                          {commentFlag &&
                            textAreas.map((text, index) => (
                              <textarea
                                key={index}
                                value={text}
                                onChange={(e) => handleChange(e, index)}
                                placeholder="Enter text"
                                className="form-control comment-textarea" // Added the custom className "comment-textarea"
                              />
                            ))}
                        </div>
                      </div> */}
                      <div className=" d-grid gap-2 d-md-flex justify-content-md-end ">
                        <button
                          className="btn btn-md btn-primary custom-button me-md-2"
                          onClick={backTicket}
                        >
                          Back
                        </button>
                        <button
                          className="btn btn-md btn-primary "
                          onClick={saveTicket}
                        >
                          {btntxt}
                        </button>
                        {/* {!commentFlag && (
                          <button
                            className="btn btn-md btn-primary "
                            onClick={handleComments}
                          >
                            Add Comments
                          </button>
                        )} */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
            <ToastContainer />
          </div>
        </div>
      </div>
      <Modal
        show={textShow}
        onHide={handleTextClose}
        backdrop="static"
        dialogclassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{"Comment Session"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="textarea-container">
            <textarea
              value={textAreas}
              onChange={(e) => setTextAreas(e.target.value)}
              placeholder="Enter new comment"
              className="custom-textarea"
            />
            <button className="addBtn" onClick={createTextArea}>
              Add Comment
            </button>
            <div>
              {oldComment.map((comment, key) => (
                <div key={comment.id}>
                  <textarea
                    className="dbValueTextArea"
                    value={`Created by: ${comment.created_by}\nComments: ${comment.comments}`}
                    readOnly
                  />
                  {role === "Admin" ||
                    (username === comment.created_by && (
                      <button onClick={() => removeTextArea(comment.id)}>
                        Remove
                      </button>
                    ))}
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        className="modal-lg"
        style={{ backgroundColor: "#f0f0f0" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Sub Tickets</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="custom-outer-box">
            <div className="custom-modal-content">
              <div className="row">
                <div className="col-lg-4 ">
                  <div className="user-profile">
                    <div className="user-info bg-primary p-3">
                      <h2 className="text-white">
                        Product: {viewTicket.product}
                      </h2>
                    </div>
                    <div className="list-group">
                      <div className="list-group-item">
                        <i className="far fa-ticket"></i> Ticket Id &nbsp;
                        <span className="highlight-text-blue">
                          {" "}
                          {viewTicket.ticket_id}
                        </span>
                      </div>
                      <div className="list-group-item">
                        <i className="fas fa-history"></i> Created By &nbsp;
                        <span className="text-primary spacing">
                          {viewTicket.created_by}
                        </span>
                      </div>
                      <div className="list-group-item">
                        <i className="fas fa-history"></i> Deadline &nbsp;
                        <span className="text-primary spacing">
                          {viewTicket.dead_line}
                        </span>
                      </div>
                      <div className="list-group-item">
                        <i className="far fa-user"></i> Assign &nbsp;
                        <span className="text-primary spacing">
                          {viewTicket.assign}
                        </span>
                      </div>
                      &nbsp;
                      {checkParentNo.length > 0 && (
                        <button
                          className="custom-btn"
                          onClick={() => subTicket(viewTicket.ticket_id)}
                        >
                          Sub Ticket
                        </button>
                      )}
                      &nbsp;
                      {showcompletedbtn &&
                        statusFromLocalStorage == "completed" &&
                        (username === assinedTicket || role === "Admin") &&
                        completedTicketStatus !== "Completed" &&
                        completedTicketStatus !== "Closed" && (
                          <button
                            // disabled={
                            //   (statusFromLocalStorage === "pending" &&
                            //     username !== assinedTicket) ||
                            //   completedTicketStatus === "Completed" ||
                            //   completedTicketStatus === "Closed"
                            // }
                            className="custom-btn"
                            onClick={() => completed(viewTicket)}
                          >
                            Mark As Completed{" "}
                            {/* {completedTicketStatus !== "Completed"
                              ? "Mark As Completed"
                              : "Completed"} */}
                          </button>
                        )}
                      &nbsp;
                      {role === "Admin" &&
                        completedTicketStatus === "Completed" && (
                          <button
                            className="custom-btn btn-danger"
                            onClick={() =>
                              handleCloseTicket(viewTicket.ticket_id)
                            }
                          >
                            Close Ticket
                          </button>
                        )}
                      &nbsp;
                      {(username === assinedTicket || role === "Admin") &&
                        completedTicketStatus !== "Completed" &&
                        completedTicketStatus !== "Closed" && (
                          <button
                            className="custom-btn btn-danger"
                            // disabled={
                            //   completedTicketStatus === "Completed" ||
                            //   completedTicketStatus === "Closed"
                            // }
                            onClick={() => handleModal(viewTicket.ticket_id)}
                          >
                            Assign Ticket
                          </button>
                        )}
                      &nbsp;
                      <button onClick={handlePriority}>Priority</button>
                      {viewTicket.phase !== "Entrée" && showPriorityModal && (
                        <Priority
                          ticketId={viewTicket.ticket_id}
                          onClose={handleClosePriorityModal}
                        />
                      )}
                      &nbsp;
                      <button
                        className="custom-btn btn-danger"
                        onClick={() => duplicateTicket(viewTicket.ticket_id)}
                      >
                        Duplicate Ticket
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="table-responsive margin-bottom-2x">
                    <table className="table margin-bottom-none">
                      <thead>
                        <tr>
                          <th className="text-center">Created on</th>
                          <th className="text-center">Priority</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Phase</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-center">
                            <span className="text-primary">
                              {moment(viewTicket.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </span>
                          </td>
                          <td className="text-center">
                            <span className="text-info">
                              {viewTicket.priority}
                            </span>
                          </td>
                          <td className="text-center">
                            <span className="text-success">
                              {viewTicket.status}
                            </span>
                          </td>
                          <td className="text-center">
                            <span className="text-warning">
                              {viewTicket.phase}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="ticket-details-container">
                    <div className="ticket-header bg-warning p-3">
                      <h3 className="ticket-id text-white">
                        Ticket ID: {viewTicket.ticket_id}
                      </h3>
                      <h4 className="ticket-id text-white">
                        Parent Ticket ID: {viewTicket.parent_no}
                      </h4>
                      <h4 className="client text-white">
                        Client: {viewTicket.client}
                      </h4>
                      <h4 className="task text-white">
                        Task: {viewTicket.task}
                      </h4>
                    </div>
                    <h2 className="subject text-danger p-3">
                      Subject: {viewTicket.subject}
                    </h2>
                    {viewTicket.phase !== "Entrée" && (
                      <div className="comments-section bg-light p-3 rounded">
                        <h3 className="comments-heading text-info">
                          Comments:
                        </h3>
                        <CommentSection />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            {newTickets && (
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="carhd-title">Sub Tickets List</h3>
                  </div>
                  <div className="container table-responsive">
                    <MDBDataTableV5
                      striped
                      fixed
                      hover
                      entriesOptions={[10, 20, 30, 50]}
                      entries={10}
                      data={datatable}
                      searchTop
                      searchBottom={false}
                    />
                    <ToastContainer />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="col-auto my-custom-left-margin d-print-none">
        <div className="btn-list">
         
        </div>
      </div> */}
      {showBugesModal && (
        <TestingNumberofBuges
          show={showBugesModal}
          onClose={handleCloseModal}
          ticketId={ticketIdToClose}
          onConfirm={handleConfirmClose}
          product={viewTicket.product}
          subject={viewTicket.subject}
        />
      )}
      <Modal show={showModal} onHide={handleShowUsers}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-sm-11 col-md-6">
            <div className="mb-2">
              <label className="form-label">Assign</label>
              <select
                className="form-select"
                aria-label="Default select example"
                value={viewAssignValue}
                onChange={(e) => {
                  setViewAssign({
                    ...viewAssign,
                    viewAssignValue: e.target.value,
                  });
                }}
              >
                <option>Please choose one option</option>
                {viewAssignDropDownValues?.map((option, index) => {
                  return <option key={index}>{option?.firstname}</option>;
                })}
              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              assignTicket(viewTicket.ticket_id);
              handleModal();
            }}
          >
            Assign
          </Button>
        </Modal.Footer>
      </Modal>
      {showCompleteModal && (
        <CompleteTicketModal
          showCompleteModal={showCompleteModal}
          setShowCompleteModal={setShowCompleteModal}
          // handleCompleteTicket={handleCompleteTicket}
          timeTaken={timeTaken}
          ticketId={ticketId}
          ticket={selectedTicket}
          setTimeTaken={setTimeTaken}
          expectedFormat={expectedTime}
        />
      )}
    </div>
  );
}
