import React from "react";
import { useEffect } from "react";
import { getCycle } from "../Lists/ListShow";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { MDBDataTableV5 } from "mdbreact";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../../ApiUrl";

export default function Cycle() {
  useEffect(() => {
    const fetchCycle = async () => {
      const data = await getCycle();
      setCycleList(data);
    };
    fetchCycle();
  }, []);

  const [cycleList, setCycleList] = useState([]);
  const [headtitle, setHeadtitle] = useState("Add New cycle");
  const [btntxt, setButtontxt] = useState("Create New cycle");
  const [primaryid, setPrimaryid] = useState("");
  const [cycle, setCycle] = useState("");
  const [show, setShow] = useState(false);

  const axiosJWT = axios.create();

  const handleEdit = async (id) => {
    handleShow();
    setHeadtitle("Edit cycle");
    setButtontxt("Update cycle");
    const response = await axiosJWT.get(`${API_URL}/cycle/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken1}`,
      },
    });
    setCycle(response.data.cycle);
    setPrimaryid(response.data.id);
  };

  const handleKeyDown = (event) => {
    const keyCode = event.which || event.keyCode;

    if (keyCode === 13) {
      // Enter key is pressed
      event.preventDefault(); // Prevent the default form submission
      savecycle(event); // Pass the event to the function
    }
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this cycle?"
    );
    if (!confirmed) {
      return;
    }
    try {
      await axiosJWT.delete(`${API_URL}/cycle/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      toast.success("cycle Deleted Successfully!", {
        autoClose: 2000,
      });
      const fetchcycle = async () => {
        const data = await getCycle();
        setCycleList(data);
      };
      fetchcycle();
      setTimeout(() => {
        setCycle(cycleList.filter((stat) => stat.id !== id));
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const accessToken1 = localStorage.getItem("token");

  const handleClose = () => {
    toast.dismiss();
    setShow(false);
    setHeadtitle("Add New cycle");
    setButtontxt("Create New cycle");
  };

  const handleShow = () => {
    setShow(true);
    setPrimaryid("");
  };

  const savecycle = async () => {
    if (cycle.trim() === "") {
      toast.error("Please Enter Fields...!", { autoClose: 1000 });
      return;
    }
    try {
      if (primaryid) {
        try {
          const response = await axiosJWT.post(
            `${API_URL}/updatecycle/${primaryid}`,
            {
              cycle,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken1}`,
              },
            }
          );
          toast.success(response.data.msg, { autoClose: 1000 });
          const fetchcycle = async () => {
            const data = await getCycle();
            setCycleList(data);
          };
          fetchcycle();
          setTimeout(() => {
            handleClose();
          }, 1000);
        } catch (error) {
          if (error.response) {
            toast.error(error.response.data.error, { autoClose: 2000 });
          } else {
            toast.error("Error occurred");
          }
        }
      } else {
        try {
          const response = await axiosJWT.post(
            `${API_URL}/createcycle`,
            {
              cycle,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken1}`,
              },
            }
          );
          toast.success(response.data.msg, { autoClose: 1000 });
          setCycle("");
          const fetchcycle = async () => {
            const data = await getCycle();
            setCycleList(data);
          };
          fetchcycle();
          setTimeout(() => {
            handleClose();
          }, 1000);
        } catch (error) {
          if (error.response) {
            toast.error(error.response.data.error, { autoClose: 2000 });
          } else {
            toast.error("Error occurred");
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const datatable = {
    columns: [
      {
        label: "id",
        field: "id",
      },
      {
        label: "cycle",
        field: "cycle",
      },
      {
        label: "Actions",
        field: "actions",
      },
    ],
    rows: cycleList.map((stat, index) => {
      return {
        ...stat,
        id: index + 1,
        actions: (
          <div>
            <div style={{ display: "flex" }}>
              <button
                className="demo-icons-list-item no-bg"
                title="Edit"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                onClick={() => handleEdit(stat.id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="blue"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M8 20l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4h4z" />
                  <path d="M13.5 6.5l4 4" />
                  <path d="M16 18h4m-2 -2v4" />
                </svg>
              </button>
              <button
                className="demo-icons-list-item no-bg"
                title="Delete"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                onClick={() => handleDelete(stat.id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-trash"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="red"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 7l16 0"></path>
                  <path d="M10 11l0 6"></path>
                  <path d="M14 11l0 6"></path>
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                </svg>
              </button>
            </div>
          </div>
        ),
      };
    }),
  };

  return (
    <div className="page-wrapper">
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <div className="page-pretitle">Overview</div>
              <h2 className="page-title">Masters/Cycle</h2>
            </div>
            <div className="col-auto ms-auto d-print-none">
              <div className="btn-list">
                {/* {screenActions.includes("Masters Create") && ( */}
                <button
                  href="#"
                  className="btn btn-primary d-none d-sm-inline-block"
                  onClick={handleShow}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 5l0 14"></path>
                    <path d="M5 12l14 0"></path>
                  </svg>
                  New cycle
                </button>
                {/* )} */}
              </div>
            </div>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              className="modal-xs"
            >
              <Modal.Header closeButton>
                <Modal.Title>{headtitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="col-12">
                  <form className="">
                    <div className="card-body">
                      <div className="row row-cards">
                        <div className="col-sm-6 col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Cycle *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter cycle"
                              value={cycle}
                              onChange={(e) => setCycle(e.target.value)}
                              onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-md btn-primary ms-auto"
                  onClick={savecycle}
                >
                  {btntxt}
                </button>
              </Modal.Footer>
            </Modal>
            <ToastContainer />
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Cycle List</h3>
                </div>
                <div className="container table-responsive mt-3">
                  <MDBDataTableV5
                    striped
                    fixed
                    hover
                    entriesOptions={[10, 20, 30, 50]}
                    entries={10}
                    pagesAmount={4}
                    data={datatable}
                    searchTop
                    searchBottom={false}
                  />
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
