/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getRole } from "./List";
import { ToastContainer, toast } from "react-toastify";
import { MDBDataTableV5 } from "mdbreact";
import { getTaskStatus, getUsers } from "../masters/Lists/ListShow";
import "./Role.css";
import { API_URL } from "../ApiUrl";
import axios from "axios";
import { Modal } from "react-bootstrap";

export default function Role() {
  const [roles, setRoles] = useState([]);
  const [status, setStatus] = useState({
    statusValue: "",
    statusDropDownValues: [],
  });
  const [name, setName] = useState("");
  const [isRole, setIsRole] = useState(false);
  const [btntxt, setButtontxt] = useState("Create New Role");
  const [primaryid, setPrimaryid] = useState("");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const { statusValue } = status;

  const localStorageModules = localStorage.getItem("modules");
  const parsedModules = JSON.parse(localStorageModules);

  let modules = [];
  if (Array.isArray(parsedModules)) {
    modules = parsedModules;
  }

  useEffect(() => {
    const fetchRole = async () => {
      const data = await getRole();
      setRoles(data);
      const statusData = await getTaskStatus();
      setStatus({ ...status, statusDropDownValues: statusData });
      const userData = await getUsers();
      const users = userData.flatMap((user) => {
        if (typeof user.role === "string") {
          // Split the roles into an array
          return user.role.split(",").map((role) => role.trim()); // Trim roles to remove whitespace
        }
        // Handle the case where 'screens' is not a string, e.g., if it's missing or not a string
        return [];
      });
      setUsers(users);
    };
    fetchRole();
  }, [selectedCheckboxes]);

  const accessToken1 = localStorage.getItem("token");
  const axiosJWT = axios.create();

  const handleNewRole = async () => {
    setName("");
    toast.dismiss();
    setPrimaryid("");
    setSelectAll(false);
    setButtontxt("Create Role");
    const statusData = await getTaskStatus();
    setStatus({
      ...status,
      statusDropDownValues: statusData,
    });
    setIsRole(true);
  };

  const handleClose = async () => {
    setIsRole(!isRole);
    setName("");
    setStatus({ ...status, statusValue: "" });
    setSelectedCheckboxes([]);
  };

  const handleCheckboxChange = (checkboxValue) => {
    if (!Array.isArray(selectedCheckboxes)) {
      setSelectedCheckboxes([]);
    }

    const isSelected = selectedCheckboxes.includes(checkboxValue);

    if (isSelected) {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((value) => value !== checkboxValue)
      );
    } else {
      setSelectedCheckboxes(selectedCheckboxes.concat(checkboxValue));
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      // If currently selected, deselect all checkboxes
      setSelectedCheckboxes([]); // Clear the array
    } else {
      // Otherwise, select all available checkboxes
      const allCheckboxValues = modules.map((screen) => screen.name); // Ensure all screen names are included
      setSelectedCheckboxes(allCheckboxValues); // Add all checkbox values
    }
    setSelectAll(!selectAll); // Toggle the "Select All" state
  };

  console.log("Selected Checkboxes:", selectedCheckboxes); // Log the content of `selectedCheckboxes`

  const selectedScreenNames = selectedCheckboxes
    .map((checkbox) => {
      const screen = modules.find((m) => m.name === checkbox); // Match by name
      return screen ? screen.name : ""; // Get the screen name
    })
    .filter((name) => name !== ""); // Remove empty names

  console.log("Selected Screen Names:", selectedScreenNames); // Log the content of `selectedScreenNames`

  const saveRole = async () => {
    try {
      if (name === "" || selectedScreenNames.length === 0) {
        // Validate
        alert("Please fill in all fields and select at least one checkbox.");
        return;
      }

      // Create the `screensString` from `selectedScreenNames`
      const screensString = selectedScreenNames.join(","); // Ensure it's valid
      console.log("Screens String:", screensString); // Log for debugging

      const requestBody = {
        name,
        statusValue,
        screens: screensString,
      };

      console.log("Request Body:", requestBody); // Log the request body to confirm content

      let response;
      if (primaryid) {
        // If there's a `primaryid`, update the existing role
        response = await axiosJWT.post(
          `${API_URL}/updaterole/${primaryid}`,
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${accessToken1}`,
            },
          }
        );
      } else {
        // Otherwise, create a new role
        response = await axiosJWT.post(`${API_URL}/createrole`, requestBody, {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        });
      }

      toast.success(response.data.msg, { autoClose: 1000 });
      setTimeout(() => {
        handleClose();
      }, 1000);

      const fetchRoles = async () => {
        const data = await getRole();
        setRoles(data);
      };
      fetchRoles();
    } catch (error) {
      console.error("Error saving role:", error);
      toast.error("Error saving role. Please try again.");
    }
  };

  const handleEdit = async (id) => {
    setButtontxt("Update Role");
    setIsRole(true); // Open the modal
    setPrimaryid(id);

    try {
      const response = await axiosJWT.get(`${API_URL}/role/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });

      const roleData = response.data;

      setName(roleData.name);
      setStatus({
        ...status,
        statusValue: roleData.status,
      });

      // Extract the screens and ensure proper trimming
      const screensArray = roleData.screens
        .split(",")
        .map((item) => item.trim());

      setSelectedCheckboxes(screensArray); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching role data:", error);
      toast.error("Failed to fetch role data. Please try again.");
    }
  };

  const handleDelete = async (id) => {
    try {
      const roleToDelete = roles.find((role) => role.id === id);

      const roleExistsInUsers = users.some((user) => {
        return user.includes(roleToDelete.name);
      });

      if (roleExistsInUsers) {
        alert(
          "Cannot delete this role because it's assigned to one or more users."
        );
        return;
      }

      const confirmed = window.confirm(
        "Are you sure you want to delete this role?"
      );
      if (!confirmed) {
        return;
      }

      const response = await axiosJWT.delete(`${API_URL}/deleterole/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });

      if (response.data.success) {
        toast.error("Error deleting role. Please try again.", {
          autoClose: 2000,
        });

        setRoles((prevRoles) => prevRoles.filter((role) => role.id !== id));
      } else {
        toast.success("Role Deleted Successfully!");
      }
      setTimeout(() => {
        setRoles(roles.filter((role) => role.id !== id));
      }, 1000);
    } catch (error) {
      console.error(error);
      toast.error("Error deleting role. Please try again.");
    }
  };

  const datatable = {
    columns: [
      {
        label: "id",
        field: "id",
      },
      {
        label: "name",
        field: "name",
      },
      // {
      //     label: 'status',
      //     field: 'status',
      // },
      {
        label: "Actions",
        field: "actions",
      },
    ],
    rows: roles.map((role, index) => {
      // const rowRoles = typeof role.name === 'string' ? role.name.split(',').map(role => role.trim()) : [];
      return {
        ...role,
        id: index + 1,
        actions: (
          <div style={{ display: "flex" }}>
            <button
              className="demo-icons-list-item no-bg"
              title="Edit"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              onClick={() => handleEdit(role.id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="blue"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 20l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4h4z" />
                <path d="M13.5 6.5l4 4" />
                <path d="M16 18h4m-2 -2v4" />
              </svg>
            </button>
            <button
              className="demo-icons-list-item no-bg"
              title="Delete"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              onClick={() => handleDelete(role.id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-trash"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="red"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M4 7l16 0"></path>
                <path d="M10 11l0 6"></path>
                <path d="M14 11l0 6"></path>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
              </svg>
            </button>
          </div>
        ),
      };
    }),
  };
  return (
    <div className="page-wrapper">
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <div className="page-pretitle">Overview</div>
              <h2 className="page-title">Users/Role</h2>
            </div>
            <div className="col-auto ms-auto d-print-none">
              <div className="btn-list">
                <button
                  className="btn btn-primary d-none d-sm-inline-block"
                  onClick={handleNewRole}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 5l0 14"></path>
                    <path d="M5 12l14 0"></path>
                  </svg>
                  New Role
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={isRole}
          onHide={handleClose}
          backdrop="static"
          dialogclassName="custom-modal"
          contentclassName="custom-modal-content"
        >
          <Modal.Header closeButton>
            <Modal.Title>{btntxt}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12">
              <form className="">
                <div className="card-body">
                  <div className="row row-cards">
                    <div className="col-sm-6 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Role Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="ROLE NAME"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                        />
                      </div>
                    </div>
                    {/* <div className="col-sm-6 col-md-6">

                                            <div className="mb-3">
                                                <label className="form-label">Status *</label>
                                                <select className="form-select" aria-label="Default select example" value={status.statusValue} onChange={(e) => setStatus({
                                                    ...status,
                                                    statusValue: e.target.value
                                                })} >

                                                    <option>Please choose one option</option>
                                                    {status.statusDropDownValues.map((data, index) => {
                                                        return <option key={index} >
                                                            {data.status}
                                                        </option>
                                                    })}
                                                </select>
                                            </div>
                                        </div> */}
                    {/* Replace the existing "Screens" section with this code */}
                    <div>
                      {/* <h3>Screens:</h3>
                      <div className="mb-3">
                        <label className="form-label">Home Page</label>
                        <select
                          className="form-select"
                          //   value={defaultModuleId}
                          //   onChange={(e) =>
                          //     handleDefaultModuleChange(e.target.value)
                          //   }
                        >
                          <option value="">Select a Home Page...</option>
                          {modules.map((screen) => {
                            console.log(screen);
                            return (
                              <option
                                key={screen.moduleid}
                                value={screen.moduleid}
                              >
                                {screen.name}
                              </option>
                            );
                          })}
                        </select>
                      </div> */}
                      <h3>Select All</h3>
                      <div className="checkbox-item-horizontal">
                        <input
                          type="checkbox"
                          id="select-all"
                          checked={selectAll}
                          onChange={() => handleSelectAll()}
                        />
                        <label htmlFor="select-all">Select All</label>
                      </div>
                      <div className="checkbox-list">
                        <div className="checkbox-grid mt-3">
                          {modules.map((screen) => (
                            <div
                              key={screen.moduleid}
                              className="checkbox-item"
                            >
                              <input
                                type="checkbox"
                                id={screen.name} // Match `id` with the label's `for` attribute
                                value={screen.name} // Ensure correct checkbox value
                                checked={selectedCheckboxes.includes(
                                  screen.name
                                )} // Ensure proper check state
                                onChange={() =>
                                  handleCheckboxChange(screen.name)
                                } // Handle individual checkbox changes
                              />
                              <label htmlFor={screen.name}>{screen.name}</label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>

          <Modal.Footer>
            {isRole && (
              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button className="btn btn-md btn-primary " onClick={saveRole}>
                  {btntxt}
                </button>
              </div>
            )}
          </Modal.Footer>
        </Modal>
        {!isRole && (
          <div className="page-body">
            <div className="container-xl">
              <div className="row row-cards">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Role List</h3>
                    </div>
                    <div className="container table-responsive mt-3">
                      <MDBDataTableV5
                        striped
                        fixed
                        hover
                        entriesOptions={[10, 20, 30, 50]}
                        entries={10}
                        pagesAmount={4}
                        data={datatable}
                        searchTop
                        searchBottom={false}
                      />
                      <ToastContainer />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
