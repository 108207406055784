/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { MDBDataTableV5 } from "mdbreact";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { API_URL } from "../ApiUrl";
import jwt_decode from "jwt-decode";
import { getCurrentDate } from "../config";
import { getSubTickets, getTickets } from "./Lists/ListShow";
import { Modal } from "react-bootstrap";
import {
  getClients,
  getCycle,
  getPhase,
  getPriority,
  getProduct,
  getSource,
  getTaskStatus,
  getTeam,
  getUsers,
} from "../masters/Lists/ListShow";

export default function Ticket({
  assign,
  setAssign,
  setAssignTicketView,
  priority,
  setPriority,
  product,
  setProduct,
  client,
  setClient,
  toAssign,
  setToAssign,
  status,
  setStatus,
  setTicketId,
  childNo,
  completed_date_time,
  dead_line,
  initial_date_time,
  parentNo,
  phase,
  source,
  subject,
  task,
  teams,
  setChildNo,
  setCompleted_date_time,
  setDead_line,
  setInitial_date_time,
  setParentNo,
  setPhase,
  setSource,
  setSubject,
  setTask,
  setTeams,
  setPrimaryId,
  primaryId,
  ticket_id,
  setSubTicketList,
  subTicketList,
  setCommentArea,
  setViewTicket,
  tickets,
  setTickets,
  assignTicketView,
  setNewTickets,
  newTickets,
  cycle,
  setCycle,
  setDuration,
  duration,
}) {
  const { productValue, productDropDownValues } = product;

  const { statusValue, statusDropDownValues } = status;
  const { teamsValue, teamsDropDownValues } = teams;
  const { sourceValue, sourceDropDownValues } = source;
  const { phaseValue, phaseDropDownValues } = phase;
  const { clientValue, clientDropDownValues } = client;
  const { priorityValue, priorityDropDownValues } = priority;
  const { assignValue, assignDropDownValues } = assign || {};
  const { toAssignValue, toAssignDropDownValues } = toAssign || {};
  const { cycleValue, cycleDropDownValues } = cycle;
  const [textAreas, setTextAreas] = useState([""]);
  const [show, setShow] = useState(false);
  const [comments, setComments] = useState("");
  // const [btnTextComment, setBtnTextComment] = useState('Create');
  const [commentFlag, setCommentFlag] = useState(false);
  const [oldComment, setOldComment] = useState([]);
  //   const [createTicket, setCreateTicket] = useState("");
  const [subAutoid, setSubAutoid] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    const fetchTickets = async () => {
      const data = await getTickets();
      const data1 = await getSubTickets();
      const parentValue = data1.map((element) => element.parent_no);
      setParentNo(parentValue.toString().replace(/,/g, ""));
      const filteredTickets = data.filter((element) => !element.parent_no);
      if (assignTicketView && assignTicketView.length > 0) {
        setTickets(assignTicketView);
      } else {
        setTickets(filteredTickets);
      }
    };
    // const newTicket = localStorage.getItem("createticket");
    // setCreateTicket(newTicket);
    fetchTickets();
    getAutoID();
    handleShow();
  }, []);
  const handleChange = (e, index) => {
    const updatedTextAreas = [...textAreas];
    updatedTextAreas[index] = e.target.value;
    setTextAreas(updatedTextAreas);
  };

  const handleDateChange = (e, field) => {
    const newValue = parseInt(e.target.value, 10);
    setDuration({
      ...duration,
      [field]: newValue,
    });
  };

  const backToHome = () => {
    navigate("/dashboard");
    setAssignTicketView([]);
    setNewTickets(false);
  };

  const removeTextArea = async (id) => {
    const updatedComments = oldComment.filter((comment) => comment.id !== id);
    setOldComment(updatedComments);
    setNewTickets(true);

    try {
      const response = await axiosJWT.delete(`${API_URL}/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });

      if (response.status === 200) {
        console.log("Comment deleted from the database");
      } else {
        console.error("Failed to delete comment from the database");
      }
    } catch (error) {
      console.error("Error occurred while deleting comment:", error);
    }
  };

  const currentDateTime = new Date();
  const username = sessionStorage.getItem("username");
  const createTextArea = async (index) => {
    try {
      const comment = textAreas.toString();
      const commentToPost = comment.trim();

      if (commentToPost !== "") {
        // console.log('Posting comment:', commentToPost);

        await axios.post(
          `${API_URL}/createcomment`,
          {
            ticket_id: autoid,
            created_by: username,
            comments: commentToPost,
            from_date: "",
            to_date: "",
            created_date_time: currentDateTime.toISOString(),
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken1}`,
            },
          }
        );
        toast.success("Comment posted successfully", { autoClose: 1000 });
      }
      setTimeout(() => {
        handleClose();
      }, 1000);
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const handleShow = async () => {
    getAutoID();
    setComments("");
    setCommentFlag(true);
    // setBtnTextComment('Create');
    setReadOnly(true);
    setButtontxt("Create New Ticket");
    setPrimaryId("");
    setNewTickets(!newTickets);
    setChildNo("");
    setCompleted_date_time("");
    setDead_line("");
    setInitial_date_time("");
    setSubject("");
    setTask("");
    setParentNo(ticket_id);
    setTextAreas([""]);
    // setDuration([""]);

    const clientData = await getClients();
    setClient({
      clientValue: "",
      clientDropDownValues: clientData,
    });
    const assignData = await getUsers();
    setAssign({
      assignValue: "",
      assignDropDownValues: assignData,
    });

    setToAssign({
      toAssignValue: "",
      toAssignDropDownValues: assignData,
    });
    const statusData = await getTaskStatus();
    setStatus({
      statusValue: "",
      statusDropDownValues: statusData,
    });
    const sourceData = await getSource();
    setSource({
      sourceValue: "",
      sourceDropDownValues: sourceData,
    });
    const phaseData = await getPhase();
    setPhase({
      phaseValue: "",
      phaseDropDownValues: phaseData,
    });
    const cycleData = await getCycle();
    setCycle({
      cycleValue: "",
      cycleDropDownValues: cycleData,
    });
    const productData = await getProduct();
    setProduct({
      productValue: "",
      productDropDownValues: productData,
    });
    const teamData = await getTeam();
    setTeams({
      teamsValue: "",
      teamsDropDownValues: teamData,
    });
    const priorityData = await getPriority();

    setPriority({
      priorityValue: "",
      priorityDropDownValues: priorityData,
    });
  };

  const [btntxt, setButtontxt] = useState("Create New Ticket");
  const [autoid, setAutoid] = useState("");
  const role = sessionStorage.getItem("role");

  const navigate = useNavigate();
  const axiosJWT = axios.create();

  const getAutoID = async () => {
    try {
      const response = await axiosJWT.get(`${API_URL}/getautoid/ticket`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      setAutoid(response.data.ticket_id);
    } catch (error) {
      if (error) {
        console.log(error.message);
      }
    }
  };

  const accessToken1 = localStorage.getItem("token");
  const tokenPayload = jwt_decode(accessToken1);
  const isTokenExpired = tokenPayload.exp < Date.now() / 1000;

  if (isTokenExpired) {
    localStorage.removeItem("token");
    localStorage.setItem("isLoggedin", false);
    navigate("/");
  }

  const handleFileChange = (event) => {
    setAttachment(event.target.files[0]); // Store the selected file
  };

  const handleDelete = async (ticket_id) => {
    const hasSubTickets = parentNo && parentNo.includes(ticket_id);

    if (hasSubTickets) {
      window.alert("This ticket has sub-tickets and cannot be deleted.");
      return;
    }

    const confirmed = window.confirm(
      "Are you sure you want to delete this ticket?"
    );
    if (!confirmed) {
      return;
    }

    try {
      await axiosJWT.delete(`${API_URL}/tickets/${ticket_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });

      toast.success("Ticket Deleted Successfully!", {
        autoClose: 2000,
      });

      setTickets(tickets.filter((ticket) => ticket.ticket_id !== ticket_id));
    } catch (error) {
      console.log(error);
    }
  };

  const handleComments = async (data) => {
    data.preventDefault();
    setShow(true);
    const response = await axiosJWT.get(`${API_URL}/commentbyid/${comments}`, {
      headers: {
        Authorization: `Bearer ${accessToken1}`,
      },
    });
    setOldComment(response.data);
  };

  const parseDuration = (durationString) => {
    if (!durationString) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
      };
    }

    const daysMatch = durationString.match(/(\d+)d/);
    const hoursMatch = durationString.match(/(\d+)h/);
    const minutesMatch = durationString.match(/(\d+)m/);
    const days = daysMatch ? parseInt(daysMatch[1], 10) : 0;
    const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
    const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;
    return { days, hours, minutes };
  };

  const handleEdit = async (ticket_id) => {
    setNewTickets(false);
    setCommentFlag(false);
    setReadOnly(false);
    setComments(ticket_id);
    setAutoid(ticket_id);
    setButtontxt("Update ticket");
    try {
      const response = await axiosJWT.get(`${API_URL}/tickets/${ticket_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      setAssign({
        ...assign,
        assignValue: response.data.assign,
      });
      setChildNo(response.data.child_no);
      setCompleted_date_time(response.data.completed_date_time);
      setDead_line(response.data.dead_line);
      setInitial_date_time(response.data.initial_date_time);
      setClient({
        ...client,
        clientValue: response.data.client,
      });
      setParentNo(response.data.parentNo);
      setPhase({
        ...phase,
        phaseValue: response.data.phase,
      });
      setPriority({
        ...priority,
        priorityValue: response.data.priority,
      });
      setProduct({
        ...product,
        productValue: response.data.product,
      });
      setSource({
        ...source,
        sourceValue: response.data.source,
      });
      setStatus({
        ...status,
        statusValue: response.data.status,
      });
      setSubject(response.data.subject);
      setTask(response.data.task);
      setTeams({
        ...teams,
        teamsValue: response.data.teams,
      });
      setToAssign({
        ...toAssign,
        toAssignValue: response.data.toAssign,
      });
      setCycle({
        ...cycle,
        cycleValue: response.data.cycle,
      });
      const { days, hours, minutes } = parseDuration(
        response.data.noofdaysexpected
      );

      // Update state with the parsed values
      setDuration({ days, hours, minutes });
      setPrimaryId(response.data.id);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const ticketView = async (ticket_id) => {
    try {
      setTicketId(ticket_id);
      const parentNo = ticket_id;
      localStorage.setItem("showcompletedbtn", true);
      localStorage.setItem("ticket_id", ticket_id);
      const subTicketResponse = await axios.get(
        `${API_URL}/filteredSubTickets/${parentNo}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      const ticketResponse = await axios.get(
        `${API_URL}/tickets/${ticket_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      localStorage.setItem("viewTicket", JSON.stringify(ticketResponse.data));
      navigate("/viewTickets");

      const subTickets = subTicketResponse.data;

      const updateLocalStorageBasedOnStatus = (subTickets) => {
        let hasPendingStatus = false;

        if (subTickets.length === 0) {
          localStorage.setItem("status", "completed");
          return;
        }

        for (let i = 0; i < subTickets.length; i++) {
          const ticketStatus = subTickets[i].status;

          if (ticketStatus !== "Completed" && ticketStatus !== "Closed") {
            hasPendingStatus = true;
            break;
          }
        }

        if (hasPendingStatus) {
          localStorage.setItem("status", "pending");
        } else {
          localStorage.setItem("status", "completed");
        }
      };

      updateLocalStorageBasedOnStatus(subTickets);

      setNewTickets(!newTickets);
      sessionStorage.setItem("buttonFlag", false);
      // setShow(true);

      const commentResponse = await axios.get(
        `${API_URL}/commentbyid/${ticket_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      // if (localStorage.getItem("viewTicket")) {
      //   const viewTicketData = JSON.parse(localStorage.getItem("viewTicket"));
      //   setViewTicket(viewTicketData);
      // }

      // setCommentArea(commentResponse.data);
    } catch (error) {
      console.error("Error in ticketView:", error.message);
    }
  };

  const subTickets = async (ticket_id) => {
    let parentNoHistory = sessionStorage.getItem("parentNoHistory");
    parentNoHistory = parentNoHistory ? JSON.parse(parentNoHistory) : [];
    parentNoHistory.push(ticket_id);
    sessionStorage.setItem("parentNoHistory", JSON.stringify(parentNoHistory));

    sessionStorage.setItem("parentNo", ticket_id);
    const subParentNo = sessionStorage.getItem("parentNo");
    navigate("/subTickets");
    setNewTickets(!newTickets);
    const axiosJWT = axios.create();
    const accessToken1 = localStorage.getItem("token");

    try {
      const responseProductTeams = await axiosJWT.get(
        `${API_URL}/tickets/${ticket_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      localStorage.setItem("product", responseProductTeams.data.product);
      localStorage.setItem("team", responseProductTeams.data.teams);
      const response = await axiosJWT.get(
        `${API_URL}/filteredSubTickets/${subParentNo}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      localStorage.setItem("subTicketList", JSON.stringify(response.data));
      const viewTicketDataString = localStorage.getItem("subTicketList");

      if (viewTicketDataString) {
        const viewTicketData = JSON.parse(viewTicketDataString);
        setSubTicketList(viewTicketData);
      }
    } catch (error) {
      console.error(error);
    }
    setParentNo(ticket_id);
  };

  const screens = sessionStorage.getItem("screens").split(",");

  const statusOrder = [
    "Assigned",
    "Pending",
    "Unassigned",
    "Completed",
    "Closed",
    "Duplicate",
  ];

  const getStatusOrderIndex = (status) => statusOrder.indexOf(status);

  const sortedTickets = [...tickets]
    .filter((ticket) => role === "Admin" || ticket.assign === username)
    .sort((a, b) => {
      const statusComparison =
        getStatusOrderIndex(a.status) - getStatusOrderIndex(b.status);
      if (statusComparison !== 0) return statusComparison;

      const ticketIdA = parseInt(a.ticket_id.slice(1), 10);
      const ticketIdB = parseInt(b.ticket_id.slice(1), 10);
      const ticketIdComparison = ticketIdB - ticketIdA;
      if (ticketIdComparison !== 0) return ticketIdComparison;

      return new Date(b.lastUpdated) - new Date(a.lastUpdated);
    });

  const datatable = {
    columns: [
      {
        label: "id",
        field: "id",
      },
      {
        label: "ticket_id",
        field: "ticket_id",
      },
      {
        label: "client",
        field: "client",
      },
      {
        label: "product",
        field: "product",
      },
      {
        label: "status",
        field: "status",
      },
      {
        label: "subject",
        field: "subject",
      },
      {
        label: "phase",
        field: "phase",
      },
      // {
      //     label: 'task',
      //     field: 'task',
      // },
      {
        label: "assign",
        field: "assign",
      },

      ...(screens.includes("Ticket Edit") ||
      // screens.includes("Ticket Sub") ||
      screens.includes("Ticket View") ||
      screens.includes("Ticket Delete")
        ? [
            {
              label: "Actions",
              field: "actions",
            },
          ]
        : []),
    ],

    rows: sortedTickets.map((ticket, index) => {
      return {
        ...ticket,
        id: index + 1,

        actions: (
          <div style={{ display: "flex" }}>
            {screens !== undefined && screens.includes("Ticket Edit") && (
              <button
                className="demo-icons-list-item no-bg"
                title="Edit"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                onClick={() => handleEdit(ticket.ticket_id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="blue"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M8 20l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4h4z" />
                  <path d="M13.5 6.5l4 4" />
                  <path d="M16 18h4m-2 -2v4" />
                </svg>
              </button>
            )}
            {screens !== undefined && screens.includes("Ticket Delete") && (
              <button
                className="demo-icons-list-item no-bg"
                title="Delete"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                onClick={() => handleDelete(ticket.ticket_id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-trash"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="red"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 7l16 0"></path>
                  <path d="M10 11l0 6"></path>
                  <path d="M14 11l0 6"></path>
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                </svg>
              </button>
            )}
            {screens !== undefined && screens.includes("Ticket View") && (
              <button
                className="demo-icons-list-item no-bg"
                title="View"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                onClick={() => ticketView(ticket.ticket_id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-eye"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                  <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
                </svg>
              </button>
            )}
            {/* {screens !== undefined 
            && screens.includes("Ticket Sub")  */}
            {/* && ( */}
            <button
              className="demo-icons-list-item no-bg"
              title="Sub Ticket"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              onClick={() => subTickets(ticket.ticket_id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-tournament"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M4 4m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                <path d="M20 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                <path d="M4 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                <path d="M4 20m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                <path d="M6 12h3a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-3"></path>
                <path d="M6 4h7a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-2"></path>
                <path d="M14 10h4"></path>
              </svg>
            </button>
            {/* )} */}
          </div>
        ),
      };
    }),
  };

  const backTicket = async (data) => {
    data.preventDefault();
    setNewTickets(true);
    setCommentFlag(false);
  };

  const handleClose = () => {
    setTextAreas([""]);
    setShow(false);
  };

  const getFormattedDuration = () => {
    const { days, hours, minutes } = duration;

    // Ensure that missing values are set to zero
    const safeDays = days || 0;
    const safeHours = hours || 0;
    const safeMinutes = minutes || 0;

    return `${safeDays}d${safeHours}h${safeMinutes}m`; // Constructing the formatted duration
  };

  const saveTicket = async (data, index) => {
    let toAssignValueProjectOwner = "";
    let assignValueProjectOwner = "";
    let isAssign = "";

    data.preventDefault();
    setNewTickets(false);

    const getFutureDate = () => {
      const today = new Date();
      const futureDate = new Date(today.setDate(today.getDate() + 7));
      return futureDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    };

    // Set deadline to one week from now if it's empty
    const deadline = dead_line || getFutureDate();
    setDead_line(deadline);

    if (
      statusValue === "Please choose one option" ||
      statusValue === "" ||
      clientValue === "Please choose one option" ||
      clientValue === "" ||
      subject === "" ||
      task === "" ||
      // toAssignValue === "Please choose one option" ||
      // toAssignValue === "" ||
      phaseValue === "Please choose one option" ||
      phaseValue === "" ||
      // priorityValue === "Please choose one option" ||
      // priorityValue === "" ||
      productValue === "Please choose one option" ||
      productValue === "" ||
      sourceValue === "Please choose one option" ||
      sourceValue === "" ||
      teamsValue === "Please choose one option" ||
      teamsValue === "" ||
      cycleValue === "Please choose one option" ||
      cycleValue === ""
    ) {
      setNewTickets(false);
      toast.error("Please Enter All Fields...!", { autoClose: 1000 });
      return;
    } else {
      setNewTickets(true);
    }

    try {
      const products = await getProduct();

      const selectedProduct = products.find(
        (product) => product.product === productValue
      );
      if (selectedProduct) {
        toAssignValueProjectOwner = selectedProduct.projectowner;
        assignValueProjectOwner = selectedProduct.projectowner;

        isAssign = assignValue || assignValueProjectOwner;
        setAssign(isAssign);
      } else {
        console.log(`Product Owner not found for ${productValue}`);
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
    }

    try {
      const formattedDuration = getFormattedDuration();
      const lowerCasePhaseValue = phaseValue;
      //  const lowerCasePhaseValue = phaseValue.toLowerCase();
      if (primaryId) {
        const response = await axiosJWT.post(
          `${API_URL}/updateticket/${primaryId}`,
          {
            autoid,
            assignValue: isAssign,
            childNo,
            completed_date_time,
            deadline,
            initial_date_time: new Date(),
            clientValue,
            // mark,
            parentNo: "",
            phaseValue: lowerCasePhaseValue,
            priorityValue,
            productValue,
            sourceValue,
            statusValue,
            subject,
            task,
            teamsValue,
            toAssignValue: toAssignValueProjectOwner,
            currentDateTime,
            formattedDuration,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken1}`,
            },
          }
        );
        toast.success(response.data.msg, { autoClose: 1000 });
        {
          statusValue === "Assigned" &&
            (await axiosJWT.post(
              `${API_URL}/createticketmasterlog`,
              {
                assign: isAssign,
                ticket_id: response.data.ticket_id,
                phase: lowerCasePhaseValue,
                noofdaysexpected: formattedDuration,
                assigned_date_time: new Date().toISOString(),
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken1}`,
                },
              }
            ));
        }
        const subParentNo = sessionStorage.getItem("parentNo");
        // const response1 = await axiosJWT.post(
        //   `${API_URL}/updateclientticket/${primaryId}`,
        //   {
        //     clientValue,
        //   },
        //   {
        //     headers: {
        //       Authorization: `Bearer ${accessToken1}`,
        //     },
        //   }
        // );
        // localStorage.setItem("subTicketList", JSON.stringify(response1.data));
        const fetchTickets = async () => {
          const data = await getTickets();
          const filteredTickets = data.filter((element) => !element.parent_no);
          setTickets(filteredTickets);
        };
        fetchTickets();
        setTimeout(() => {
          handleClose();
        }, 1000);
      } else {
        const formattedDuration = getFormattedDuration();

        const formData = new FormData();
        formData.append("autoid", autoid);
        formData.append("assignValue", isAssign);
        formData.append("username", username);
        formData.append("cycleValue", cycleValue);
        formData.append("childNo", childNo);
        formData.append("completed_date_time", completed_date_time);
        formData.append("dead_line", deadline);
        formData.append("initial_date_time", currentDateTime.toISOString());
        formData.append("clientValue", clientValue);
        formData.append("parentNo", "");
        formData.append("phaseValue", lowerCasePhaseValue);
        formData.append("priorityValue", priorityValue);
        formData.append("productValue", productValue);
        formData.append("sourceValue", sourceValue);
        formData.append("statusValue", statusValue);
        formData.append("subject", subject);
        formData.append("task", task);
        formData.append("teamsValue", teamsValue);
        formData.append("toAssignValue", toAssignValueProjectOwner);
        formData.append("formattedDuration", formattedDuration);

        // Append file if it exists
        if (attachment) {
          formData.append("attachment", attachment);
        }

        const response = await axiosJWT.post(
          `${API_URL}/createticket`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken1}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success(response.data.msg, { autoClose: 1000 });
        if (response.data) {
          const parentId = response.data.id;
          getAutoSubID();

          await createSubTicketsBasedOnCycle(parentId, cycleValue);

          const ticketId = response.data.id;
        }

        const comment = textAreas.toString();
        const commentToPost = comment.trim();

        if (commentToPost !== "") {
          // console.log('Posting comment:', commentToPost);

          await axios.post(
            `${API_URL}/createcomment`,
            {
              ticket_id: autoid,
              created_by: username,
              comments: commentToPost,
              from_date: "",
              to_date: "",
              created_date_time: currentDateTime.toISOString(),
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken1}`,
              },
            }
          );
          setTimeout(() => {
            handleClose();
          }, 1000);
        }
        const fetchTickets = async () => {
          const data = await getTickets();
          const filteredTickets = data.filter((element) => !element.parent_no);
          setTickets(filteredTickets);
        };
        fetchTickets();
        getAutoID();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAutoSubID = async () => {
    try {
      const response = await axiosJWT.get(`${API_URL}/getautoid/subticket`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      setSubAutoid(response.data.ticket_id);
    } catch (error) {
      if (error) {
        console.log(error.message);
      }
    }
  };

  const createSubTicketsBasedOnCycle = async (parentId, cycle) => {
    const cycleSteps = cycle.split("-").map((step) => step.trim());

    let toAssignValueProjectOwner = "";
    let assignValueProjectOwner = "";
    let isAssign = "";

    try {
      const products = await getProduct();

      const selectedProduct = products.find(
        (product) => product.product === productValue
      );

      if (selectedProduct) {
        toAssignValueProjectOwner = selectedProduct.projectowner;
        assignValueProjectOwner = selectedProduct.projectowner;
        isAssign = assignValue || assignValueProjectOwner;
      } else {
        console.log(`Product Owner not found for ${productValue}`);
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
    }

    const firstStep = cycleSteps[0].toLowerCase();
    const response = await axiosJWT.get(`${API_URL}/getautoid/subticket`, {
      headers: {
        Authorization: `Bearer ${accessToken1}`,
      },
    });

    setSubAutoid(response.data.ticket_id);
    if (firstStep === "test cases and coding") {
      const formattedDuration = getFormattedDuration();
      const getFutureDate = () => {
        const today = new Date();
        const futureDate = new Date(today.setDate(today.getDate() + 7));
        return futureDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
      };
      const deadline = dead_line || getFutureDate();

      const commonData = {
        autoid: response.data.ticket_id,
        parentNo: parentId,
        childNo,
        username,
        completed_date_time,
        deadline,
        initial_date_time: new Date(),
        clientValue,
        priorityValue,
        productValue,
        sourceValue,
        statusValue,
        subject,
        task,
        teamsValue,
        assignValue: isAssign,
        toAssignValue: toAssignValueProjectOwner,
        cycleValue,
        formattedDuration,
        attachment,
      };

      function incrementLastNumber(input) {
        // Use a regular expression to find the last sequence of digits
        const regex = /(\d+)$/; // This regex matches the last group of digits

        // Match the regular expression against the input string
        const match = input.match(regex);

        if (match) {
          const numberStr = match[1];

          const incrementedNumber = parseInt(numberStr, 10) + 1;

          const incrementedNumberStr = incrementedNumber
            .toString()
            .padStart(numberStr.length, "0");

          const updatedString = input.replace(regex, incrementedNumberStr);

          return updatedString;
        } else {
          throw new Error("No numeric portion found to increment.");
        }
      }

      // Test the function with 'ST001'
      const original = response.data.ticket_id;
      const updated = incrementLastNumber(original);

      await axiosJWT.post(
        `${API_URL}/createsubticket`,
        {
          ...commonData,
          phaseValue: "test cases",
          assignValue: "Anitha",
          statusValue: "Assigned",
          attachment,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      await axiosJWT.post(
        `${API_URL}/createsubticket`,
        {
          ...commonData,
          autoid: updated,
          attachment,
          phaseValue: "coding",
          statusValue: "Unassigned",
          assignValue: "2cqr automation",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );
    } else if (firstStep !== "test cases and coding" && firstStep !== "none") {
      const formattedDuration = getFormattedDuration();
      const getFutureDate = () => {
        const today = new Date();
        const futureDate = new Date(today.setDate(today.getDate() + 7));
        return futureDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
      };

      // Set deadline to one week from now if it's empty
      const deadline = dead_line || getFutureDate();
      const commonData = {
        autoid: response.data.ticket_id,
        parentNo: parentId,
        childNo,
        username,
        completed_date_time,
        deadline,
        initial_date_time: new Date(),
        clientValue,
        priorityValue,
        productValue,
        sourceValue,
        // statusValue,
        subject,
        task,
        teamsValue,
        // assignValue: isAssign,
        formattedDuration,
        toAssignValue: toAssignValueProjectOwner,
        cycleValue,
        attachment,
      };

      await axiosJWT.post(
        `${API_URL}/createsubticket`,
        {
          ...commonData,
          attachment,
          statusValue: "Unassigned",
          assignValue: "2cqr automation",
          phaseValue: firstStep,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );
    }
    // else if (firstStep === "code review") {
    //   const commonData = {
    //     autoid: response.data.ticket_id,
    //     parentNo: parentId,
    //     childNo,
    //     username,
    //     completed_date_time,
    //     dead_line,
    //     initial_date_time,
    //     clientValue,
    //     priorityValue,
    //     productValue,
    //     sourceValue,
    //     statusValue,
    //     subject,
    //     task,
    //     teamsValue,
    //     assignValue,
    //     toAssignValue,
    //     cycleValue: cycle,
    //   };

    //   await axiosJWT.post(
    //     `${API_URL}/createsubticket`,
    //     {
    //       ...commonData,
    //       phaseValue: "Code Review",
    //     },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${accessToken1}`,
    //       },
    //     }
    //   );
    // }
    // else {
    //   const commonData = {
    //     autoid: response.data.ticket_id,
    //     parentNo: parentId,
    //     childNo,
    //     completed_date_time,
    //     dead_line,
    //     initial_date_time,
    //     clientValue,
    //     priorityValue,
    //     productValue,
    //     sourceValue,
    //     statusValue,
    //     subject,
    //     task,
    //     teamsValue,
    //     assignValue,
    //     toAssignValue,
    //     username,
    //     cycleValue: cycle,
    //   };

    //   await axiosJWT.post(
    //     `${API_URL}/createsubticket`,
    //     {
    //       ...commonData,
    //       phaseValue: "Requirement",
    //     },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${accessToken1}`,
    //       },
    //     }
    //   );
    // }
  };
  return (
    <div div className="page-wrapper">
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            {newTickets && (
              <div className="col">
                <div className="page-pretitle mt-1 mb-0">Overview</div>
                <h2 className="page-title mt-1 mb-1">Tickets/New Ticket</h2>
              </div>
            )}
            {!newTickets && (
              <div className="col">
                {/* If newTickets is false, show the Ticket ID */}
                <h2 className="page-title mt-1 mb-1">Ticket Id / {autoid}</h2>
              </div>
            )}
            <div className="col-auto ms-auto d-print-none">
              <div className="btn-list">
                {screens !== undefined &&
                  screens.includes("Ticket Create") &&
                  newTickets && (
                    <button
                      className="btn btn-primary d-none d-sm-inline-block mt-1 mb-1"
                      onClick={handleShow}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M12 5l0 14"></path>
                        <path d="M5 12l14 0"></path>
                      </svg>
                      New Ticket
                    </button>
                  )}
                {newTickets && (
                  <button
                    className="btn btn-md btn-primary me-md-2 mt-1 mb-1"
                    onClick={backToHome}
                  >
                    Back to home
                  </button>
                )}
              </div>
            </div>

            {!newTickets && (
              <div className="col-12">
                <form className="">
                  <div className="card-body">
                    <div className="row row-cards">
                      {/* <div className="col-md-3">
                        <div className="mb-3">
                          <label className="form-label">Ticket Id</label>
                          <span
                            className="form-text"
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              marginLeft: "3px",
                            }}
                          >
                            {autoid}
                          </span>
                        </div>
                      </div> */}
                      {/* <div className="col-sm-6 col-md-3">
                        <div className="mb-3">
                          <label className="form-label">ToAssign *</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={toAssignValue}
                            onChange={(e) =>
                              setToAssign({
                                ...toAssign,
                                toAssignValue: e.target.value,
                              })
                            }
                          >
                            <option>Please choose one option</option>
                            {toAssignDropDownValues.map((option, index) => {
                              return (
                                <option key={index}>{option.firstname}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div> */}
                      <div className="col-sm-6 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Subject *</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Task *</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Task"
                            value={task}
                            onChange={(e) => setTask(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="mb-3">
                          <label className="form-label">Dead_line </label>
                          <input
                            type="date"
                            className="form-control"
                            min={getCurrentDate()} // Use the imported function
                            placeholder="Dead_line"
                            value={dead_line}
                            onChange={(e) => setDead_line(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3 d-flex flex-nowrap align-items-center">
                        <div className="mr-3">
                          <label htmlFor="day-select" className="form-label">
                            Days
                          </label>
                          <select
                            id="day-select"
                            className="form-select"
                            aria-label="Select days"
                            value={duration.days}
                            onChange={(e) => handleDateChange(e, "days")}
                          >
                            {Array.from({ length: 31 }, (_, i) => i).map(
                              (num) => (
                                <option key={num} value={num}>
                                  {num}
                                </option>
                              )
                            )}
                          </select>
                        </div>

                        <div className="mr-3">
                          <label htmlFor="hour-select" className="form-label">
                            Hours
                          </label>
                          <select
                            id="hour-select"
                            className="form-select"
                            aria-label="Select hours"
                            value={duration.hours}
                            onChange={(e) => handleDateChange(e, "hours")}
                          >
                            {Array.from({ length: 24 }, (_, i) => i).map(
                              (num) => (
                                <option key={num} value={num}>
                                  {num}
                                </option>
                              )
                            )}
                          </select>
                        </div>

                        <div>
                          <label htmlFor="minute-select" className="form-label">
                            Minutes
                          </label>
                          <select
                            id="minute-select"
                            className="form-select"
                            aria-label="Select minutes"
                            value={duration.minutes}
                            onChange={(e) => handleDateChange(e, "minutes")}
                          >
                            {Array.from({ length: 60 }, (_, i) => i).map(
                              (num) => (
                                <option key={num} value={num}>
                                  {num}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="mb-2">
                          <label className="form-label">Cycle *</label>
                          {!readOnly && (
                            <input
                              type="text"
                              className="form-control"
                              value={cycleValue}
                              readOnly
                            />
                          )}
                          {readOnly && (
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={cycleValue}
                              onChange={(e) =>
                                setCycle({
                                  ...cycle,
                                  cycleValue: e.target.value,
                                })
                              }
                            >
                              <option>Please choose one option</option>
                              {cycleDropDownValues.map((option, index) => {
                                return (
                                  <option key={index}>{option.cycle}</option>
                                );
                              })}
                            </select>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="mb-2">
                          <label className="form-label">Phase *</label>

                          {!readOnly && (
                            <input
                              type="text"
                              className="form-control"
                              value={phaseValue}
                              readOnly
                            />
                          )}
                          {readOnly && (
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={phaseValue}
                              onChange={(e) =>
                                setPhase({
                                  ...phase,
                                  phaseValue: e.target.value,
                                })
                              }
                            >
                              <option>Please choose one option</option>
                              {phaseDropDownValues.map((data, index) => {
                                return (
                                  <option key={index} value={data.phase}>
                                    {data.phase}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="mb-3">
                          <label className="form-label">Product *</label>
                          {!readOnly && (
                            <input
                              type="text"
                              className="form-control"
                              value={productValue}
                              readOnly
                            />
                          )}
                          {readOnly && (
                            <select
                              type="text"
                              className="form-select"
                              aria-label="Default select example"
                              value={productValue}
                              onChange={(e) =>
                                setProduct({
                                  ...product,
                                  productValue: e.target.value,
                                })
                              }
                            >
                              <option>Please choose one option</option>
                              {productDropDownValues?.map((data, index) => (
                                <option key={index}>{data.product}</option>
                              ))}
                            </select>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="mb-2">
                          <label className="form-label">Client *</label>

                          {!readOnly && (
                            <input
                              type="text"
                              className="form-control"
                              value={clientValue}
                              readOnly
                            />
                          )}
                          {readOnly && (
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={clientValue}
                              onChange={(e) =>
                                setClient({
                                  ...client,
                                  clientValue: e.target.value,
                                })
                              }
                            >
                              <option>Please choose one option</option>
                              {clientDropDownValues.map((data, index) => {
                                return (
                                  <option key={index} value={data.name}>
                                    {data.name}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                        </div>
                      </div>
                      {/* <div className="col-sm-6 col-md-3">
                        <div className="mb-3">
                          <label className="form-label">Priority *</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={priorityValue}
                            onChange={(e) =>
                              setPriority({
                                ...priority,
                                priorityValue: e.target.value,
                              })
                            }
                          >
                            <option>Please choose one option</option>
                            {priorityDropDownValues.map((data, index) => {
                              return (
                                <option key={index}>{data.priority}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div> */}
                      <div className="col-sm-6 col-md-3">
                        <div className="mb-3">
                          <label className="form-label">Source *</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={sourceValue}
                            onChange={(e) =>
                              setSource({
                                ...source,
                                sourceValue: e.target.value,
                              })
                            }
                          >
                            <option>Please choose one option</option>
                            {sourceDropDownValues.map((data, index) => {
                              return <option key={index}>{data.source}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="mb-3">
                          <label className="form-label">Teams *</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={teamsValue}
                            onChange={(e) =>
                              setTeams({
                                ...teams,
                                teamsValue: e.target.value,
                              })
                            }
                          >
                            <option>Please choose one option</option>
                            {teamsDropDownValues.map((data, index) => {
                              return (
                                <option key={index} value={data.team}>
                                  {data.team}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="mb-3">
                          <label className="form-label">Status *</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={statusValue}
                            onChange={(e) =>
                              setStatus({
                                ...status,
                                statusValue: e.target.value,
                              })
                            }
                          >
                            <option>Please choose one option</option>
                            {statusDropDownValues.map((data, index) => {
                              return <option key={index}>{data.status}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="mb-2">
                          <label className="form-label">Assign</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={assignValue}
                            onChange={(e) =>
                              setAssign({
                                ...assign,
                                assignValue: e.target.value,
                              })
                            }
                          >
                            <option>Please choose one option</option>
                            {assignDropDownValues?.map((option, index) => {
                              return (
                                <option key={index}>{option?.firstname}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-6 col-md-3">
                        <div className="mb-3">
                          <label className="form-label">Attachment</label>
                          <input
                            type="file"
                            className="form-control"
                            onChange={handleFileChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className=" d-grid gap-2 d-md-flex justify-content-md-end ">
                  <div className="mb-3" style={{ marginTop: "20px" }}>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      {!newTickets && (
                        <button
                          className="btn btn-md btn-primary me-md-2"
                          onClick={backTicket}
                        >
                          Back
                        </button>
                      )}
                      <button
                        className="btn btn-md btn-primary me-md-2"
                        onClick={saveTicket}
                      >
                        {btntxt}
                      </button>
                      {!commentFlag && (
                        <button
                          className="btn btn-md btn-primary"
                          onClick={handleComments}
                        >
                          Add Comments
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <ToastContainer />
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        dialogclassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{"Comment Session"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="textarea-container">
            <textarea
              value={textAreas}
              onChange={(e) => setTextAreas(e.target.value)}
              placeholder="Enter new comment"
              className="custom-textarea"
            />{" "}
            <button className="addBtn" onClick={createTextArea}>
              Add Comment
            </button>
            <div>
              {oldComment.map((comment, key) => (
                <div key={comment.id}>
                  <textarea
                    className="dbValueTextArea"
                    value={`Created by: ${comment.created_by}\nComments: ${comment.comments}`}
                    readOnly
                  />
                  {(role === "Admin" || username === comment.created_by) && (
                    <button onClick={() => removeTextArea(comment.id)}>
                      Remove
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="page-body">
        <div className="container-xl mt-0 pt-0">
          <div className="row row-cards">
            {newTickets && (
              <div className="col-12 ">
                <div className="card ">
                  <div className="card-header">
                    <h3 className="carhd-title">Tickets List</h3>
                  </div>
                  <div className="container table-responsive">
                    <MDBDataTableV5
                      striped
                      fixed
                      hover
                      entriesOptions={[10, 20, 30, 50]}
                      entries={10}
                      data={datatable}
                      searchTop
                      searchBottom={false}
                    />
                    <ToastContainer />
                  </div>
                </div>
                {/* <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-3">
                  <button
                    className="btn btn-md btn-primary me-md-2"
                    onClick={backToHome}
                  >
                    Back to home
                  </button>
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
