import React, { useEffect, useState } from "react";
import { Button, Alert } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../ApiUrl";
import { getUsers } from "../masters/Lists/ListShow";
import "./EmployeeReports.css"; // Import CSS file for styling

const EmployeeReports = ({ assign, setAssign }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [commentData, setCommentData] = useState([]);
  const [filteredComments, setFilteredComments] = useState([]);
  const { assignValue, assignDropDownValues } = assign || {};

  useEffect(() => {
    const fetchAssignValues = async () => {
      try {
        const assignData = await getUsers();
        setAssign({
          assignValue: "",
          assignDropDownValues: assignData,
        });
      } catch (err) {
        console.error("Error fetching assign values:", err);
        setError("Error fetching assign values. Please try again later.");
      }
    };
    fetchAssignValues();
  }, [setAssign]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchCommentData = async () => {
      try {
        const response = await axios.get(`${API_URL}/comment`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (Array.isArray(response.data)) {
          setCommentData(response.data);
          console.log("Comment API Response:", response.data); // Log to inspect response
        } else {
          throw new Error("Unexpected response format");
        }
      } catch (error) {
        console.error("Error fetching comment data:", error);
      }
    };

    fetchCommentData();
  }, [token]);

  const handleSubmit = async (employeeName) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${API_URL}/assignTicket/${employeeName}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (Array.isArray(response.data)) {
        setData(response.data);
        console.log("API Response:", response.data); // Log to inspect response
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      setError("Error fetching data. Please try again.");
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setAssign((prevAssign) => ({ ...prevAssign, assignValue: "" }));
    setData([]); // Clear data on clear
    setFilteredComments([]); // Clear filtered comments on clear
  };

  const handleEmployeeChange = (e) => {
    const selectedEmployeeName = e.target.value;
    setAssign((prevAssign) => ({
      ...prevAssign,
      assignValue: selectedEmployeeName,
    }));
    if (selectedEmployeeName) {
      handleSubmit(selectedEmployeeName);
      const filtered = commentData.filter(
        (comment) => comment.created_by === selectedEmployeeName
      );
      // Sort comments by created_date_time in descending order
      const sortedComments = filtered.sort(
        (a, b) => new Date(b.created_date_time) - new Date(a.created_date_time)
      );
      setFilteredComments(sortedComments);
    } else {
      setFilteredComments([]);
    }
  };

  const filterDataByStatus = (status) => {
    return data
      .filter(
        (item) =>
          item.status === status && item.phase.toLowerCase() !== "entrée"
      ) // Exclude "entrèe" phase (case-insensitive)
      .map((item) => ({
        ...item,
        formattedDeadline: formatDateTime(item.dead_line),
        formattedUpdatedAt: formatDateTime(item.updated_at),
      }));
  };

  const sortAssignedData = (data) => {
    return data.sort((a, b) => {
      if (a.priority === null && b.priority !== null) {
        return 1;
      }
      if (a.priority !== null && b.priority === null) {
        return -1;
      }
      return (a.priority || 0) - (b.priority || 0);
    });
  };

  const sortPendingData = (data) => {
    return data.sort((a, b) => new Date(a.dead_line) - new Date(b.dead_line)); // Sort by deadline
  };

  const sortCompletedData = (data) => {
    return data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)); // Sort by updated_at
  };

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return "N/A";
    const date = new Date(dateTimeString);
    if (isNaN(date.getTime())) return "Invalid Date";
    const options = { year: "numeric", month: "short", day: "numeric" };
    return `${date.toLocaleDateString(
      undefined,
      options
    )} ${date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`;
  };

  const formatTime = (time) => {
    if (!time) return "";
    const [hours, minutes] = time.split(":").map(Number);
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  return (
    <div className="d-flex justify-content-center mt-4">
      <div className="col-11">
        <div className="card custom-card">
          <div className="row">
            <div className="col-md-4 custom-selector">
              <h2>Employee</h2>
              <select
                className="form-select"
                aria-label="Default select example"
                value={assignValue}
                onChange={handleEmployeeChange}
              >
                <option value="">Please choose one option</option>
                {assignDropDownValues &&
                  assignDropDownValues.map((option) => (
                    <option key={option.id} value={option.firstname}>
                      {option.firstname}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="d-grid gap-2 d-md-flex justify-content-md-end custom_btn mt-3">
            <Button variant="secondary" onClick={handleClear}>
              Clear
            </Button>
          </div>
        </div>
        {error && (
          <Alert variant="danger" className="mt-4">
            {error}
          </Alert>
        )}
        {loading && <p>Loading...</p>}
        <div className="tables-container mt-4">
          <div className="table-wrapper">
            <h3>Comments</h3>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Serial No</th>
                  <th>Product</th>
                  <th>Subject</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {filteredComments.length > 0 ? (
                  filteredComments.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.product}</td>
                      <td>{item.subject}</td>
                      <td>{formatTime(item.from_time)}</td>
                      <td>{formatTime(item.to_time)}</td>
                      <td>{formatDateTime(item.created_date_time)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="table-wrapper mt-4">
            <h3>Assigned</h3>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Serial No</th>
                  <th>Product</th>
                  <th>Subject</th>
                  <th>Phase</th>
                  <th>Deadline</th>
                  <th>Priority</th>
                  {/* Add more columns as needed */}
                </tr>
              </thead>
              <tbody>
                {sortAssignedData(filterDataByStatus("Assigned")).length > 0 ? (
                  sortAssignedData(filterDataByStatus("Assigned")).map(
                    (item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.product}</td>
                        <td>{item.subject}</td>
                        <td>{item.phase}</td>
                        <td>{formatDateTime(item.dead_line)}</td>
                        <td>{item.priority}</td>
                        {/* Add more columns as needed */}
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="6">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="table-wrapper mt-4">
            <h3>Pending</h3>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Serial No</th>
                  <th>Product</th>
                  <th>Subject</th>
                  <th>Phase</th>
                  <th>Deadline</th>
                  <th>Priority</th>
                  {/* Add more columns as needed */}
                </tr>
              </thead>
              <tbody>
                {sortPendingData(filterDataByStatus("Pending")).length > 0 ? (
                  sortPendingData(filterDataByStatus("Pending")).map(
                    (item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.product}</td>
                        <td>{item.subject}</td>
                        <td>{item.phase}</td>
                        <td>{formatDateTime(item.dead_line)}</td>
                        <td>{item.priority}</td>
                        {/* Add more columns as needed */}
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="6">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="table-wrapper mt-4">
            <h3>Completed</h3>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Serial No</th>
                  <th>Product</th>
                  <th>Subject</th>
                  <th>Phase</th>
                  <th>Completed At</th>
                  <th>Priority</th>
                  {/* Add more columns as needed */}
                </tr>
              </thead>
              <tbody>
                {sortCompletedData(filterDataByStatus("Completed")).length >
                0 ? (
                  sortCompletedData(filterDataByStatus("Completed")).map(
                    (item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.product}</td>
                        <td>{item.subject}</td>
                        <td>{item.phase}</td>
                        <td>{formatDateTime(item.updated_at)}</td>
                        <td>{item.priority}</td>
                        {/* Add more columns as needed */}
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="6">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeReports;
