import axios from "axios";
import { API_URL } from "../ApiUrl";

export const getRole = async () => {
    const axiosJWT = axios.create();
    const accessToken1 = localStorage.getItem('token');
    const response = await axiosJWT.get(`${API_URL}/role`, {
        headers: {
            Authorization: `Bearer ${accessToken1}`
        }
    });
    return response.data;
};