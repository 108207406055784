/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import axios from "axios";
import Autosuggest from "react-autosuggest";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../ApiUrl";
import "./SearchBar.css";

const debounce = (func, delay) => {
  let debounceTimer;
  return function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), delay);
  };
};

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("token");

  const onSuggestionsFetchRequested = useCallback(
    debounce(async ({ value }) => {
      if (!value) return;

      try {
        const response = await axios.get(`${API_URL}/search-suggestions`, {
          params: { query: value },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setSuggestions(response.data.suggestions);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    }, 300),
    [accessToken]
  );

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => suggestion.ticket_id;

  const renderSuggestion = (suggestion) => (
    <div className="suggestion-item">
      {suggestion.ticket_id} - {suggestion.client} - {suggestion.product} -{" "}
      {suggestion.phase} -{suggestion.subject}
    </div>
  );

  const handleSearch = async (ticket_id) => {
    try {
      const response = await axios.get(`${API_URL}/tickets/${ticket_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      localStorage.setItem("viewTicket", JSON.stringify(response.data));

      navigate(`/viewTickets?ticket_id=${ticket_id}`);
    } catch (error) {
      console.error("Error fetching ticket details:", error);
    }
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    localStorage.setItem("ticket_id", suggestion.ticket_id);
    handleSearch(suggestion.ticket_id);
  };

  const inputProps = {
    placeholder: "Search by ticket_id, client, product or subject...",
    value: searchTerm,
    onChange: (e, { newValue }) => setSearchTerm(newValue),
    className: "search-box",
    "aria-label": "Search tickets",
    "aria-autocomplete": "list",
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSearch(searchTerm);
      }}
      className="search-form"
    >
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        theme={{
          suggestionsContainer: "suggestions-container",
          suggestionsList: "suggestions-list",
          suggestion: "suggestion-item",
        }}
        onSuggestionSelected={onSuggestionSelected}
      />
    </form>
  );
};

export default SearchBar;
