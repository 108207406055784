/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Table, Spinner, Alert } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../ApiUrl";
import { getUsers } from "../masters/Lists/ListShow";
import * as XLSX from "xlsx"; // Import the xlsx library

const formatDate = (dateTime) => {
  if (!dateTime) return "";
  const date = new Date(dateTime);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const formatTime = (time) => {
  if (!time) return "";
  const [hours, minutes] = time.split(":").map(Number);
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

const CommentReports = ({ assign, setAssign }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [commentsData, setCommentsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const username = sessionStorage.getItem("username");
  const { assignValue, assignDropDownValues } = assign || {};

  useEffect(() => {
    const getAssignValue = async () => {
      try {
        const assignData = await getUsers();

        if (role !== "Admin") {
          setAssign({
            assignValue: username,
            assignDropDownValues: assignData,
          });
        } else {
          setAssign({
            assignValue: "",
            assignDropDownValues: assignData,
          });
        }
      } catch (err) {
        console.error("Error fetching assign values:", err);
      }
    };
    getAssignValue();
  }, [role, username]);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    if (start !== startDate || end !== endDate) {
      setStartDate(start);
      setEndDate(end);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${API_URL}/comment`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const endDateWithTime = endDate
        ? new Date(endDate.setHours(23, 59, 59, 999))
        : null;

      const filtered = response.data.filter((comment) => {
        const createdDateTime = new Date(comment.created_date_time);

        const isWithinDateRange =
          (!startDate || createdDateTime >= startDate) &&
          (!endDateWithTime || createdDateTime <= endDateWithTime);

        const isAssigned =
          role === "Admin"
            ? !assignValue || comment.created_by === assignValue
            : comment.created_by === username;

        return isWithinDateRange && isAssigned;
      });

      // Sort the filtered comments by created_date_time in descending order
      const sortedComments = filtered.sort(
        (a, b) => new Date(b.created_date_time) - new Date(a.created_date_time)
      );

      setCommentsData(sortedComments);
    } catch (error) {
      setError(
        error.response?.data?.message ||
          "An error occurred while fetching comments."
      );
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    setAssign({ ...assign, assignValue: role === "Admin" ? "" : username });
    setCommentsData([]);
  };

  const handleExportToExcel = () => {
    const data = commentsData.map((comment, index) => ({
      "Serial No": index + 1,
      Product: comment.product,
      Subject: comment.subject,
      "Created By": comment.created_by,
      Comments: comment.comments,
      "Created Date": formatDate(comment.created_date_time),
      "Start Time": formatTime(comment.from_time),
      "End Time": formatTime(comment.to_time),
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Comments");
    XLSX.writeFile(workbook, "CommentReports.xlsx");
  };

  return (
    <div className="d-flex justify-content-center mt-4">
      <div className="col-11">
        <div className="card custom-card">
          <div className="row">
            <div className="col-md-6 custom-selector">
              <h2>Date Range</h2>
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                className="form-control border rounded"
                placeholderText="Select Date Range"
                dateFormat="dd-MM-yyyy"
              />
            </div>

            {role === "Admin" && (
              <div className="col-md-4 custom-selector">
                <h2>Comment Created By</h2>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={assignValue}
                  onChange={(e) =>
                    setAssign({
                      ...assign,
                      assignValue: e.target.value,
                    })
                  }
                >
                  <option value="">Select User</option>
                  {assignDropDownValues &&
                    assignDropDownValues.map((option, index) => (
                      <option key={index} value={option.firstname}>
                        {option.firstname}
                      </option>
                    ))}
                </select>
              </div>
            )}
          </div>
          <div className="d-grid gap-2 d-md-flex justify-content-md-end custom_btn mt-5">
            <Button variant="primary" onClick={handleSubmit} disabled={loading}>
              {loading ? (
                <Spinner as="span" animation="border" size="sm" />
              ) : (
                "Submit"
              )}
            </Button>
            <Button variant="secondary" onClick={handleClear}>
              Clear
            </Button>
            <Button variant="success" onClick={handleExportToExcel}>
              Export to Excel
            </Button>
          </div>
        </div>

        {error && (
          <Alert variant="danger" className="mt-4">
            {error}
          </Alert>
        )}

        <div className="table-responsive mt-5">
          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>Serial No</th>
                <th>Product</th>
                <th>Subject</th>
                <th>Created By</th>
                <th>Comments</th>
                <th>Created Date</th>
                {commentsData.some((comment) => comment.from_time) && (
                  <th>Start Time</th>
                )}
                {commentsData.some((comment) => comment.to_time) && (
                  <th>End Time</th>
                )}
              </tr>
            </thead>
            <tbody>
              {commentsData.map((comment, index) => (
                <tr key={comment.id}>
                  <td>{index + 1}</td>
                  <td>{comment.product}</td>
                  <td>{comment.subject}</td>
                  <td>{comment.created_by}</td>
                  <td>{comment.comments}</td>
                  <td>{formatDate(comment.created_date_time)}</td>
                  {comment.from_time && (
                    <td>{formatTime(comment.from_time)}</td>
                  )}
                  {comment.to_time && <td>{formatTime(comment.to_time)}</td>}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default CommentReports;
