import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../ApiUrl";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const Auth = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post(`${API_URL}/login`, {
          email: email,
          password: password,
        });

        // Clear all storage before setting new data
        sessionStorage.clear();
        localStorage.clear();

        const {
          usertype,
          name,
          designation,
          lastlogin,
          profileimg,
          role,
          accessToken,
        } = response.data;

        // Save user data to session storage
        sessionStorage.setItem("usertype", usertype);
        sessionStorage.setItem("username", name);
        sessionStorage.setItem("designation", designation);
        sessionStorage.setItem("lastlogin", lastlogin);
        sessionStorage.setItem("profileimg", profileimg);
        sessionStorage.setItem("role", role);

        // Save authentication data to local storage
        localStorage.setItem("role", role);
        localStorage.setItem("token", accessToken);
        localStorage.setItem("isLoggedin", true);

        toast.success("Login Successfully!");

        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);

        const queryParams = `name=${encodeURIComponent(role)}`;
        const response1 = await axios.get(
          `${API_URL}/rolename/roles?${queryParams}`
        );
        const screens = response1.data.map((value) => value.screens);
        sessionStorage.setItem("screens", screens);
      } catch (error) {
        handleError(error);
      }
    } else {
      toast.error("Please Enter All Fields...!", { autoClose: 1000 });
    }
  };

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const handleError = (error) => {
    if (error.message === "Network Error") {
      toast.error("Network Error. Please Contact Admin", { autoClose: 1000 });
    } else if (error.response) {
      console.log(error);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div
      className="page page-center"
      style={{ paddingTop: "2rem", paddingBottom: "3rem" }}
    >
      <div className="container container-normal">
        <div className="row align-items-center g-4">
          <div className="col-lg">
            <div className="container-tight">
              <div className="text-center mb-4">
                <a href="/" className="navbar-brand navbar-brand-autodark">
                  <img
                    style={{ marginRight: "10%" }}
                    src="./assets/static/logo_trans.png"
                    height="66"
                    alt="Logo"
                  />
                </a>
              </div>
              <div className="card card-md">
                <div className="card-body">
                  <h2 className="h2 text-center mb-4">Login to your account</h2>
                  <form method="POST" onSubmit={Auth}>
                    <div className="mb-3">
                      <label className="form-label">Email address</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="your@email.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="mb-2">
                      <label className="form-label">Password</label>
                      <div className="input-group input-group-flat">
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Your password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="form-footer">
                      <button type="submit" className="btn btn-primary w-100">
                        Signin
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg d-none d-lg-block">
            <img
              src="./assets/static/illustrations/undraw_secure_login_pdn4.svg"
              height="200"
              className="d-block mx-auto"
              alt="Secure Login Illustration"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
