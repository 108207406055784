/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./CommentSection.css";
import { API_URL } from "../ApiUrl";
import "@fortawesome/fontawesome-free/css/all.min.css";

const CommentSection = () => {
  const ticket_id = localStorage.getItem("ticket_id");

  const username = sessionStorage.getItem("username");
  const token = localStorage.getItem("token");
  const [commentArea, setCommentArea] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [fromHour, setFromHour] = useState("00");
  const [fromMinute, setFromMinute] = useState("00");
  const [fromAmPm, setFromAmPm] = useState("AM");
  const [toHour, setToHour] = useState("00");
  const [toMinute, setToMinute] = useState("00");
  const [toAmPm, setToAmPm] = useState("AM");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [product, setProduct] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [currentCommentId, setCurrentCommentId] = useState(null);

  const fetchComments = async () => {
    try {
      const response = await fetch(`${API_URL}/commentbyid/${ticket_id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const comments = await response.json();
        setCommentArea(comments);
      } else {
        setError("Failed to fetch comments");
      }
    } catch (error) {
      setError("Error fetching comments");
    }
  };

  useEffect(() => {
    const fetchTicketData = async () => {
      try {
        const response = await axios.get(`${API_URL}/tickets/${ticket_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSubject(response.data.subject);
        setProduct(response.data.product);
      } catch (error) {
        console.error("Error fetching ticket data:", error);
      }
    };

    fetchComments();
    fetchTicketData();
  }, [ticket_id, token]);

  const handleAddComment = async () => {
    setError("");
    if (
      newComment.trim() &&
      fromHour &&
      fromMinute &&
      toHour &&
      toMinute &&
      fromAmPm &&
      toAmPm
    ) {
      const convertTo24HourFormat = (hour, minute, ampm) => {
        let hours = parseInt(hour, 10);
        if (hour === "12") {
          // "12 AM" should be "00" hours
          hours = ampm === "AM" ? 0 : 12;
        } else if (ampm === "PM" && hours !== 12) {
          hours += 12;
        }
        return `${hours.toString().padStart(2, "0")}:${minute.padStart(
          2,
          "0"
        )}:00`;
      };

      const fromTime = convertTo24HourFormat(fromHour, fromMinute, fromAmPm);
      const toTime = convertTo24HourFormat(toHour, toMinute, toAmPm);

      if (fromTime === toTime) {
        setError("From time and To time cannot be the same.");
        return;
      }

      if (!fromTime || !toTime) {
        setError("From time and To time are mandatory.");
        return;
      }

      const commentData = {
        ticket_id,
        product,
        subject,
        comments: newComment,
        from_time: fromTime,
        to_time: toTime,
        created_by: username,
        created_date_time: new Date().toISOString(),
      };

      try {
        const response = await fetch(`${API_URL}/createcomment`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(commentData),
        });

        let responseData;
        try {
          responseData = await response.text();
          responseData = JSON.parse(responseData);
        } catch (jsonError) {
          throw new Error("Invalid JSON response");
        }

        if (response.ok) {
          fetchComments();
          setCommentArea([...commentArea, responseData]);
          setNewComment("");
          setFromHour("00");
          setFromMinute("00");
          setFromAmPm("AM");
          setToHour("00");
          setToMinute("00");
          setToAmPm("AM");
          setSuccessMessage("Comment added successfully!");
          setTimeout(() => setSuccessMessage(""), 3000);
        } else {
          setError(
            `Failed to add comment: ${responseData.message || "Unknown error"}`
          );
        }
      } catch (error) {
        setError(`Error adding comment: ${error.message}`);
      }
    } else {
      setError("Please fill in all fields correctly.");
    }
  };

  const handleEditComment = async (commentId) => {
    setIsEditing(true);
    setCurrentCommentId(commentId);
    try {
      const response = await fetch(`${API_URL}/commentid/${commentId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const commentToEdit = await response.json();
        setNewComment(commentToEdit.comments);

        // Convert from_time and to_time from 24-hour to 12-hour format
        const {
          hour: fromHour,
          minute: fromMinute,
          ampm: fromAmPm,
        } = convertTo12HourFormat(commentToEdit.from_time);
        const {
          hour: toHour,
          minute: toMinute,
          ampm: toAmPm,
        } = convertTo12HourFormat(commentToEdit.to_time);

        setFromHour(fromHour);
        setFromMinute(fromMinute);
        setFromAmPm(fromAmPm);
        setToHour(toHour);
        setToMinute(toMinute);
        setToAmPm(toAmPm);
      } else {
        console.error("Failed to fetch comment for editing");
      }
    } catch (error) {
      console.error("Error fetching comment for editing:", error);
    }
  };

  const convertTo24HourFormat = (hour, minute, ampm) => {
    let hours = parseInt(hour, 10);
    if (ampm === "PM" && hours !== 12) {
      hours += 12;
    } else if (ampm === "AM" && hours === 12) {
      hours = 0;
    }
    return `${hours.toString().padStart(2, "0")}:${minute.padStart(2, "0")}:00`;
  };

  const convertTo12HourFormat = (time) => {
    const [hour, minute] = time.split(":");
    const hourInt = parseInt(hour, 10);
    const ampm = hourInt >= 12 ? "PM" : "AM";
    const hour12 = hourInt % 12 || 12; // Convert 24-hour to 12-hour format
    return {
      hour: hour12.toString().padStart(2, "0"),
      minute: minute.padStart(2, "0"),
      ampm,
    };
  };

  const handleSaveEdit = async () => {
    if (!currentCommentId) {
      console.error("Current Comment ID is not set.");
      setError("Invalid Comment ID.");
      return;
    }

    if (!newComment.trim()) {
      setError("Comment cannot be empty.");
      return;
    }

    const fromTime = convertTo24HourFormat(fromHour, fromMinute, fromAmPm);
    const toTime = convertTo24HourFormat(toHour, toMinute, toAmPm);

    if (fromTime === toTime) {
      setError("From time and To time cannot be the same.");
      return;
    }

    if (!fromTime || !toTime) {
      setError("From time and To time are mandatory.");
      return;
    }

    const updatedCommentData = {
      comments: newComment,
      from_time: fromTime,
      to_time: toTime,
      created_by: username,
      created_date_time: new Date().toISOString(),
    };

    try {
      const response = await fetch(
        `${API_URL}/updatecomment/${currentCommentId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedCommentData),
        }
      );

      if (response.ok) {
        fetchComments(); // Refresh comments list
        setIsEditing(false);
        setCurrentCommentId(null);
        setNewComment("");
        setFromHour("00");
        setFromMinute("00");
        setFromAmPm("AM");
        setToHour("00");
        setToMinute("00");
        setToAmPm("AM");
        setSuccessMessage("Comment updated successfully!");
        setTimeout(() => setSuccessMessage(""), 3000);
      } else {
        const responseData = await response.json();
        setError(
          `Failed to update comment: ${responseData.error || "Unknown error"}`
        );
      }
    } catch (error) {
      setError(`Error updating comment: ${error.message}`);
    }
  };

  const renderTimeOptions = (type) => {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      const value = i === 12 ? "12" : i.toString().padStart(2, "0");
      options.push(value);
    }
    return options.map((value) => (
      <option key={`${type}-${value}`} value={value}>
        {value}
      </option>
    ));
  };

  const renderMinuteOptions = () => {
    const options = [];
    for (let i = 0; i < 60; i += 1) {
      const value = i < 10 ? `0${i}` : i.toString();
      options.push(
        <option key={`minute-${value}`} value={value}>
          {value}
        </option>
      );
    }
    return options;
  };

  return (
    <div className="comment-section">
      {error && <p className="error">{error}</p>}
      {successMessage && <p className="success">{successMessage}</p>}
      <ul className="comments-list">
        {commentArea.map((comment, index) => {
          const commentDate = new Date(comment.created_date_time);
          const currentDate = new Date();
          const isCurrentDate =
            commentDate.toDateString() === currentDate.toDateString();
          return (
            <li key={index} className="comment bg-light">
              <p className="comment-text" style={{ fontWeight: "bold" }}>
                {comment.comments}
              </p>
              {isCurrentDate && (
                <button
                  onClick={() => handleEditComment(comment.id)}
                  className="btn btn-secondary btn-sm"
                  style={{
                    border: "none",
                    background: "none",
                  }}
                >
                  <i className="fas fa-edit"></i>
                </button>
              )}
            </li>
          );
        })}
      </ul>
      <div className="new-comment row align-items-end">
        <div>
          <div className="row mt-2">
            <div className="col">
              <select
                value={fromHour}
                onChange={(e) => setFromHour(e.target.value)}
                className="form-control"
              >
                {renderTimeOptions("hour")}
              </select>
            </div>
            <div className="col">
              <select
                value={fromMinute}
                onChange={(e) => setFromMinute(e.target.value)}
                className="form-control"
              >
                {renderMinuteOptions()}
              </select>
            </div>
            <div className="col">
              <select
                value={fromAmPm}
                onChange={(e) => setFromAmPm(e.target.value)}
                className="form-control"
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <select
                value={toHour}
                onChange={(e) => setToHour(e.target.value)}
                className="form-control"
              >
                {renderTimeOptions("hour")}
              </select>
            </div>
            <div className="col">
              <select
                value={toMinute}
                onChange={(e) => setToMinute(e.target.value)}
                className="form-control"
              >
                {renderMinuteOptions()}
              </select>
            </div>
            <div className="col">
              <select
                value={toAmPm}
                onChange={(e) => setToAmPm(e.target.value)}
                className="form-control"
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
          </div>
          <div className="textarea-wrapper">
            <textarea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Enter your comment"
              className="form-control comment-input mb-3 bold-textarea"
              rows="3"
            />
          </div>
        </div>
        <div className="col-md-4 d-flex justify-content-end mt-2 mt-md-0">
          <button
            onClick={isEditing ? handleSaveEdit : handleAddComment}
            className="btn btn-primary"
          >
            {isEditing ? "Save Edit" : "Add Comment"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CommentSection;
