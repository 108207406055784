import { API_URL } from "../../ApiUrl";
import axios from "axios";

export const getTickets = async () => {
  const axiosJWT = axios.create();
  const accessToken1 = localStorage.getItem("token");
  const response = await axiosJWT.get(`${API_URL}/tickets`, {
    headers: {
      Authorization: `Bearer ${accessToken1}`,
    },
  });
  return response.data;
};

export const getSubTickets = async () => {
  const axiosJWT = axios.create();
  const accessToken1 = localStorage.getItem("token");
  const response = await axiosJWT.get(`${API_URL}/subticket`, {
    headers: {
      Authorization: `Bearer ${accessToken1}`,
    },
  });

  return response.data;
};
