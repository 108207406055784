import React, { useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../ApiUrl";
import $ from "jquery";

import SearchBar from "../searchbar/SearchBar";

export default function Navbar({ setNewTickets, newTickets }) {
  // const [token, setToken] = useState('');
  // const [expire, setExpire] = useState('');
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [activeTickets, setActiveTickets] = useState(null);
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  useEffect(() => {
    refreshToken();
  });

  const location = useLocation();

  const handleNavigationChange = () => {
    if (
      location.pathname !== "/ticketReports" &&
      location.pathname !== "/subTickets"
    ) {
      sessionStorage.removeItem("parentNoHistory");
    }
  };

  useEffect(() => {
    handleNavigationChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // console.log(token);
  // console.log(expire);

  const usertype = sessionStorage.getItem("usertype");
  const username = sessionStorage.getItem("username");
  const designation = sessionStorage.getItem("designation");
  const userlastlogin = sessionStorage.getItem("lastlogin");
  const profileimg = sessionStorage.getItem("profileimg");
  const screens = sessionStorage.getItem("screens");
  const screenActions = screens ? screens.split(",") : [];
  const allowedScreens = [];

  screenActions.forEach((action) => {
    const [screen] = action.split(" ");
    if (screen) {
      allowedScreens.push(screen);
    }
  });

  let formattedDateTime = "";

  if (userlastlogin) {
    const parsedDate = new Date(userlastlogin);

    if (!isNaN(parsedDate.getTime())) {
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      };

      formattedDateTime = new Intl.DateTimeFormat("en-GB", options)
        .format(parsedDate)
        .replace(",", "");
    }
  }

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/token`);
      // setToken(response.data.accessToken);
      localStorage.setItem("token", response.data.accessToken);
      jwt_decode(response.data.accessToken);
      // setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        navigate("/");
      }
    }
  };

  const Logout = async () => {
    try {
      await axios.delete(`${API_URL}/logout`);
      localStorage.removeItem("token");
      sessionStorage.removeItem("username");
      localStorage.setItem("isLoggedin", false);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const logdinstatus = localStorage.getItem("isLoggedin");
  if (logdinstatus === "false") {
    window.location.href = "/";
  }

  const Changeactive = () => {
    $(".master_menu").removeClass("show");
    $(this).closest("li").attr("class");
  };

  const handleTicketReports = () => {
    setActiveTickets("ticketReports");
    setNewTickets(false);
    Changeactive();
  };

  return (
    <>
      <header className="navbar navbar-expand-md navbar-light d-print-none">
        <div className="container-xl">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-menu"
            aria-controls="navbar-menu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
            <img
              src="./assets/static/logo_trans.png"
              width="140"
              height="52"
              alt="2CQR"
            />
          </h1>

          <SearchBar />

          <div className="navbar-nav flex-row order-md-last">
            <div className="d-none d-md-flex">
              <a
                href="?theme=light"
                className="nav-link px-0 hide-theme-light"
                title="Enable light mode"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                  <path d="M15 15l3.35 3.35" />
                  <path d="M9 15l-3.35 3.35" />
                  <path d="M5.65 5.65l3.35 3.35" />
                  <path d="M18.35 5.65l-3.35 3.35" />
                </svg>
              </a>
            </div>
            <div className="d-none d-md-flex">
              <p className="d-md-flex last_login">
                <b>Last Login: {formattedDateTime}</b>
              </p>
            </div>
            <div className="nav-item dropdown">
              <a
                href="/"
                className="nav-link d-flex lh-1 text-reset p-0"
                data-bs-toggle="dropdown"
                aria-label="Open user menu"
              >
                <span
                  className="avatar avatar-sm"
                  style={{
                    backgroundImage: profileimg
                      ? `url(${API_URL}${profileimg})`
                      : `url("./default_avatar.png")`,
                  }}
                ></span>
                <div className="d-none d-xl-block ps-2">
                  <div>Welcome, {username}</div>
                  {usertype === "Admin" ? (
                    <div className="mt-1 small text-muted">{usertype}</div>
                  ) : (
                    <div className="mt-1 small text-muted">{designation}</div>
                  )}
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <a href="./profile.html" className="dropdown-item">
                  Profile
                </a>
                <div className="dropdown-divider"></div>
                <Link onClick={Logout} className="dropdown-item">
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
      <header className="navbar-expand-md">
        <div className="collapse navbar-collapse" id="navbar-menu">
          <div className="navbar navbar-light">
            <div className="container-xl">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/dashboard">
                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
                        <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                        <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                      </svg>
                    </span>
                    <span className="nav-link-title">Home</span>
                  </Link>
                </li>
                {allowedScreens.includes("Masters") && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      role="button"
                      aria-expanded="false"
                    >
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 3l8 4.5l0 9l-8 4.5l-8 -4.5l0 -9l8 -4.5" />
                          <path d="M12 12l8 -4.5" />
                          <path d="M12 12l0 9" />
                          <path d="M12 12l-8 -4.5" />
                          <path d="M16 5.25l-8 4.5" />
                        </svg>
                      </span>
                      <span className="nav-link-title">Masters</span>
                    </Link>
                    <div className="dropdown-menu master_menu">
                      <div className="dropdown-menu-columns">
                        <div className="dropdown-menu-column">
                          <Link
                            className={`dropdown-item ${
                              activeMenuItem === "client" ? "active" : ""
                            }`}
                            onClick={() => {
                              setActiveMenuItem("client");
                              Changeactive();
                            }}
                            to="/client"
                          >
                            Client
                          </Link>
                          <Link
                            className={`dropdown-item ${
                              activeMenuItem === "taskstatus" ? "active" : ""
                            }`}
                            onClick={() => {
                              setActiveMenuItem("taskstatus");
                              Changeactive();
                            }}
                            to="/taskstatus"
                          >
                            Task Status
                          </Link>
                          <Link
                            className={`dropdown-item ${
                              activeMenuItem === "phase" ? "active" : ""
                            }`}
                            onClick={() => {
                              setActiveMenuItem("phase");
                              Changeactive();
                            }}
                            to="/phase"
                          >
                            Phase
                          </Link>
                          <Link
                            className={`dropdown-item ${
                              activeMenuItem === "product" ? "active" : ""
                            }`}
                            onClick={() => {
                              setActiveMenuItem("product");
                              Changeactive();
                            }}
                            to="/product"
                          >
                            Product
                          </Link>
                          <Link
                            className={`dropdown-item ${
                              activeMenuItem === "team" ? "active" : ""
                            }`}
                            onClick={() => {
                              setActiveMenuItem("team");
                              Changeactive();
                            }}
                            to="/team"
                          >
                            Team
                          </Link>
                          <Link
                            className={`dropdown-item ${
                              activeMenuItem === "source" ? "active" : ""
                            }`}
                            onClick={() => {
                              setActiveMenuItem("source");
                              Changeactive();
                            }}
                            to="/source"
                          >
                            Source
                          </Link>
                          <Link
                            className={`dropdown-item ${
                              activeMenuItem === "priority" ? "active" : ""
                            }`}
                            onClick={() => {
                              setActiveMenuItem("priority");
                              Changeactive();
                            }}
                            to="/priority"
                          >
                            Priority
                          </Link>
                          <Link
                            className={`dropdown-item ${
                              activeMenuItem === "cycle" ? "active" : ""
                            }`}
                            onClick={() => {
                              setActiveMenuItem("cycle");
                              Changeactive();
                            }}
                            to="/cycle"
                          >
                            Cycle
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                )}
                {allowedScreens.includes("Ticket") && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      role="button"
                      aria-expanded="false"
                    >
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M9 11l3 3l8 -8" />
                          <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" />
                        </svg>
                      </span>
                      <span className="nav-link-title">Tickets</span>
                    </Link>
                    <div className="dropdown-menu master_menu">
                      <div className="dropdown-menu-columns">
                        <div className="dropdown-menu-column">
                          <Link
                            className={`dropdown-item ${
                              activeTickets === "ticketReports" ? "active" : ""
                            }`}
                            onClick={handleTicketReports}
                            to="/ticketReports"
                          >
                            Ticket List
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                )}

                {allowedScreens.some((screen) =>
                  [
                    "Reports",
                    "Comment",
                    "Employee",
                    "Product",
                    "Ticket",
                  ].includes(screen)
                ) && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      role="button"
                      aria-expanded="false"
                    >
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M12 3v9h9"></path>
                          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                        </svg>
                      </span>
                      <span className="nav-link-title">Reports</span>
                    </Link>
                    <div className="dropdown-menu master_menu">
                      <div className="dropdown-menu-columns">
                        <div className="dropdown-menu-column">
                          {screens !== undefined &&
                            screens.includes("Ticket Reports") && (
                              <Link
                                className={`dropdown-item ${
                                  activeTickets === "reports" ? "active" : ""
                                }`}
                                onClick={handleTicketReports}
                                to="/reports"
                              >
                                Ticket Reports
                              </Link>
                            )}
                          {screens !== undefined &&
                            screens.includes("Comment Reports") && (
                              <Link
                                className={`dropdown-item ${
                                  activeTickets === "commentreports"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={handleTicketReports}
                                to="/commentreports"
                              >
                                Comment Reports
                              </Link>
                            )}
                          {screens !== undefined &&
                            screens.includes("Product Reports") && (
                              <Link
                                className={`dropdown-item ${
                                  activeTickets === "productreports"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={handleTicketReports}
                                to="/productreports"
                              >
                                Product Reports
                              </Link>
                            )}
                          {screens !== undefined &&
                            screens.includes("Employee Reports") && (
                              <Link
                                className={`dropdown-item ${
                                  activeTickets === "employeereports"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={handleTicketReports}
                                to="/employeereports"
                              >
                                Employee Reports
                              </Link>
                            )}
                          {screens !== undefined &&
                            screens.includes("Product Bugs Reports") && (
                              <Link
                                className={`dropdown-item ${
                                  activeTickets === "bugsreports"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={handleTicketReports}
                                to="/bugsreports"
                              >
                                Product Bugs Reports
                              </Link>
                            )}
                          {/* {screens !== undefined &&
                            screens.includes("Status Reports") && ( */}
                          <Link
                            className={`dropdown-item ${
                              activeTickets === "statusreports" ? "active" : ""
                            }`}
                            onClick={handleTicketReports}
                            to="/statusreports"
                          >
                            Status Reports
                          </Link>
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                  </li>
                )}
                {allowedScreens.includes("Users") && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      role="button"
                      aria-expanded="false"
                    >
                      <span className="nav-link-title">
                        <i className="ti ti-users"></i> Users
                      </span>
                    </Link>
                    <div className="dropdown-menu master_menu">
                      <div className="dropdown-menu-columns">
                        <div className="dropdown-menu-column">
                          {screens !== undefined &&
                            screens.includes("Users New") && (
                              <Link
                                className={`dropdown-item ${
                                  activeMenuItem === "client" ? "active" : ""
                                }`}
                                onClick={() => {
                                  setActiveMenuItem("client");
                                  Changeactive();
                                }}
                                to="/users"
                              >
                                {role === "Admin" ? "New Users" : "Edit Users"}
                              </Link>
                            )}
                          {screens !== undefined &&
                            screens.includes("Users Role") && (
                              <Link
                                className={`dropdown-item ${
                                  activeTickets === "role" ? "active" : ""
                                }`}
                                onClick={() => {
                                  setActiveTickets("role");
                                  Changeactive();
                                }}
                                to="/role"
                              >
                                Role
                              </Link>
                            )}
                          {screens.includes("Modules") && (
                            <Link
                              className={`dropdown-item ${
                                activeTickets === "module" ? "active" : ""
                              }`}
                              onClick={() => {
                                setActiveTickets("module");
                                Changeactive();
                              }}
                              to="/modules"
                            >
                              Module
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                )}
                {allowedScreens.includes("Bulk") && (
                  <li className="nav-item">
                    <Link className="nav-link" to="/excel">
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <svg class="svg-icon" viewBox="0 0 20 20">
                          <path d="M15.475,6.692l-4.084-4.083C11.32,2.538,11.223,2.5,11.125,2.5h-6c-0.413,0-0.75,0.337-0.75,0.75v13.5c0,0.412,0.337,0.75,0.75,0.75h9.75c0.412,0,0.75-0.338,0.75-0.75V6.94C15.609,6.839,15.554,6.771,15.475,6.692 M11.5,3.779l2.843,2.846H11.5V3.779z M14.875,16.75h-9.75V3.25h5.625V7c0,0.206,0.168,0.375,0.375,0.375h3.75V16.75z"></path>
                        </svg>
                      </span>
                      <span className="nav-link-title">Bulk Excel Upload</span>
                    </Link>
                  </li>
                )}
                {allowedScreens.includes("Weekly" || "Review") && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      role="button"
                      aria-expanded="false"
                    >
                      <i className="fas fa-calendar-week me-2"></i>
                      <span className="nav-link-title">Weekly Approval</span>
                    </Link>
                    <div className="dropdown-menu master_menu">
                      <div className="dropdown-menu-columns">
                        <div className="dropdown-menu-column">
                          {screens !== undefined &&
                            role === "Admin" &&
                            screens.includes("Review Form") && (
                              <Link
                                className={`dropdown-item ${
                                  activeTickets === "reviewform" ? "active" : ""
                                }`}
                                onClick={handleTicketReports}
                                to="/reviewform"
                              >
                                <i className="fas fa-file-alt me-2"></i>
                                Review Form
                              </Link>
                            )}
                          {screens !== undefined &&
                            role === "Admin" &&
                            screens.includes("Weekly Reports") && (
                              <Link
                                className={`dropdown-item ${
                                  activeTickets === "weeklyreports"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={handleTicketReports}
                                to="/weeklyreports"
                              >
                                <i className="fas fa-calendar-check me-2"></i>
                                Weekly Reports
                              </Link>
                            )}
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
