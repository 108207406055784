/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./viewTicket.css";
import { ToastContainer, toast } from "react-toastify";
import { API_URL } from "../ApiUrl";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import MOMENT from "moment-timezone";
import "./viewTicket.css";
import moment from "moment";
import { getUsers } from "../masters/Lists/ListShow";
import CompleteTicketModal from "./TimeTakenToComplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { getSubTickets } from "./Lists/ListShow";
import CommentSection from "./CommentSection";
import Priority from "./Priority";

export default function ViewTicket({
  setViewTicket,
  setSubTicketList,
  ticket_id,
  setParentNo,
  currentDateTime,
  setCurrentDateTime,
  viewTicket,
  toAssign,
}) {
  const axiosJWT = axios.create();
  const navigate = useNavigate();
  let datetime = MOMENT().tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");

  const [timeTaken, setTimeTaken] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [expectedTime, setExpectedTime] = useState("");
  setCurrentDateTime((currentDateTime = datetime));
  const role = sessionStorage.getItem("role");
  const [showModal, setShowModal] = useState(false);
  const [viewAssign, setViewAssign] = useState({
    viewAssignValue: "",
    viewAssignDropDownValues: [],
  });
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [textAreas, setTextAreas] = useState([""]);
  const [show, setShow] = useState(false);
  const [autoid, setAutoid] = useState("");
  const [statusFromLocalStorage, setStatusFromLocalStorage] = useState("");

  const { toAssignValue, toAssignDropDownValues } = toAssign;

  const [showPriorityModal, setShowPriorityModal] = useState(false);
  const [checkParentNo, setCheckParentNo] = useState("");
  const { viewAssignValue, viewAssignDropDownValues } = viewAssign || {};
  const showcompletedbtn = localStorage.getItem("showcompletedbtn");
  useEffect(() => {
    ticketView();
    handleShow();
    getAutoID();
  }, []);
  const localStorage_ticket_id = localStorage.getItem("ticket_id");

  useEffect(() => {
    const status = localStorage.getItem("status");
    setStatusFromLocalStorage(status);
    const checkParentNo = async () => {
      const subTicketResponse = await axios.get(
        `${API_URL}/filteredSubTickets/${localStorage_ticket_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );
      setCheckParentNo(subTicketResponse.data);
      return subTicketResponse;
    };

    checkParentNo();
  }, []);
  const handleShow = async () => {
    const assignData = await getUsers();
    setViewAssign({
      viewAssignValue: "",
      viewAssignDropDownValues: assignData,
    });
    setShowModal(false);
  };

  const getAutoID = async () => {
    try {
      const response = await axiosJWT.get(`${API_URL}/getautoid/subticket`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      setAutoid(response.data.ticket_id);
    } catch (error) {
      if (error) {
        console.log(error.message);
      }
    }
  };

  const ticketView = async () => {
    const viewTicketDataString = localStorage.getItem("viewTicket");
    if (viewTicketDataString) {
      const viewTicketData = JSON.parse(viewTicketDataString);
      setViewTicket(viewTicketData);
    }
  };

  const handleOpenCompleteModal = () => {
    setShowCompleteModal(true);
  };

  // const handleCompleteTicket = async () => {
  //   try {
  //     await axios.post(
  //       `${API_URL}/tickets/${ticket_id}`,
  //       {
  //         status: "Completed",
  //         completed_date_time: new Date().toISOString(),
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       }
  //     );

  //     // navigate("/dashboard");
  //   } catch (err) {
  //     console.error(err);
  //   }
  //   handleCloseCompleteModal();
  // };

  const handleClose = () => {
    setTextAreas([""]);
    setShow(false);
  };

  const createTextArea = async (index) => {
    try {
      const comment = textAreas.toString();
      const commentToPost = comment.trim();

      if (commentToPost !== "") {
        // console.log('Posting comment:', commentToPost);

        await axios.post(
          `${API_URL}/createcomment`,
          {
            ticket_id: autoid,
            created_by: username,
            comments: commentToPost,
            from_date: "",
            to_date: "",
            created_date_time: currentDateTime.toISOString(),
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken1}`,
            },
          }
        );
        toast.success("Comment posted successfully", { autoClose: 1000 });
      }
      setTimeout(() => {
        handleClose();
      }, 1000);
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const duplicateTicket = async (ticket_id) => {
    await axiosJWT.post(
      `${API_URL}/tickets/${ticket_id}`,
      {
        status: "Duplicate",
        // completed_date_time: new Date().toISOString(),
      },
      {
        headers: { Authorization: `Bearer ${accessToken1}` },
      }
    );

    toast.success("Ticket status updated to Duplicate", { autoClose: 1000 });
    navigate("/dashboard");
    setTimeout(() => {
      handleClose();
    }, 1000);
  };

  const closeTicket = async (ticket_id) => {
    try {
      // Function to update the status of a ticket
      const updateTicketStatus = async (ticketId) => {
        try {
          const response = await axiosJWT.post(
            `${API_URL}/tickets/${ticketId}`,
            {
              status: "Closed",
              completed_date_time: new Date().toISOString(),
            },
            {
              headers: { Authorization: `Bearer ${accessToken1}` },
            }
          );

          return ticketId;
        } catch (error) {
          console.error(`Error updating ticket ${ticketId}:`, error.message);
          throw error; // Throw error to stop processing if needed
        }
      };

      // Function to fetch and process sub-tickets
      const processSubTickets = async (parentTicketId) => {
        try {
          // Fetch sub-tickets using the parent ticket_id
          const response = await axios.get(
            `${API_URL}/filteredSubTickets/${parentTicketId}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken1}`,
              },
            }
          );

          // Extract sub-tickets
          const subTickets = response.data;

          // Log and update each sub-ticket
          for (const subTicket of subTickets) {
            // Update the status of the sub-ticket
            await updateTicketStatus(subTicket.ticket_id);
          }

          // Process each updated sub-ticket (if you want to further process them)
          for (const subTicket of subTickets) {
            await processSubTickets(subTicket.ticket_id); // Recursive call if needed
          }
        } catch (error) {
          console.error(
            `Error processing sub-tickets for ${parentTicketId}:`,
            error.message
          );
          throw error; // Throw error to stop processing if needed
        }
      };

      toast.success("All Tickets Closed", {
        autoClose: 3000, // 3 seconds
        position: toast.POSITION.TOP_RIGHT,
      });

      // Start by updating the initial ticket and processing its sub-tickets
      await updateTicketStatus(ticket_id);
      await processSubTickets(ticket_id);

      // Notify success and navigate
      navigate("/dashboard");

      // Close the modal after a delay
      setTimeout(() => {
        handleClose();
      }, 1000);
    } catch (error) {
      console.error("Error in ticket processing:", error.message);
      toast.error("Failed to close tickets");
    }
  };

  const handleModal = async (ticket_id) => {
    setShowModal(!showModal);
    try {
      const response = await axiosJWT.get(`${API_URL}/tickets/${ticket_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });

      await axiosJWT.post(
        `${API_URL}/createticketmasterlog`,
        {
          assign: response.data.assign,
          ticket_id: response.data.ticket_id,
          phase: response.data.phase,
          noofdaysexpected: response.data.noofdaysexpected,
          assigned_date_time: new Date().toISOString(),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      setViewAssign({
        ...viewAssign,
        viewAssignValue: response.data.assign,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const assignTicket = async (ticketById) => {
    try {
      await axiosJWT.post(
        `${API_URL}/updateAssignTicket/${ticketById}`,
        {
          assignValue: viewAssignValue,
          statusValue: "Assigned",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );
      handleModal();
      const response1 = await axiosJWT.get(`${API_URL}/tickets/${ticketById}`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      localStorage.setItem("viewTicket", JSON.stringify(response1.data));
      const viewTicketDataString = localStorage.getItem("viewTicket");
      if (viewTicketDataString) {
        setViewTicket(response1.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const completedTicketStatus = viewTicket.status;
  const assinedTicket = viewTicket.assign;

  const accessToken1 = localStorage.getItem("token");
  jwt_decode(accessToken1);
  const username = sessionStorage.getItem("username");

  const subTickets = async (ticket_id) => {
    let parentNoHistory = sessionStorage.getItem("parentNoHistory");
    parentNoHistory = parentNoHistory ? JSON.parse(parentNoHistory) : [];
    parentNoHistory.push(ticket_id);
    sessionStorage.setItem("parentNoHistory", JSON.stringify(parentNoHistory));

    sessionStorage.setItem("parentNo", ticket_id);
    sessionStorage.setItem("parentNo", ticket_id);
    const subParentNo = sessionStorage.getItem("parentNo");
    navigate("/subTickets");
    const axiosJWT = axios.create();
    const accessToken1 = localStorage.getItem("token");
    const queryParams = {};

    if (ticket_id) {
      queryParams.ticket_id = subParentNo;
    }

    try {
      const responseProductTeams = await axiosJWT.get(
        `${API_URL}/tickets/${ticket_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      localStorage.setItem("product", responseProductTeams.data.product);
      localStorage.setItem("team", responseProductTeams.data.teams);

      const response = await axios.get(
        `${API_URL}/filteredSubTickets/${ticket_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      setSubTicketList(response.data);
    } catch (error) {
      console.error(error);
    }
    setParentNo(ticket_id);
  };

  const copyOtherValues = (viewticket) => {
    return {
      parentNo: viewticket.parent_no,
      dead_line: viewticket.dead_line || null,
      initial_date_time: new Date().toISOString(),
      clientValue: viewticket.client,
      priorityValue: viewticket.priority || null,
      productValue: viewticket.product || null,
      sourceValue: viewticket.source || null,
      subject: viewticket.subject || null,
      task: viewticket.task || null,
      teamsValue: viewticket.teams || null,
      assignValue: viewTicket.toAssign,
      toAssignValue: viewTicket.toAssign,
      statusValue: "Processing",
      noofdaysexpected: viewTicket.noofdaysexpected || null,
      // completed_date_time: viewticket.completed_date_time,
      cycleValue: viewticket.cycle || null,
      childNo: null,
      formattedDuration: viewticket.duration || null,
    };
  };

  const completed = async (viewticket) => {
    const ticketId = viewticket.ticket_id;

    // Check if ticket_id starts with "ST"
    if (ticketId.startsWith("ST")) {
      const baseTicketId = parseInt(ticketId.replace("ST", ""), 10);

      await axiosJWT.post(
        `${API_URL}/tickets/${ticketId}`,
        {
          status: "Completed",
          completed_date_time: new Date().toISOString(),
        },
        {
          headers: { Authorization: `Bearer ${accessToken1}` },
        }
      );

      toast.success("Ticket status updated to Completed", { autoClose: 1000 });
      navigate("/dashboard");
      setTimeout(() => {
        handleClose();
      }, 1000);

      const currentDateTime = new Date();
      const ticketDeadline = new Date(viewticket.deadline);
      const createdOn = new Date(viewTicket.createdAt);
      if (currentDateTime > ticketDeadline) {
        setSelectedTicket(viewticket);
        handleOpenCompleteModal();

        // setShowCompleteModal(true);
      }

      const phase = viewticket.phase?.toLowerCase();
      const parentNo = viewticket.parent_no;

      if (phase === "entree") {
        const response = await axiosJWT.get(
          `${API_URL}/filteredSubTickets/${parentNo}`,
          {
            headers: { Authorization: `Bearer ${accessToken1}` },
          }
        );
        const hasIncompleteTickets = response.data.some(
          (ticket) => ticket.status !== "Completed"
        );
      }

      if (!viewticket.cycle || !viewticket.cycle.trim()) {
        toast.error("Cannot proceed without a valid cycle.");
        return;
      }

      let cyclePhases = viewticket.cycle.split(" - ");

      const findMatchingPhaseIndex = (phases, phaseToMatch) => {
        return phases.findIndex((p) =>
          p.toLowerCase().includes(phaseToMatch.toLowerCase())
        );
      };

      const currentPhaseIndex = findMatchingPhaseIndex(cyclePhases, phase);

      let nextPhase = null;
      if (
        currentPhaseIndex !== -1 &&
        currentPhaseIndex < cyclePhases.length - 1
      ) {
        nextPhase = cyclePhases[currentPhaseIndex + 1];
      }

      let beforeTicketId = null;
      let afterTicketId = null;

      if (phase === "test cases") {
        afterTicketId = `ST${(baseTicketId + 1).toString().padStart(4, "0")}`;
      } else if (phase === "coding") {
        beforeTicketId = `ST${(baseTicketId - 1).toString().padStart(4, "0")}`;
      }

      let isSubTicketCreationAllowed = false;

      const hasTestCasesAndCoding = nextPhase == "test cases and coding";
      const correctAutoid = parseInt(autoid.replace("ST", ""), 10);

      const subTickets = [];
      const testCasesTicketId = `ST${(correctAutoid + 1)
        .toString()
        .padStart(4, "0")}`;
      const codingTicketId = `ST${(correctAutoid + 2)
        .toString()
        .padStart(4, "0")}`;
      if (hasTestCasesAndCoding) {
        const firstSubTicket = {
          autoid: testCasesTicketId,
          username,
          phaseValue: "test cases",
          completed_date_time: null,

          ...copyOtherValues(viewticket),
        };

        const secondSubTicket = {
          autoid: codingTicketId,
          username,
          phaseValue: "coding",
          completed_date_time: null,

          ...copyOtherValues(viewticket),
        };

        subTickets.push(firstSubTicket, secondSubTicket);
      } else if (phase === "test cases" || phase === "coding") {
        const beforeTicket = beforeTicketId
          ? await axiosJWT.get(`${API_URL}/tickets/${beforeTicketId}`, {
              headers: { Authorization: `Bearer ${accessToken1}` },
            })
          : null;

        const afterTicket = afterTicketId
          ? await axiosJWT.get(`${API_URL}/tickets/${afterTicketId}`, {
              headers: { Authorization: `Bearer ${accessToken1}` },
            })
          : null;

        const isBeforeCompleted = beforeTicket?.data?.status === "Completed";
        const isAfterCompleted = afterTicket?.data?.status === "Completed";

        const hasSameParentNoBefore =
          beforeTicket && beforeTicket.data.parent_no === parentNo;

        const hasSameParentNoAfter =
          afterTicket && afterTicket.data.parent_no === parentNo;

        if (
          (phase === "test cases" &&
            hasSameParentNoAfter &&
            isAfterCompleted) ||
          (phase === "coding" && hasSameParentNoBefore && isBeforeCompleted)
        ) {
          isSubTicketCreationAllowed = true;
        }

        // New condition: Create the next phase ticket directly if above/below tickets are not matched
        const isAboveOrBelowNotMatched =
          (phase === "test cases" && !hasSameParentNoAfter) ||
          (phase === "coding" && !hasSameParentNoBefore);

        if (
          isAboveOrBelowNotMatched &&
          nextPhase !== null &&
          phase !== "entree"
        ) {
          const newSubTicket = {
            autoid,
            username,
            phaseValue: nextPhase,
            completed_date_time: null,
            ...copyOtherValues(viewticket),
          };

          await axiosJWT.post(`${API_URL}/createsubticket`, newSubTicket, {
            headers: { Authorization: `Bearer ${accessToken1}` },
          });
          getAutoID();
          if (role === "Admin") {
            toast.success("New sub-ticket created for the next phase!", {
              autoClose: 1000,
            });
          } else {
            toast.info("Ticket is Completed", { autoClose: 1000 });
          }

          const fetchSubTickets = async () => {
            const subParentNo = sessionStorage.getItem("parentNo");
            const data = await getSubTickets();
            const filteredSubTickets = data.filter(
              (element) => element.parent_no === subParentNo
            );

            setSubTicketList(filteredSubTickets);
          };
          fetchSubTickets();
        } else if (
          isSubTicketCreationAllowed &&
          nextPhase !== null &&
          phase !== "entree"
        ) {
          const newSubTicket = {
            autoid,
            username,
            phaseValue: nextPhase,
            completed_date_time: null,
            ...copyOtherValues(viewticket),
          };

          await axiosJWT.post(`${API_URL}/createsubticket`, newSubTicket, {
            headers: { Authorization: `Bearer ${accessToken1}` },
          });
          getAutoID();
          if (role === "Admin") {
            toast.success("New sub-ticket created for the next phase!", {
              autoClose: 1000,
            });
          } else {
            toast.info("Ticket is Completed", { autoClose: 1000 });
          }

          const fetchSubTickets = async () => {
            const subParentNo = sessionStorage.getItem("parentNo");
            const data = await getSubTickets();
            const filteredSubTickets = data.filter(
              (element) => element.parent_no === subParentNo
            );

            setSubTicketList(filteredSubTickets);
          };
          fetchSubTickets();
        } else {
          if (role === "Admin") {
            toast.info(
              `Cannot create a new sub-ticket. Ensure that the corresponding ${
                phase === "test cases" ? "coding" : "test cases"
              } ticket is completed and shares the same parent number.`,
              { autoClose: 2000 }
            );
          } else {
            toast.info("Ticket is Completed", { autoClose: 2000 });
          }
        }
      } else {
        const newSubTicket = {
          autoid,
          phaseValue: nextPhase,
          username,
          assignValue: viewTicket.assign,
          completed_date_time: null,
          ...copyOtherValues(viewticket),
        };

        subTickets.push(newSubTicket);
      }

      // Create the sub-tickets
      if (
        !isSubTicketCreationAllowed &&
        nextPhase !== null &&
        phase !== "entree"
      ) {
        for (const subTicket of subTickets) {
          await axiosJWT.post(`${API_URL}/createsubticket`, subTicket, {
            headers: {
              Authorization: `Bearer ${accessToken1}`,
            },
          });
        }
        getAutoID();

        toast.success("Sub-tickets created successfully!", { autoClose: 1000 });
        const fetchSubTickets = async () => {
          const subParentNo = sessionStorage.getItem("parentNo");
          const data = await getSubTickets();
          const filteredSubTickets = data.filter(
            (element) => element.parent_no === subParentNo
          );

          setSubTicketList(filteredSubTickets);
        };
        fetchSubTickets();
      }
    } else if (ticketId.startsWith("T")) {
      // If ticket_id starts with "T"
      try {
        await axiosJWT.post(
          `${API_URL}/tickets/${viewticket.ticket_id}`,
          {
            status: "Completed",
            completed_date_time: new Date().toISOString(),
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken1}`,
            },
          }
        );
        toast.success("Ticket status updated to Completed", {
          autoClose: 1000,
        });
        navigate("/dashboard");
        handleClose();
      } catch (error) {
        toast.error("Failed to update the ticket status.", { autoClose: 1000 });
      }
    }
  };

  const close = async (ticket_id) => {
    try {
      await axiosJWT.post(
        `${API_URL}/tickets/${ticket_id}`,
        {
          status: "Closed",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );
      toast.success("Ticket status updated to Close", { autoClose: 1000 });
      // navigate("/dashboard");
    } catch (err) {
      console.log(err);
    }
  };

  const backTicket = () => {
    navigate(-1);
  };

  const handlePriority = () => {
    setShowPriorityModal(true);
  };

  const handleClosePriorityModal = () => {
    setShowPriorityModal(false);
  };

  // const buttonFlag = sessionStorage.getItem("buttonFlag");

  console.log(viewTicket.phase);

  return (
    <div className="container">
      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        {false && (
          <button className="btn btn-md btn-primary " onClick={backTicket}>
            Back
          </button>
        )}
      </div>
      <div className="row">
        <div className="col-lg-4 ">
          <div className="user-profile">
            <div className="user-info bg-primary p-3">
              <h2 className="text-white">Product: {viewTicket.product}</h2>
            </div>

            <div className="list-group">
              <div className="list-group-item">
                <i className="far fa-ticket"></i> Ticket Id &nbsp;
                <span className="highlight-text-orange">
                  {" "}
                  {viewTicket.ticket_id}
                </span>
              </div>
              <div className="list-group-item">
                <i className="fas fa-history"></i> Created By &nbsp;
                <span className="text-primary spacing">
                  {viewTicket.created_by}
                </span>
              </div>
              <div className="list-group-item">
                <i className="fas fa-history"></i> Deadline &nbsp;
                <span className="text-primary spacing">
                  {viewTicket.dead_line}
                </span>
              </div>
              <div className="list-group-item">
                <i className="far fa-user"></i> Assign &nbsp;
                <span className="text-primary spacing">
                  {viewTicket.assign}
                </span>
              </div>
              &nbsp; {/* {!buttonFlag && ( */}
              {checkParentNo.length > 0 && (
                <button
                  className="custom-btn"
                  onClick={() => subTickets(viewTicket.ticket_id)}
                >
                  Sub Ticket
                </button>
              )}
              &nbsp;
              {showcompletedbtn &&
                statusFromLocalStorage === "completed" &&
                (username === assinedTicket || role === "Admin") &&
                completedTicketStatus !== "Completed" &&
                completedTicketStatus !== "Closed" && (
                  <button
                    className="custom-btn"
                    onClick={() => completed(viewTicket)}
                  >
                    {" "}
                    Mark As Completed
                    {/* {
                    completedTicketStatus !== "Completed"
                      ? "Mark As Completed"
                      : "Completed"} */}
                  </button>
                )}
              &nbsp;
              {role === "Admin" && completedTicketStatus === "Completed" && (
                <button
                  className="custom-btn btn-danger"
                  // disabled={completedTicketStatus !== "Completed"}
                  onClick={() => close(viewTicket.ticket_id)}
                >
                  Close Ticket
                </button>
              )}
              &nbsp;
              {(username === assinedTicket || role === "Admin") &&
                completedTicketStatus !== "Completed" &&
                completedTicketStatus !== "Closed" && (
                  <button
                    className="custom-btn btn-danger"
                    // disabled={
                    //   completedTicketStatus === "Completed" ||
                    //   completedTicketStatus === "Closed"
                    // }
                    onClick={() => handleModal(viewTicket.ticket_id)}
                  >
                    Assign Ticket
                  </button>
                )}
              {/* &nbsp;
              <button onClick={handlePriority}>Priority</button>
              {showPriorityModal && (
                <Priority
                  ticketId={viewTicket.ticket_id}
                  onClose={handleClosePriorityModal}
                />
              )} */}
              &nbsp;
              {role === "Admin" && (
                <button
                  className="custom-btn btn-danger"
                  onClick={() => duplicateTicket(viewTicket.ticket_id)}
                >
                  Duplicate Ticket
                </button>
              )}
              &nbsp;
              {role === "Admin" && viewTicket.status !== "Closed" && (
                <button
                  className="custom-btn btn-danger"
                  onClick={() => closeTicket(viewTicket.ticket_id)}
                >
                  Close All Ticket
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-8 position-relative">
          <Button
            variant="secondary"
            onClick={backTicket}
            style={{ marginRight: "-30px", marginTop: "-31px" }}
            className="position-absolute end-0"
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <div className="table-responsive margin-bottom-2x">
            <table className="table margin-bottom-none ">
              <thead>
                <tr>
                  <th className="text-center">Created on</th>
                  <th className="text-center">Priority</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Phase</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">
                    <span className="text-primary">
                      {moment(viewTicket.createdAt).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="text-info">{viewTicket.priority}</span>
                  </td>
                  <td className="text-center">
                    <span className="text-success">{viewTicket.status}</span>
                  </td>
                  <td className="text-center">
                    <span className="text-warning">{viewTicket.phase}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="ticket-details-container">
            <div className="ticket-header bg-warning p-3">
              <h3 className="ticket-id text-white">
                Ticket ID: {viewTicket.ticket_id}
              </h3>
              <h4 className="ticket-id text-white">
                Parent Ticket ID: {viewTicket.parent_no}
              </h4>
              <h4 className="client text-white">Client: {viewTicket.client}</h4>
              <h4 className="client text-white">Task: {viewTicket.task}</h4>
            </div>
            <h2 className="subject text-danger p-3">
              Subject: {viewTicket.subject}
            </h2>
            {viewTicket.phase !== "Entrée" && (
              <div className="comments-section bg-light p-3 rounded">
                <h3 className="comments-heading text-info">Comments:</h3>
                <CommentSection />
              </div>
            )}
            <div className="col-auto my-custom-left-margin d-print-none">
              <div className="btn-list">
                <button
                  className="btn btn-md btn-primary"
                  // onClick={backToParentTicket}
                >
                  Back
                </button>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-sm-11 col-md-6">
            <div className="mb-2">
              <label className="form-label">Assign</label>
              <select
                className="form-select"
                aria-label="Default select example"
                value={viewAssignValue}
                onChange={(e) => {
                  setViewAssign({
                    ...viewAssign,
                    viewAssignValue: e.target.value,
                  });
                }}
              >
                <option>Please choose one option</option>
                {viewAssignDropDownValues?.map((option, index) => {
                  return <option key={index}>{option?.firstname}</option>;
                })}
              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              assignTicket(viewTicket.ticket_id);
              handleModal();
            }}
          >
            Assign
          </Button>

          {/* <Button
            variant="primary"
            onClick={() => {
              assignTicket(viewTicket.ticket_id);
              // handleModal();
            }}
          >
            Add Comment
          </Button> */}
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        dialogclassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{"Comment Session"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="textarea-container">
            <textarea
              value={textAreas}
              onChange={(e) => setTextAreas(e.target.value)}
              placeholder="Enter new comment"
              className="custom-textarea"
            />{" "}
            <button className="addBtn" onClick={createTextArea}>
              Add Comment
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {showCompleteModal && (
        <CompleteTicketModal
          showCompleteModal={showCompleteModal}
          setShowCompleteModal={setShowCompleteModal}
          timeTaken={timeTaken}
          ticketId={ticketId}
          ticket={selectedTicket}
          setTimeTaken={setTimeTaken}
          expectedFormat={expectedTime}
        />
      )}
    </div>
  );
}
