/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import "./Reports.css";
import Select from "react-dropdown-select";
import axios from "axios";
import { API_URL } from "../ApiUrl";
import { ToastContainer, toast } from "react-toastify";
import { MDBDataTableV5 } from "mdbreact";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import jwt_decode from "jwt-decode";
import { Modal } from "react-bootstrap";
import {
  getClients,
  getPhase,
  getPriority,
  getProduct,
  getSource,
  getTaskStatus,
  getTeam,
  getUsers,
} from "../masters/Lists/ListShow";
import { saveAs } from "file-saver";

export default function Reports({
  product,
  setTeams,
  teams,
  priority,
  setPriority,
  status,
  setStatus,
  assign,
  setAssign,
  setParentNo,
  setPhase,
  setSource,
  setSubject,
  setTask,
  setPrimaryId,
  setProduct,
  client,
  setClient,
  toAssign,
  setToAssign,
  dead_line,
  phase,
  source,
  setDead_line,
  primaryId,
}) {
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [filteredTeam, setFilteredTeam] = useState([]);
  const [filteredStatus, setFilteredStatus] = useState([]);
  const [filteredpriority, setFilteredpriority] = useState([]);
  const [final, setFinal] = useState([]);
  const [showCompletedDateTime, setShowCompletedDateTime] = useState(false);
  const [showAssignedDateTime, setShowAssignedDateTime] = useState(false);
  const [showInitialDateTime, setShowInitialDateTime] = useState(false);
  const [showDeadLine, setShowDeadLine] = useState(false);
  const [owner, setOwner] = useState([]);
  const [selectKey, setSelectKey] = useState(0);
  const [show, setShow] = useState(false);
  const [autoid, setAutoid] = useState("");
  const [filteredClient] = useState([]);
  const [filteredPhase, setFilteredPhase] = useState([]);
  const [filteredAssign, setFilteredAssign] = useState([]);
  const [filteredSource, setFilteredSource] = useState([]);
  const [assignedValue, setAssignedValue] = useState("");
  const [filteredToAssign, setFilteredToAssign] = useState([]);
  const [toAssignedValue, setToAssignedValue] = useState("");
  const { assignValue, assignDropDownValues } = assign || [];
  const { toAssignValue, toAssignDropDownValues } = toAssign || [];
  const [entreeChecked, setEntreeChecked] = useState(false);
  const [deadLineDateRange, setDeadLineDateRange] = useState({
    startDate: "",
    endDate: "",
    key: "selection",
  });
  const [completedDateTimeRange, setCompletedDateTimeRange] = useState({
    startDate: "",
    endDate: "",
    key: "selection",
  });
  const [initialDateTimeRange, setInitialDateTimeRange] = useState({
    startDate: "",
    endDate: "",
    key: "selection",
  });

  const [assignedDateTimeRange, setAssignedDateTimeRange] = useState({
    startDate: "",
    endDate: "",
    key: "selection",
  });

  const axiosJWT = axios.create();
  const username = sessionStorage.getItem("username");
  const role = sessionStorage.getItem("role");

  useEffect(() => {
    const fetchData = async () => {
      const clientData = await getClients();
      setClient({
        ...client,
        clientDropDownValues: clientData,
      });
      const statusData = await getTaskStatus();
      setStatus({
        ...status,
        statusDropDownValues: statusData,
      });
      const assignData = await getUsers();
      setAssign({
        assignValue: "",
        assignDropDownValues: assignData,
      });
      setToAssign({
        toAssignValue: "",
        toAssignDropDownValues: assignData,
      });
      const sourceData = await getSource();
      setSource({
        ...source,
        sourceDropDownValues: sourceData,
      });
      const phaseData = await getPhase();
      setPhase({
        ...phase,
        phaseDropDownValues: phaseData,
      });
      const productData = await getProduct();
      setOwner(productData);
      setProduct({
        ...product,
        productDropDownValues: productData,
      });

      const teamData = await getTeam();
      setTeams({
        ...teams,
        teamsDropDownValues: teamData,
      });
      const priorityData = await getPriority();
      setPriority({
        ...priority,
        priorityDropDownValues: priorityData,
      });
    };
    fetchData();
  }, []);

  const { productValue } = product;
  const { teamsValue } = teams;
  const { sourceValue } = source;
  const { phaseValue } = phase;
  const { statusValue } = status;
  const { clientValue } = client;
  const { priorityValue } = priority;

  const data1 = filteredProduct.map((filterProduct) => filterProduct.product);
  const data2 = filteredTeam.map((filterTeam) => filterTeam.team);
  const data3 = filteredStatus.map((filteredStatus) => filteredStatus.status);
  const data4 = filteredpriority.map(
    (filterpriority) => filterpriority.priority
  );
  const data5 = filteredClient.map((filterClient) => filterClient.client);
  const data6 = filteredPhase.map((filterPhase) => filterPhase.phase);
  const data7 = filteredSource.map((filterSource) => filterSource.source);
  const data10 = filteredAssign.map((filterAssign) => filterAssign.label);
  const data11 = filteredToAssign.map((filterToAssign) => filterToAssign.label);

  const formatDate = (date) => {
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  };

  const formatDateTime = (date) => {
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
  };

  const formattedStartDate = formatDate(deadLineDateRange.startDate);
  const formattedEndDate = formatDate(deadLineDateRange.endDate);
  const formatedCompletedStartDate = formatDateTime(
    completedDateTimeRange.startDate
  );
  const formatedCompletedEndDate = formatDateTime(
    completedDateTimeRange.endDate
  );
  const formatedInitalStartDate = formatDateTime(
    initialDateTimeRange.startDate
  );
  const formatedInitalEndDate = formatDateTime(initialDateTimeRange.endDate);
  const formatedAssignedStartDate = formatDateTime(
    assignedDateTimeRange.startDate
  );
  const formatedAssignedEndDate = formatDateTime(assignedDateTimeRange.endDate);

  const handleClose = async () => {
    setShow(false);
    setDead_line("");
    setAssign({ assign: [] });
    setStatus({ statusValue: "", statusDropDownValues: [] });
    setClient({ clientValue: "", clientDropDownValues: [] });
    setToAssign({ toAssignValue: "", toAssignDropDownValues: [] });
    setAssign({ assignValue: "", assignDropDownValues: [] });
    setPriority({ priorityValue: "", priorityDropDownValues: [] });
  };

  const saveTicket = async (data) => {
    data.preventDefault();

    if (
      dead_line === "" ||
      client.toString() === "" ||
      phase.toString() === "" ||
      priority.toString() === "" ||
      product.productValue.toString() === "" ||
      source.toString() === ""
    ) {
      toast.error("Please Enter All Fields...!", { autoClose: 1000 });
      return;
    }

    try {
      if (primaryId) {
        const response = await axiosJWT.post(
          `${API_URL}/updateticket/${primaryId}`,
          {
            autoid,
            assignValue,
            dead_line,
            clientValue,
            // mark,
            // parentNo,
            phaseValue,
            priorityValue,
            productValue,
            sourceValue,
            statusValue,
            // subject,
            // task,
            teamsValue,
            toAssignValue,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken1}`,
            },
          }
        );
        toast.success(response.data.msg, { autoClose: 1000 });
        setTimeout(() => {
          handleClose();
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const screens = sessionStorage.getItem("screens").split(",");

  const handleSubmit = async () => {
    const axiosJWT = axios.create();
    const accessToken1 = localStorage.getItem("token");

    const queryParams = {};

    if (data1) {
      queryParams.product = data1;
    }
    if (data2) {
      queryParams.teams = data2;
    }
    if (data3) {
      queryParams.status = data3;
    }
    if (data4) {
      queryParams.priority = data4;
    }
    if (data5) {
      queryParams.client = data5;
    }
    if (data6) {
      queryParams.phase = data6;
    }
    if (data7) {
      queryParams.source = data7;
    }
    if (data10) {
      queryParams.assign = data10;
    }
    if (data11) {
      queryParams.toAssign = data11;
    }

    if (formatedCompletedStartDate) {
      queryParams.formatedCompletedStartDate = formatedCompletedStartDate;
    }
    if (formatedCompletedEndDate) {
      queryParams.formatedCompletedEndDate = formatedCompletedEndDate;
    }
    if (formatedInitalStartDate) {
      queryParams.formatedInitalStartDate = formatedInitalStartDate;
    }
    if (formatedInitalEndDate) {
      queryParams.formatedInitalEndDate = formatedInitalEndDate;
    }
    if (formatedAssignedStartDate) {
      queryParams.formatedAssignedStartDate = formatedAssignedEndDate;
    }
    if (formatedAssignedEndDate) {
      queryParams.formatedInitalEndDate = formatedAssignedEndDate;
    }
    if (formattedStartDate) {
      queryParams.formattedStartDate = formattedStartDate;
    }
    if (formattedEndDate) {
      queryParams.formattedEndDate = formattedEndDate;
    }

    try {
      const response = await axiosJWT.get(`${API_URL}/filteredtickets`, {
        params: queryParams,
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      if (entreeChecked === true) {
        const filteredTickets = response.data.filter(
          (ticket) => ticket.phase === "Entrée"
        );
        setFinal(filteredTickets);
      } else {
        const filteredTickets = response.data.filter(
          (ticket) => ticket.phase !== "Entrée"
        );
        setFinal(filteredTickets);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelect = (ranges) => {
    setDeadLineDateRange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: "selection",
    });
  };

  const handleCompletedDateTime = (ranges) => {
    setCompletedDateTimeRange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: "selection",
    });
  };

  const handleInitialDateTime = (ranges) => {
    setInitialDateTimeRange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: "selection",
    });
  };

  const dropdownOptions = assignDropDownValues.map((item) => ({
    label: item.firstname,
    value: item.id,
  }));
  const dropdownToAssignOptions = toAssignDropDownValues.map((item) => ({
    label: item.firstname,
    value: item.id,
  }));

  const handleDropdownChange = (selectedOption) => {
    setAssignedValue(selectedOption.value);
    setFilteredAssign(selectedOption);
  };
  const handleDropdownToAssignChange = (selectedOption) => {
    setToAssignedValue(selectedOption[0].label);
    setFilteredToAssign(selectedOption);
  };
  const toggleCompletedDateTime = () => {
    setShowCompletedDateTime(!showCompletedDateTime);
  };
  const toggleInitialDateTime = () => {
    setShowInitialDateTime(!showInitialDateTime);
  };
  const toggleDeadLine = () => {
    setShowDeadLine(!showDeadLine);
  };
  const toggleAssignedDateTime = () => {
    setShowAssignedDateTime(!showAssignedDateTime);
  };

  const handleClear = () => {
    window.location.reload();
    setShowAssignedDateTime(false);
    setShowCompletedDateTime(false);
    setShowDeadLine(false);
    setShowInitialDateTime(false);
    setFilteredProduct([]);
    setAssignedValue(""); // Clear the selected value for Assign dropdown
    setToAssignedValue(""); // Clear the selected value for To Assign dropdown
    setFilteredTeam([]);
    setFilteredStatus([]);
    setFilteredpriority([]);
    setSelectKey(selectKey + 1);
    setDeadLineDateRange({});
    setCompletedDateTimeRange({});
    setInitialDateTimeRange({});
    setAssignedDateTimeRange({});
  };

  const downloadExcel = (ticket) => {
    const formattedData = [
      {
        TicketID: ticket.ticket_id,
        Status: ticket.status,
        Subject: ticket.subject,
        Task: ticket.task,
        Assign: ticket.assign,

        Product: ticket.product,
        Priority: ticket.priority,
        Dead_Line: ticket.dead_line,
      },
    ];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const dataURI = XLSX.write(wb, { bookType: "xlsx", type: "base64" });

    const a = document.createElement("a");
    a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${dataURI}`;
    a.download = `ticket_${ticket.ticket_id || ticket.ticket_id} .xlsx`;
    a.click();
  };

  const accessToken1 = localStorage.getItem("token");
  jwt_decode(accessToken1);
  // const isTokenExpired = tokenPayload.exp < Date.now() / 1000;

  const handleEdit = async (ticket_id) => {
    setAutoid(ticket_id);
    setShow(true);
    const response = await axiosJWT.get(`${API_URL}/tickets/${ticket_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken1}`,
      },
    });

    setAssign({
      ...assign,
      assignValue: response.data.assign,
    });
    setDead_line(response.data.dead_line);
    setClient({
      ...client,
      clientValue: response.data.client,
    });
    // setMark(response.data.mark);
    setParentNo(response.data.parentNo);
    setPhase({
      ...phase,
      phaseValue: response.data.phase,
    });
    setPriority({
      ...priority,
      priorityValue: response.data.priority,
    });
    setProduct({
      ...product,
      productValue: response.data.product,
    });
    setSource({
      ...source,
      sourceValue: response.data.source,
    });
    setStatus({
      ...status,
      statusValue: response.data.status,
    });
    setSubject(response.data.subject);
    setTask(response.data.task);
    setTeams({
      ...teams,
      teamsValue: response.data.teams,
    });
    setToAssign({
      ...toAssign,
      toAssignValue: response.data.toAssign,
    });
    setPrimaryId(response.data.id);
  };

  const datatable = {
    columns: [
      {
        label: "id",
        field: "id",
      },
      {
        label: "ticket_id",
        field: "ticket_id",
      },
      {
        label: "client",
        field: "client",
      },
      {
        label: "status",
        field: "status",
      },
      {
        label: "subject",
        field: "subject",
      },
      {
        label: "task",
        field: "task",
      },
      {
        label: "assign",
        field: "assign",
      },
      {
        label: "product",
        field: "product",
      },
      {
        label: "priority",
        field: "priority",
      },
      {
        label: "phase",
        field: "phase",
      },
      {
        label: "dead_line",
        field: "dead_line",
      },
      ...(screens.includes("Reports Edit") ||
      screens.includes("Reports Exports")
        ? [
            {
              label: "Actions",
              field: "actions",
            },
          ]
        : []),
    ],
    rows: (role !== "Admin"
      ? final.filter((ticket) => {
          const projectOwner = owner.find((o) => o.product === ticket.product);
          const projectOwnerName = projectOwner?.projectowner ?? "N/A";
          return ticket.assign === username || projectOwnerName === username;
        })
      : final
    ).map((ticket, index) => ({
      ...ticket,
      id: index + 1,
      actions: (
        <div style={{ display: "flex" }}>
          {screens !== undefined && screens.includes("Reports Edit") && (
            <button
              className="demo-icons-list-item no-bg"
              title="Edit"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              onClick={() => handleEdit(ticket.ticket_id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="blue"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 20l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4h4z" />
                <path d="M13.5 6.5l4 4" />
                <path d="M16 18h4m-2 -2v4" />
              </svg>
            </button>
          )}

          {screens !== undefined && screens.includes("Reports Exports") && (
            <button
              className="demo-icons-list-item no-bg"
              title="Export Excel"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              onClick={() => downloadExcel(ticket)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-file-spreadsheet"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="green"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
                <path d="M8 11h8v7h-8z"></path>
                <path d="M8 15h8"></path>
                <path d="M11 11v7"></path>
              </svg>
            </button>
          )}
        </div>
      ),
    })),
  };
  const handleOverallReports = () => {
    try {
      const ws = XLSX.utils.json_to_sheet(datatable.rows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Create a Blob containing the data in Excel format
      const blob = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: false,
        type: "binary",
      });
      const blobBuffer = new Blob([s2ab(blob)], {
        type: "application/octet-stream",
      });

      // Use the FileSaver.js library to save the blob as an Excel file
      saveAs(blobBuffer, "overall_reports.xlsx");
    } catch (error) {
      console.error("Error generating Excel file:", error);
    }
  };

  // Utility function to convert string to ArrayBuffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const handleCheckboxChange = () => {
    setEntreeChecked(!entreeChecked);
  };

  return (
    <div className="d-flex justify-content-center mt-4">
      <div className="col-11">
        <div className="card custom-card">
          <div className="row">
            <div className="col-md-4">
              <h2>Product</h2>
              <Select
                name="select"
                options={product.productDropDownValues}
                labelField="product"
                valueField="product"
                multi
                onChange={(value) => setFilteredProduct(value)}
                value={filteredProduct}
                key={selectKey}
              ></Select>
            </div>
            <div className="col-md-4 ">
              <h2>Team</h2>
              <div className="border rounded">
                <Select
                  name="select"
                  options={teams.teamsDropDownValues}
                  labelField="team"
                  valueField="team"
                  multi
                  onChange={(value) => setFilteredTeam(value)}
                  value={filteredTeam}
                  key={selectKey}
                ></Select>
              </div>
            </div>
            <div className="col-md-4 ">
              <h2>Status</h2>
              <div className="border rounded">
                <Select
                  name="select"
                  options={status.statusDropDownValues}
                  labelField="status"
                  valueField="status"
                  multi
                  onChange={(value) => setFilteredStatus(value)}
                  value={filteredStatus}
                  key={selectKey}
                ></Select>
              </div>
            </div>
            <div className="col-md-4 custom-selector">
              <h2>Priority</h2>
              <div className="border rounded">
                <Select
                  name="select"
                  options={priority.priorityDropDownValues}
                  labelField="priority"
                  valueField="priority"
                  multi
                  onChange={(value) => setFilteredpriority(value)}
                  value={filteredpriority}
                  key={selectKey}
                ></Select>
              </div>
            </div>
            <div className="col-md-4 custom-selector">
              <h2>Source</h2>
              <div className="border rounded">
                <Select
                  name="select"
                  options={source.sourceDropDownValues}
                  labelField="source"
                  valueField="source"
                  multi
                  onChange={(value) => setFilteredSource(value)}
                  value={filteredSource}
                  key={selectKey}
                ></Select>
              </div>
            </div>
            <div className="col-md-4 custom-selector">
              <h2>Phase</h2>
              <div className="border rounded">
                <Select
                  name="select"
                  options={phase.phaseDropDownValues}
                  labelField="phase"
                  valueField="phase"
                  multi
                  onChange={(value) => setFilteredPhase(value)}
                  value={filteredPhase}
                  key={selectKey}
                ></Select>
              </div>
            </div>
            <div className="col-md-4 custom-selector">
              <h2>Assign</h2>
              <div className="border rounded">
                <Select
                  value={assignedValue}
                  onChange={(value) => handleDropdownChange(value)}
                  options={dropdownOptions}
                />
              </div>
            </div>
            <div className="col-md-4 custom-selector">
              <h2>To Assign</h2>
              <div className="border rounded">
                <Select
                  value={toAssignedValue}
                  onChange={handleDropdownToAssignChange}
                  options={dropdownToAssignOptions}
                />
              </div>
            </div>
            <div className="col-md-4 custom-selector">
              <h2>Completed Date Time</h2>
              <input
                type="text"
                className="form-control border rounded"
                onClick={toggleCompletedDateTime}
                placeholder="Completed Date Time"
              />
              {showCompletedDateTime && (
                <DateRangePicker
                  ranges={[completedDateTimeRange]}
                  onChange={handleCompletedDateTime}
                  value={completedDateTimeRange}
                  className="datepicker"
                />
              )}
            </div>
            <div className="col-md-4 custom-selector">
              <h2>Initial Date Time</h2>
              <input
                className="form-control border rounded"
                onClick={toggleInitialDateTime}
                placeholder="Initial Date Time"
              />
              {showInitialDateTime && (
                <DateRangePicker
                  ranges={[initialDateTimeRange]}
                  onChange={handleInitialDateTime}
                  value={initialDateTimeRange}
                  className="datepicker custom-datepicker"
                />
              )}
            </div>

            <div className="col-md-4 custom-selector">
              <h2>Assigned Date Time</h2>
              <input
                type="text"
                className="form-control border rounded"
                onClick={toggleAssignedDateTime}
                placeholder="Assigned Date Time"
              />
              {showAssignedDateTime && (
                <DateRangePicker
                  ranges={[assignedDateTimeRange]}
                  onChange={handleInitialDateTime}
                  value={assignedDateTimeRange}
                  className="datepicker"
                />
              )}
            </div>

            <div className="col-md-4 custom-selector">
              <h2>Deadline</h2>
              <input
                type="text"
                className="form-control border rounded"
                onClick={toggleDeadLine}
                placeholder="Deadline"
              />
              {showDeadLine && (
                <DateRangePicker
                  ranges={[deadLineDateRange]}
                  onChange={handleSelect}
                  value={deadLineDateRange}
                  className="datepicker"
                />
              )}
            </div>
            <div className="col-md-4 custom-selector">
              <div className="form-check d-flex align-items-center">
                <input
                  type="checkbox"
                  id="entreeCheckbox"
                  className="form-check-input"
                  onChange={handleCheckboxChange}
                  checked={entreeChecked}
                  style={{ marginRight: "10px" }} // Adjust the margin-right as needed
                />
                <h2
                  htmlFor="entreeCheckbox"
                  className="form-check-label ml-2 font-weight-bold"
                >
                  Entree
                </h2>
              </div>
            </div>
          </div>
          <div className="d-grid gap-2 d-md-flex justify-content-md-end custom_btn">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Submit
            </button>
            &nbsp;
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
        </div>
        <hr></hr>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          className="modal-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{"Update Ticket"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12">
              <form className="">
                <div className="card-body">
                  <div className="row row-cards">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Ticket Id</label>
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          defaultValue={autoid}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="mb-2">
                        <label className="form-label">Assign *</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={assignValue}
                          onChange={(e) =>
                            setAssign({
                              ...assign,
                              assignValue: e.target.value,
                            })
                          }
                        >
                          <option>Please choose one option</option>
                          {assignDropDownValues.map((option, index) => {
                            return (
                              <option key={index}>{option.firstname}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <div className="mb-2">
                        <label className="form-label">To Assign *</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={toAssignValue}
                          onChange={(e) =>
                            setToAssign({
                              ...toAssign,
                              toAssignValue: e.target.value,
                            })
                          }
                        >
                          <option>Please choose one option</option>
                          {toAssignDropDownValues.map((option, index) => {
                            return (
                              <option key={index}>{option.firstname}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <div className="mb-2">
                        <label className="form-label">Client *</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={client.clientValue}
                          onChange={(e) =>
                            setClient({
                              ...client,
                              clientValue: e.target.value,
                            })
                          }
                        >
                          <option>Please choose one option</option>
                          {client.clientDropDownValues.map((data, index) => {
                            return <option key={index}>{data.name}</option>;
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Status *</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={status.statusValue}
                          onChange={(e) =>
                            setStatus({
                              ...status,
                              statusValue: e.target.value,
                            })
                          }
                        >
                          <option>Please choose one option</option>
                          {status.statusDropDownValues.map((data, index) => {
                            return <option key={index}>{data.status}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Dead_line *</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Dead_line"
                          value={dead_line}
                          onChange={(e) => setDead_line(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Priority *</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={priority.priorityValue}
                          onChange={(e) =>
                            setPriority({
                              ...priority,
                              priorityValue: e.target.value,
                            })
                          }
                        >
                          <option>Please choose one option</option>
                          {priority.priorityDropDownValues.map(
                            (data, index) => {
                              return (
                                <option key={index}>{data.priority}</option>
                              );
                            }
                          )}
                        </select>
                      </div>
                    </div>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <button
                        className="btn btn-md btn-primary "
                        onClick={saveTicket}
                      >
                        {"Update Ticket"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <div className="page-body">
          <div className="container-xl">
            <div className="row row-cards">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="carhd-title">Tickets List</h3>
                  </div>

                  <div className="container table-responsive mt-3">
                    <MDBDataTableV5
                      striped
                      fixed
                      hover
                      entriesOptions={[10, 20, 30, 50]}
                      entries={10}
                      data={datatable}
                      searchTop
                      searchBottom={false}
                    />
                    <ToastContainer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-auto">
            <button className="btn btn-primary" onClick={handleOverallReports}>
              Overall Reports
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
