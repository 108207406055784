import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Priority.css";
import { API_URL } from "../ApiUrl";

const Priority = ({ onClose, ticketId }) => {
  const [selectedPriority, setSelectedPriority] = useState("");

  const token = localStorage.getItem("token");
  const username = sessionStorage.getItem("username");
  const handlePrioritySelect = (e) => {
    setSelectedPriority(e.target.value);
  };

  const updateTicketPriority = async (ticketId, priorityValue) => {
    try {
      await axios.post(
        `${API_URL}/updatepriorityticket/${ticketId}`,
        { priorityValue },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (err) {
      console.error(`Error updating ticket ${ticketId} priority:`, err);
    }
  };

  const handleSave = async () => {
    if (!selectedPriority) {
      toast.error("Please select a priority level.");
      return;
    }

    if (!ticketId) {
      console.error("Ticket ID is not defined.");
      return;
    }

    const priorityValue = parseInt(selectedPriority, 10); // Ensure this is parsed correctly as an integer

    try {
      const response = await axios.get(`${API_URL}/assignTicket/${username}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let updatedTickets = response.data;

      for (let ticket of updatedTickets) {
        if (ticket.priority >= priorityValue && ticket.priority <= 10) {
          const newPriority = ticket.priority + 1;
          if (newPriority <= 10) {
            await updateTicketPriority(ticket.ticket_id, newPriority);
          }
        }
      }

      await updateTicketPriority(ticketId, priorityValue);

      const updatedResponse = await axios.get(
        `${API_URL}/assignTicket/${username}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      updatedTickets = updatedResponse.data;
      const ticketWithPriority10 = updatedTickets.find(
        (ticket) => ticket.priority === 10
      );
      if (ticketWithPriority10) {
        await updateTicketPriority(ticketWithPriority10.ticket_id, null);
      }

      toast.success("Priority updated successfully!");

      const response1 = await axios.get(`${API_URL}/tickets/${ticketId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      localStorage.setItem("viewTicket", JSON.stringify(response1.data));
      onClose();
    } catch (err) {
      console.error("Error updating priority or assigning ticket:", err);
      toast.error("Error updating priority. Please try again.");
    }
  };

  if (!ticketId) {
    return null;
  }

  return (
    <>
      <Modal
        show={true}
        onHide={onClose}
        backdrop="static"
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Priority</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5>Select a Priority Level:</h5>
            <select
              value={selectedPriority}
              onChange={handlePrioritySelect}
              className="form-control custom-select" // Added custom CSS class for styling
            >
              <option value="">Select Priority</option>
              {[...Array(10)].map((_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleSave} className="btn btn-primary">
            Save
          </button>
          <button onClick={onClose} className="btn btn-secondary">
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Priority;
