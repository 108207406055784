/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { API_URL } from "../ApiUrl";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Priority from "../tickets/Priority";
import moment from "moment";
import CommentSection from "../tickets/CommentSection";
import { getSubTickets } from "../tickets/Lists/ListShow";
import { TestingNumberofBuges } from "../tickets/TestingNumberofBuges";
import { getUsers } from "../masters/Lists/ListShow";

export default function ViewTicketModal({
  setParentNo,
  setSubTicketList,
  setCommentArea,
  viewTicket,
  onClose,
  setViewTicket,
  showModal,
  setShowModal,
  ticketId,
  childNo,
  assign,
  setAssign,
}) {
  //   const [show, setShow] = useState(false);
  const [statusFromLocalStorage, setStatusFromLocalStorage] = useState("");
  const [ticketIdToClose, setTicketIdToClose] = useState(null);
  const [showBugesModal, setShowBugesModal] = useState(false);
  const [showPriorityModal, setShowPriorityModal] = useState(false);
  //   const [showModal, setShowModal] = useState(false);
  const [viewAssign, setViewAssign] = useState({
    viewAssignValue: "",
    viewAssignDropDownValues: [],
  });
  const [assignModal, setAssignModal] = useState(false);
  const [autoid, setAutoid] = useState([]);
  //   const [selectedTicket, setSelectedTicket] = useState(null);
  //   const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [checkParentNo, setCheckParentNo] = useState([]);

  const completedTicketStatus = viewTicket.status;
  const role = sessionStorage.getItem("role");
  const accessToken1 = localStorage.getItem("token");
  const navigate = useNavigate();
  const username = sessionStorage.getItem("username");
  const showcompletedbtn = localStorage.getItem("showcompletedbtn");
  const assinedTicket = viewTicket.assign;

  const { viewAssignValue, viewAssignDropDownValues } = viewAssign || {};

  const checkParentno = async () => {
    try {
      const subTicketResponse = await axios.get(
        `${API_URL}/filteredSubTickets/${viewTicket.ticket_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      setCheckParentNo(subTicketResponse.data);
      return subTicketResponse;
    } catch (error) {
      console.error("Error fetching subTickets:", error);
    }
  };

  const parentTicket = sessionStorage.getItem("parentNo");

  useEffect(() => {
    getAutoID();
    handleShowUsers();

    const accessToken1 = localStorage.getItem("token");
    const axiosJWT = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken1}`,
      },
    });

    if (localStorage.getItem("isRefreshing") === "true") {
      localStorage.setItem("isRefreshing", "false");
    } else {
      const fetchAssignValue = async () => {
        // Check if parentTicket and accessToken1 are valid
        if (!parentTicket || !accessToken1) {
          console.error("parentTicket or accessToken1 is missing.");
          return;
        }

        const ticketIdRegex = /^[A-Z]+\d+/;

        // Ensure parentTicket is a string
        if (typeof parentTicket !== "string") {
          console.error("parentTicket is not a string.");
          return;
        }

        const match = parentTicket.match(ticketIdRegex);

        if (match) {
          const firstParentNo = match[0];

          try {
            const response = await axiosJWT.get(
              `${API_URL}/tickets/${firstParentNo}`,
              {
                headers: {
                  Authorization: `Bearer ${accessToken1}`,
                },
              }
            );

            setAssign((prevAssign) => ({
              ...prevAssign,
              assignValue: response.data.assign,
            }));
          } catch (error) {
            if (error.response) {
              // Server responded with a status other than 2xx
              console.error(
                `Error fetching data: ${error.response.status} ${error.response.statusText}`
              );
            } else if (error.request) {
              // Request was made but no response received
              console.error("Error making request:", error.request);
            } else {
              // Something happened in setting up the request
              console.error("Error setting up request:", error.message);
            }
          }
        } else {
          console.error("parentTicket does not match the expected format.");
        }
      };

      fetchAssignValue();

      localStorage.setItem("isRefreshing", "false");
    }
    getAutoID();
  }, []);

  useEffect(() => {
    const status = localStorage.getItem("status");
    setStatusFromLocalStorage(status);

    if (viewTicket) {
      checkParentno();
    }

    getAutoID();
  }, [viewTicket]);

  const getAutoID = async () => {
    try {
      const response = await axios.get(`${API_URL}/getautoid/subticket`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      setAutoid(response.data.ticket_id);
    } catch (error) {
      if (error) {
        console.log(error.message);
      }
    }
  };

  const assignTicket = async (ticketById) => {
    try {
      await axios.post(
        `${API_URL}/updateAssignTicket/${ticketById}`,
        {
          assignValue: viewAssignValue,
          statusValue: "Assigned",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );
      toast.success("Ticket Updated Successfully");
      // handleModal();
      const response1 = await axios.get(`${API_URL}/tickets/${ticketById}`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      localStorage.setItem("viewTicket", JSON.stringify(response1.data));
      const viewTicketDataString = localStorage.getItem("viewTicket");
      if (viewTicketDataString) {
        setViewTicket(response1.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleClose = () => {
    //     setTextAreas([""]);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowBugesModal(false);
  };

  const handleConfirmClose = async (ticket_id) => {
    if (ticket_id) {
      await close(ticket_id, handleCloseModal);
    }
  };

  const handlePriority = () => {
    setShowPriorityModal(true);
  };

  const completed = async (viewticket) => {
    const ticketId = viewticket.ticket_id;

    if (ticketId.startsWith("ST")) {
      const baseTicketId = parseInt(ticketId.replace("ST", ""), 10);

      await axios.post(
        `${API_URL}/tickets/${ticketId}`,
        {
          status: "Completed",
          completed_date_time: new Date().toISOString(),
        },
        {
          headers: { Authorization: `Bearer ${accessToken1}` },
        }
      );

      toast.success("Ticket status updated to Completed", {
        autoClose: 1000,
      });
      setShowModal(false);

      // navigate("/dashboard");
      setTimeout(() => {
        handleClose();
      }, 1000);

      //       const handleOpenCompleteModal = () => {
      //         setShowCompleteModal(true);
      //       };

      const copyOtherValues = (viewticket) => {
        return {
          parentNo: viewticket.parent_no,
          deadline: viewticket.dead_line || null,
          initial_date_time: new Date().toISOString(),
          clientValue: viewticket.client,
          priorityValue: viewticket.priority || null,
          productValue: viewticket.product || null,
          sourceValue: viewticket.source || null,
          subject: viewticket.subject || null,
          task: viewticket.task || null,
          teamsValue: viewticket.teams || null,
          //           toAssignValue: toAssignValue,
          // statusValue: "Processing",
          // completed_date_time: viewticket.completed_date_time,
          cycleValue: viewticket.cycle || null,
          childNo: childNo || null,
          formattedDuration: viewticket.noofdaysexpected || null,
        };
      };
      //       const currentDateTime = new Date();
      //       const ticketDeadline = new Date(viewticket.deadline);
      //       if (currentDateTime > ticketDeadline) {
      //         setSelectedTicket(viewticket);
      //         handleOpenCompleteModal();

      //         // setShowCompleteModal(true);
      //       }

      const phase = viewticket.phase?.toLowerCase();
      const parentNo = viewticket.parent_no;

      if (!viewticket.cycle || !viewticket.cycle.trim()) {
        toast.error("Cannot proceed without a valid cycle.");
        return;
      }

      let cyclePhases = viewticket.cycle.split(" - ");

      const findMatchingPhaseIndex = (phases, phaseToMatch) => {
        return phases.findIndex((p) =>
          p.toLowerCase().includes(phaseToMatch.toLowerCase())
        );
      };

      const currentPhaseIndex = findMatchingPhaseIndex(cyclePhases, phase);

      let nextPhase = null;
      if (
        currentPhaseIndex !== -1 &&
        currentPhaseIndex < cyclePhases.length - 1
      ) {
        nextPhase = cyclePhases[currentPhaseIndex + 1];
      }

      let beforeTicketId = null;
      let afterTicketId = null;

      if (phase === "test cases") {
        afterTicketId = `ST${(baseTicketId + 1).toString().padStart(4, "0")}`;
      } else if (phase === "coding") {
        beforeTicketId = `ST${(baseTicketId - 1).toString().padStart(4, "0")}`;
      }

      let isSubTicketCreationAllowed = false;

      const hasTestCasesAndCoding = nextPhase == "test cases and coding";
      const correctAutoid = parseInt(autoid.replace("ST", ""), 10);

      const subTickets = [];
      const testCasesTicketId = `ST${(correctAutoid + 1)
        .toString()
        .padStart(4, "0")}`;
      const codingTicketId = `ST${(correctAutoid + 2)
        .toString()
        .padStart(4, "0")}`;
      if (hasTestCasesAndCoding) {
        const firstSubTicket = {
          autoid: testCasesTicketId,
          username,
          phaseValue: "test cases",
          completed_date_time: null,
          assignValue: "Anitha",
          deadline: viewticket.dead_line,
          statusValue: "Assigned",
          ...copyOtherValues(viewticket),
        };

        const secondSubTicket = {
          autoid: codingTicketId,
          username,
          phaseValue: "coding",
          statusValue: "Unassigned",
          assignValue: "2cqr automation",
          completed_date_time: null,
          deadline: viewticket.dead_line,
          initial_date_time: new Date().toISOString(),

          ...copyOtherValues(viewticket),
        };

        subTickets.push(firstSubTicket, secondSubTicket);
      } else if (phase === "test cases" || phase === "coding") {
        const beforeTicket = beforeTicketId
          ? await axios.get(`${API_URL}/tickets/${beforeTicketId}`, {
              headers: { Authorization: `Bearer ${accessToken1}` },
            })
          : null;

        const afterTicket = afterTicketId
          ? await axios.get(`${API_URL}/tickets/${afterTicketId}`, {
              headers: { Authorization: `Bearer ${accessToken1}` },
            })
          : null;

        const isBeforeCompleted = beforeTicket?.data?.status === "Completed";
        const isAfterCompleted = afterTicket?.data?.status === "Completed";

        const hasSameParentNoBefore =
          beforeTicket && beforeTicket.data.parent_no === parentNo;

        const hasSameParentNoAfter =
          afterTicket && afterTicket.data.parent_no === parentNo;
        if (
          (phase === "test cases" &&
            hasSameParentNoAfter &&
            isAfterCompleted) ||
          (phase === "coding" && hasSameParentNoBefore && isBeforeCompleted)
        ) {
          isSubTicketCreationAllowed = true;
        }

        const isAboveOrBelowNotMatched =
          (phase === "test cases" && !hasSameParentNoAfter) ||
          (phase === "coding" && !hasSameParentNoBefore);
        if (
          isAboveOrBelowNotMatched &&
          nextPhase !== null &&
          phase !== "entree"
        ) {
          let newSubTicket;

          if (nextPhase === "code review") {
            newSubTicket = {
              autoid,
              username,
              phaseValue: nextPhase,
              assignValue: "2cqr automation",
              statusValue: "Unassigned",
              deadline: viewticket.dead_line,

              completed_date_time: null,
              ...copyOtherValues(viewticket),
            };
          } else if (nextPhase === "testing") {
            newSubTicket = {
              autoid,
              username,
              phaseValue: nextPhase,
              assignValue: "Anitha",
              deadline: viewticket.dead_line,
              statusValue: "Assigned",
              completed_date_time: null,
              ...copyOtherValues(viewticket),
            };
          } else {
            newSubTicket = {
              autoid,
              username,
              phaseValue: nextPhase,
              completed_date_time: null,
              deadline: viewticket.dead_line,
              assignValue: "2cqr automation",
              statusValue: "Unassigned",
              ...copyOtherValues(viewticket),
            };
          }

          await axios.post(`${API_URL}/createsubticket`, newSubTicket, {
            headers: { Authorization: `Bearer ${accessToken1}` },
          });
          getAutoID();
          if (role === "Admin") {
            toast.success("New sub-ticket created for the next phase!", {
              autoClose: 1000,
            });
          } else {
            toast.info("Ticket is Completed", { autoClose: 1000 });
          }

          const fetchSubTickets = async () => {
            const subParentNo = sessionStorage.getItem("parentNo");
            const data = await getSubTickets();
            const filteredSubTickets = data.filter(
              (element) => element.parent_no === subParentNo
            );

            setSubTicketList(filteredSubTickets);
          };
          fetchSubTickets();
        } else if (
          isSubTicketCreationAllowed &&
          nextPhase !== null &&
          phase !== "entree"
        ) {
          const newSubTicket = {
            autoid,
            username,
            phaseValue: nextPhase,
            assignValue: "2cqr automation",
            deadline: viewticket.dead_line,
            completed_date_time: null,
            statusValue: "Unassigned",
            ...copyOtherValues(viewticket),
          };

          await axios.post(`${API_URL}/createsubticket`, newSubTicket, {
            headers: { Authorization: `Bearer ${accessToken1}` },
          });
          getAutoID();
          if (role === "Admin") {
            toast.success("New sub-ticket created for the next phase!", {
              autoClose: 1000,
            });
          } else {
            toast.info("Ticket is Completed", { autoClose: 1000 });
          }

          const fetchSubTickets = async () => {
            const subParentNo = sessionStorage.getItem("parentNo");
            const data = await getSubTickets();
            const filteredSubTickets = data.filter(
              (element) => element.parent_no === subParentNo
            );

            setSubTicketList(filteredSubTickets);
          };
          fetchSubTickets();
        } else {
          if (role === "Admin") {
            toast.info(
              `Cannot create a new sub-ticket. Ensure that the corresponding ${
                phase === "test cases" ? "coding" : "test cases"
              } ticket is completed and shares the same parent number.`,
              { autoClose: 2000 }
            );
          } else {
            toast.info("Ticket is Completed", { autoClose: 2000 });
          }
        }
      } else {
        let newSubTicket;
        if (nextPhase === "testing") {
          newSubTicket = {
            autoid,
            phaseValue: nextPhase,
            username,
            assignValue: "Anitha",
            deadline: viewticket.dead_line,
            statusValue: "Assigned",
            completed_date_time: null,
            ...copyOtherValues(viewticket),
          };
        } else {
          newSubTicket = {
            autoid,
            phaseValue: nextPhase,
            username,
            assignValue: "2cqr automation",
            deadline: viewticket.dead_line,
            completed_date_time: null,
            statusValue: "Unassigned",
            ...copyOtherValues(viewticket),
          };
        }

        subTickets.push(newSubTicket);
      }

      // Create the sub-tickets
      if (
        !isSubTicketCreationAllowed &&
        nextPhase !== null &&
        phase !== "Entrée"
      ) {
        for (const subTicket of subTickets) {
          await axios.post(`${API_URL}/createsubticket`, subTicket, {
            headers: {
              Authorization: `Bearer ${accessToken1}`,
            },
          });
        }
        getAutoID();

        toast.success("Sub-tickets created successfully!", {
          autoClose: 1000,
        });
        const fetchSubTickets = async () => {
          const subParentNo = sessionStorage.getItem("parentNo");
          const data = await getSubTickets();
          const filteredSubTickets = data.filter(
            (element) => element.parent_no === subParentNo
          );

          setSubTicketList(filteredSubTickets);
        };
        fetchSubTickets();
      }
    } else if (ticketId.startsWith("T")) {
      // If ticket_id starts with "T"
      try {
        await axios.post(
          `${API_URL}/tickets/${viewticket.ticket_id}`,
          {
            status: "Completed",
            completed_date_time: new Date().toISOString(),
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken1}`,
            },
          }
        );
        toast.success("Ticket status updated to Completed", {
          autoClose: 1000,
        });
        setShowModal(false);
        // navigate("/dashboard");
        handleClose();
      } catch (error) {
        toast.error("Failed to update the ticket status.", {
          autoClose: 1000,
        });
      }
    }
  };

  const subTicket = async (ticket_id) => {
    let parentNoHistory = sessionStorage.getItem("parentNoHistory");
    parentNoHistory = parentNoHistory ? JSON.parse(parentNoHistory) : [];
    parentNoHistory.push(ticket_id);
    sessionStorage.setItem("parentNoHistory", JSON.stringify(parentNoHistory));

    sessionStorage.setItem("parentNo", ticket_id);

    const subParentNo = sessionStorage.getItem("parentNo");
    const axiosJWT = axios.create();
    const accessToken1 = localStorage.getItem("token");

    try {
      const responseProductTeams = await axiosJWT.get(
        `${API_URL}/tickets/${ticket_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      localStorage.setItem("product", responseProductTeams.data.product);
      localStorage.setItem("team", responseProductTeams.data.teams);

      const response = await axiosJWT.get(
        `${API_URL}/filteredSubTickets/${subParentNo}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      navigate("/subTickets");

      localStorage.setItem("subTicketList", JSON.stringify(response.data));
      const viewTicketDataString = localStorage.getItem("subTicketList");

      if (viewTicketDataString) {
        const viewTicketData = JSON.parse(viewTicketDataString);
        setSubTicketList(viewTicketData);
      }

      setTimeout(() => {
        handleClose();
      }, 1000);
    } catch (error) {
      console.error(error);
    }
    setParentNo(ticket_id);
  };

  const handleClosePriorityModal = () => {
    setShowPriorityModal(false);
    const viewTicket = localStorage.getItem("viewTicket");
    if (viewTicket) {
      setViewTicket(JSON.parse(viewTicket));
    }
  };

  const duplicateTicket = async (ticket_id) => {
    await axios.post(
      `${API_URL}/tickets/${ticket_id}`,
      {
        status: "Duplicate",
      },
      {
        headers: { Authorization: `Bearer ${accessToken1}` },
      }
    );

    toast.success("Ticket status updated to Completed", { autoClose: 1000 });
    //     navigate("/dashboard");
    setShowModal(false);
    setTimeout(() => {
      handleClose();
    }, 1000);
  };

  const close = async (ticket_id, onSuccess) => {
    try {
      await axios.post(
        `${API_URL}/tickets/${ticket_id}`,
        {
          status: "Closed",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      if (viewTicket.phase === "Testing" || viewTicket.phase === "testing") {
        setShowBugesModal(true);
      } else {
        setShowBugesModal(false);
      }

      toast.success("Ticket status updated to Closed", { autoClose: 1000 });
      setTimeout(() => {
        onSuccess();
        handleClose();
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseTicket = async (ticket_id) => {
    if (ticket_id) {
      await close(ticket_id, () => {
        setTicketIdToClose(ticket_id);
      });
    }
  };

  useEffect(() => {
    const storedAssign = localStorage.getItem("assignState");
    if (storedAssign) {
      setAssign(JSON.parse(storedAssign));
    }
  }, []);

  const handleModal = async (ticket_id) => {
    setAssignModal(!assignModal);
    try {
      const response = await axios.get(`${API_URL}/tickets/${ticket_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });

      await axios.post(
        `${API_URL}/createticketmasterlog`,
        {
          assign: response.data.assign,
          ticket_id: response.data.ticket_id,
          phase: response.data.phase,
          noofdaysexpected: response.data.noofdaysexpected,
          assigned_date_time: new Date().toISOString(),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );

      setViewAssign({
        ...viewAssign,
        viewAssignValue: response.data.assign,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowUsers = async () => {
    const assignData = await getUsers();
    setViewAssign({
      viewAssignValue: "",
      viewAssignDropDownValues: assignData,
    });

    setAssign({
      assignValue: "",
      assignDropDownValues: assignData,
    });
    setShowModal(false);
  };

  return (
    <div>
      <Modal
        size="xl"
        show={showModal}
        onHide={handleClose}
        className="text-black border-0 rounded"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="custom-outer-box">
            <div className="custom-modal-content">
              <div className="row">
                <div className="col-lg-4 ">
                  <div className="user-profile">
                    <div className="user-info bg-primary p-3">
                      <h2 className="text-white">
                        Product: {viewTicket.product}
                      </h2>
                    </div>
                    <div className="list-group">
                      <div className="list-group-item">
                        <i className="far fa-ticket"></i> Ticket Id &nbsp;
                        <span className="highlight-text-blue">
                          {" "}
                          {viewTicket.ticket_id}
                        </span>
                      </div>
                      <div className="list-group-item">
                        <i className="fas fa-history"></i> Created By &nbsp;
                        <span className="text-primary spacing">
                          {viewTicket.created_by}
                        </span>
                      </div>
                      <div className="list-group-item">
                        <i className="fas fa-history"></i> Deadline &nbsp;
                        <span className="text-primary spacing">
                          {viewTicket.dead_line}
                        </span>
                      </div>
                      <div className="list-group-item">
                        <i className="far fa-user"></i> Assign &nbsp;
                        <span className="text-primary spacing">
                          {viewTicket.assign}
                        </span>
                      </div>
                      &nbsp;
                      {checkParentNo.length > 0 && (
                        <button
                          className="custom-btn"
                          onClick={() => subTicket(viewTicket.ticket_id)}
                        >
                          Sub Ticket
                        </button>
                      )}
                      &nbsp;
                      {showcompletedbtn &&
                        statusFromLocalStorage == "completed" &&
                        (username === assinedTicket || role === "Admin") &&
                        completedTicketStatus !== "Completed" &&
                        completedTicketStatus !== "Closed" && (
                          <button
                            // disabled={
                            //   (statusFromLocalStorage === "pending" &&
                            //     username !== assinedTicket) ||
                            //   completedTicketStatus === "Completed" ||
                            //   completedTicketStatus === "Closed"
                            // }
                            className="custom-btn"
                            onClick={() => completed(viewTicket)}
                          >
                            Mark As Completed{" "}
                            {/* {completedTicketStatus !== "Completed"
                              ? "Mark As Completed"
                              : "Completed"} */}
                          </button>
                        )}
                      &nbsp;
                      {role === "Admin" &&
                        completedTicketStatus === "Completed" && (
                          <button
                            className="custom-btn btn-danger"
                            onClick={() =>
                              handleCloseTicket(viewTicket.ticket_id)
                            }
                          >
                            Close Ticket
                          </button>
                        )}
                      &nbsp;
                      {(username === assinedTicket || role === "Admin") &&
                        completedTicketStatus !== "Completed" &&
                        completedTicketStatus !== "Closed" && (
                          <button
                            className="custom-btn btn-danger"
                            onClick={() => handleModal(viewTicket.ticket_id)}
                          >
                            Assign Ticket
                          </button>
                        )}
                      &nbsp;
                      <button onClick={handlePriority}>Priority</button>
                      {viewTicket.phase !== "Entrée" && showPriorityModal && (
                        <Priority
                          ticketId={viewTicket.ticket_id}
                          onClose={handleClosePriorityModal}
                        />
                      )}
                      &nbsp;
                      {role === "Admin" && (
                        <button
                          className="custom-btn btn-danger"
                          onClick={() => duplicateTicket(viewTicket.ticket_id)}
                        >
                          Duplicate Ticket
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="table-responsive margin-bottom-2x">
                    <table className="table margin-bottom-none">
                      <thead>
                        <tr>
                          <th className="text-center">Created on</th>
                          <th className="text-center">Priority</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Phase</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-center">
                            <span className="text-primary">
                              {moment(viewTicket.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </span>
                          </td>
                          <td className="text-center">
                            <span className="text-info">
                              {viewTicket.priority}
                            </span>
                          </td>
                          <td className="text-center">
                            <span className="text-success">
                              {viewTicket.status}
                            </span>
                          </td>
                          <td className="text-center">
                            <span className="text-warning">
                              {viewTicket.phase}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="ticket-details-container">
                    <div className="ticket-header bg-warning p-3">
                      <h3 className="ticket-id text-white">
                        Ticket ID: {viewTicket.ticket_id}
                      </h3>
                      <h4 className="ticket-id text-white">
                        Parent Ticket ID: {viewTicket.parent_no}
                      </h4>
                      <h4 className="client text-white">
                        Client: {viewTicket.client}
                      </h4>
                      <h4 className="task text-white">
                        Task: {viewTicket.task}
                      </h4>
                    </div>
                    <h2 className="subject text-danger p-3">
                      Subject: {viewTicket.subject}
                    </h2>

                    {viewTicket.phase !== "Entrée" && (
                      <div className="comments-section bg-light p-3 rounded">
                        <h3 className="comments-heading text-info">
                          Comments:
                        </h3>
                        <CommentSection />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={assignModal} onHide={handleShowUsers}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-sm-11 col-md-6">
            <div className="mb-2">
              <label className="form-label">Assign</label>
              <select
                className="form-select"
                aria-label="Default select example"
                value={viewAssignValue}
                onChange={(e) => {
                  setViewAssign({
                    ...viewAssign,
                    viewAssignValue: e.target.value,
                  });
                }}
              >
                <option>Please choose one option</option>
                {viewAssignDropDownValues?.map((option, index) => {
                  return <option key={index}>{option?.firstname}</option>;
                })}
              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              assignTicket(viewTicket.ticket_id);
              handleModal(viewTicket.ticket_id);
            }}
          >
            Assign
          </Button>
        </Modal.Footer>
      </Modal>

      {showBugesModal && (
        <TestingNumberofBuges
          show={showBugesModal}
          onClose={handleCloseModal}
          ticketId={ticketIdToClose}
          onConfirm={handleConfirmClose}
          product={viewTicket.product}
          subject={viewTicket.subject}
        />
      )}
    </div>
  );
}
